import "./App.css";

import GlobalContextProvider from "./GlobalContext";
import Routes from "./Routes";
import ScrollToTop from "./User/components/ScrollTop";
import ConecctProModal1 from "./User/components/Modals/ConecctProModal1";
import ConectProModel4 from "./User/components/Modals/ConectProModel4";
import ConectproModel2 from "./User/components/Modals/ConectproModel2";
import ConectproModel3 from "./User/components/Modals/ConectproModel3";
import Sendotp from "./ServiceProvider/components/Modals/Sendotp/index.jsx";
import { useEffect, useState } from "react";
import Notification from "./User/Pages/Notification/Notification";
import ChangePassword from "./Common/model/ChnagePassword/ChangePassword.jsx";
import Navbar2 from "./User/components/UI/Nvabar2/index.jsx";
import Navbar from "./User/components/UI/Navbar/index.jsx";
import Navbarsp from "./ServiceProvider/components/UI/Navbar_SP";
import Navbarsp2 from "./ServiceProvider/components/UI/Navbar_SP2";
import Loginmodal from "./User/components/Modals/Loginmodal.jsx";
import Footer from "./User/components/UI/Footer/index.jsx";
import Verification from "./ServiceProvider/components/Modals/Verification";
import AddressModal from "./User/components/WorkerProfile/AddressModal/AddressModal.jsx";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./Common/Firebase/firebase.jsx";
import { Availabletimeslot } from "./ServiceProvider/components/Modals/Model/index.jsx";
// import { useUserStore } from "./Common/Firebase/userStore.jsx";
import { getMessaging, getToken } from "firebase/messaging";
import { app } from "./Common/Firebase/firebase";
function App() {
  const userData = JSON.parse(localStorage.getItem("login"));
  // console.log("🚀 ~ App ~ userData:", userData);
  // const { currentUser, isLoading, fetchUserInfo } = useUserStore();
  // useEffect(() => {
  //   const unsub = onAuthStateChanged(auth, (user) => {
  //     console.log("🚀 ~ unsub ~ user:", user);
  //     fetchUserInfo(user?.uid);
  //   });
  //   return () => {
  //     unsub();
  //   };
  // }, [fetchUserInfo]);
  // console.log(currentUser);

  return (
    <div className="none">
      <ScrollToTop />
      <Notification />
      <GlobalContextProvider>
        <Navbar />
        <Sendotp />
        <Verification />
        <ConecctProModal1 />
        <ConectproModel2 />
        <ConectproModel3 />
        <ConectProModel4 />
        <ChangePassword />
        <Loginmodal />
        <AddressModal />
        <Availabletimeslot />
        <Routes />
        {/* <Footer /> */}
      </GlobalContextProvider>
    </div>
  );
}

export default App;
