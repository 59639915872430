import "./List.css";
import newmeessage from "../../../ServiceProvider/assets/icons8-add-20.png";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  setDoc,
} from "@firebase/firestore";
import { GlobalContext } from "../../../GlobalContext";
import { Skeleton } from "@mui/material";
import { db } from "../../../Common/Firebase/firebase";
import profile from "../../assets/svg/Profile.svg";

const List = ({ onClick }) => {
  return (
    <div className="mainlistdiv">
      <div className="chatslist">
        <div>
          <ChatCard />
        </div>
      </div>
    </div>
  );
};

export default List;

function ChatCard({ select }) {
  const navigate = useNavigate();
  const Mydata = JSON.parse(localStorage.getItem("login"));

  const [loading, setloading] = useState(false);
  const { setlistdata, listdata } = useContext(GlobalContext);

  useEffect(() => {
    const fetchData = async () => {
      setloading(true);

      try {
        const chatsQuery = query(
          collection(db, "users", Mydata.data.data.id.toString(), "mychat")
        );
        const querySnapshot = await getDocs(chatsQuery);

        // Collect all user IDs involved in the chats
        const userIds = querySnapshot.docs.map((doc) => doc.id);

        // Fetch all user documents in a single query
        const userQuery = query(
          collection(db, "users"),
          where("id", "in", userIds)
        );
        const userSnapshot = await getDocs(userQuery);

        const userData = {};
        userSnapshot.forEach((doc) => {
          userData[doc.id] = doc.data();
        });

        const docPromises = querySnapshot.docs.map((docs) => {
          const doref = doc(collection(db, "users"), docs.id);
          return getDoc(doref).then((docSnapshot) => {
            if (docSnapshot.exists()) {
              const data = docSnapshot.data();

              return { a: docs.data(), b: data };
            } else {
              return null;
            }
          });
        });

        Promise.all(docPromises)
          .then((chatData) => {
            // Filter out null values
            chatData = chatData.filter((item) => item !== null);

            // Now you have the merged chat data
            // Sort chatData by lastmessagetime in descending order
            chatData.sort((a, b) => b.a.lastmessagetime - a.a.lastmessagetime);
            // Set the merged chat data here
            setlistdata(chatData);
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
          });

        setloading(false);
      } catch (error) {
        console.error("Error fetching chats: ", error);
        setloading(false);
      }
      // console.log("🚀 ~ fetchData ~ collection:", collection);
    };

    fetchData();
  }, [Mydata.data.data.id, setlistdata]);

  // useEffect(() => {
  //   const unreadMessagesCounts = onSnapshot(unreadMessagesQuery, (snap) => {
  //     setUserUnreadcounts((prevCounts) => ({
  //       ...prevCounts,
  //       [Mydata.data.data.id]: snap.size,
  //     }));
  //   });
  //   return () => {
  //     unreadMessagesCounts();
  //   };
  // }, [Mydata.data.data.id]);

  // const handlechat = (items) => {
  //   const item = {
  //     a: {
  //       Myname: Mydata?.data?.data?.name,
  //       Myid: Mydata?.data?.data?.id,
  //       OppName: items.b.name,
  //       OppId: items.b.id,
  //       image: items.b.image,
  //       chatid: items.a.chatid,
  //     },
  //   };
  //   if (window.innerWidth <= "540") {
  //     navigate("/Profile/Messages", {
  //       state: { item },
  //     });
  //   } else {
  //     navigate("/Profile/Messages", {
  //       state: { item },
  //     });
  //   }
  // };
  const handlechat = async (items) => {
    const item = {
      a: {
        Myname: Mydata?.data?.data?.name,
        Myid: Mydata?.data?.data?.id,
        OppName: items.b.name,
        OppId: items.b.id,
        image: items.b.image,
        chatid: items.a.chatid,
      },
    };

    // Reset unread count in Firestore
    try {
      const mydetail = doc(
        collection(db, "users"),
        JSON.stringify(Mydata.data.data.id)
      );
      const oppdetail = doc(
        collection(mydetail, "mychat"),
        JSON.stringify(items.b.id)
      );

      await setDoc(oppdetail, { unreadcount: 0 }, { merge: true });

      // Now navigate to the chat screen
      if (window.innerWidth <= "540") {
        navigate("/Profile/Messages", {
          state: { item },
        });
      } else {
        navigate("/Profile/Messages", {
          state: { item },
        });
      }
    } catch (error) {
      console.error("Error resetting unread count:", error);
    }
  };

  const customstyle = { backgroundColor: "grey" };

  return (
    <div className="chatslist">
      {listdata?.map((item, index) => {
        // console.log("🚀 ~ {listdata?.map ~ item:", item);
        const itemTimeMicroseconds = item.a.lastmessagetime;
        const itemTimeMilliseconds = Math.floor(itemTimeMicroseconds / 1000);
        const createdTime = new Date(itemTimeMilliseconds);
        const formattedTime = createdTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
        });

        return (
          <div
            className={
              select === index ? "listmaindiv activelist" : "listmaindiv"
            }
            key={index}
            onClick={() => handlechat(item)}>
            <div>
              <div className="groupavtardiv">
                <div className="useravtar">
                  {loading ? (
                    <Skeleton
                      style={customstyle}
                      variant="circular"
                      width={50}
                      height={50}
                    />
                  ) : (
                    <img
                      className=""
                      src={item?.b?.image ? item?.b?.image : profile}
                      alt=""
                    />
                  )}
                </div>
              </div>
              {loading ? (
                <Skeleton style={customstyle} variant="text" width={60} />
              ) : (
                <div className="listusername">
                  <h3>{item?.b?.name}</h3>
                  <p>
                    {item?.a?.lastmessage?.length > 20
                      ? item.a.lastmessage.substring(0, 20) + "..."
                      : item?.a?.lastmessage}
                  </p>

                  {/* <p>{item?.a?.lastmessage}</p> */}
                </div>
              )}
            </div>
            <div className="chattime">
              <span>{formattedTime}</span>
              {item.a.unreadcount > 0 && (
                <span
                  style={{
                    backgroundColor: "#00ad5a",
                    color: "white",
                    width: "20px",
                    height: "20px",
                    textAlign: "center",
                    borderRadius: "50%",
                  }}>
                  {item.a.unreadcount}
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
