import React from "react";
import sec5data from "./sec5_data";
import "./sec5.css";
const Section5 = () => {
  return (
    <div className="main_sec5_div">
      <h1>How it Works</h1>
      <div className="sec5_sub_div">
        {sec5data.map((item, index) => {
          return (
            <div className="sec5_card" key={index}>
              <img src={item.pic} alt="img" />
              <div className="sec5_text">
                <h4>{item.title}</h4>
                <p>{item.pre}</p>
              </div>
            </div>
          );
        })}
      </div>
      <button className="btn5">Book a Service</button>
    </div>
  );
};

export default Section5;
