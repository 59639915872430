import { useState } from "react";
import "./Register_sp.css";
import "../../../components/Professional/Professional.css";
import Navbar from "../../../../User/components/UI/Navbar";
import Footer from "../../../components/UI/Footer";
import Professional from "../../../components/Professional/Professional";
import Personal from "../../../components/Personalinformation/Personalinformation";
import axios from "../../../../Common/Api/index";
import { toast } from "react-hot-toast";
import "../../../components/Personalinformation/Personalinformation.css";
import { ProfessionalSchemas } from "../../../../schemas/index";
import Profile from "../../../assets/Profile.png";
import camera from "../../../assets/camera.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { PersonalSchemas } from "../../../../schemas/index";

const initialValues = {
  FullName: "",
  MobileNumber: "",
  email: "",
  password: "",
  ConfirmPassword: "",
  country: "",
  image: "",
  RatePerhour: "",
  Writehere: "",
  YourSpeciality: "",
  ResolutionExperience: "",
  Skills: "",
};
const Registersp = () => {
  const [Register, setRegister] = useState(true);

  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState("");
  const [close2, setclose2] = useState(true);
  const [close, setclose] = useState(true);
  const Navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [skills, setSkills] = useState([]);
  const [imageCount, setImageCount] = useState(0);
  const [images, setImages] = useState([]);
  // console.log("🚀 ~ Registersp ~ images:", images);
  const [newSkill, setNewSkill] = useState("");
  const [specialist, setSpecialist] = useState([]);
  const [Image, setImage] = useState(null);
  const navigate = useNavigate();
  // ! register api call ============================
  // const onSubmit = async () => {
  //   // setloading(true);
  //   const formdata = new FormData();
  //   formdata.append("name", values.FullName);
  //   formdata.append("phone", values.MobileNumber);
  //   formdata.append("password", values.password);
  //   formdata.append("email", values.email);
  //   formdata.append("user_type", "3");
  //   formdata.append("ratePerhour", values.RatePerhour);
  //   formdata.append("description", values.Writehere);
  //   formdata.append("skil", values.Skills);
  //   formdata.append("speciality_id", values.YourSpeciality);
  //   formdata.append("experience", values.ResolutionExperience);
  //   formdata.append("worker_img", values.image);
  //   formdata.append("profile_image", Image);

  //   try {
  //     let config = {
  //       url: "register",
  //       method: "post",
  //       data: formdata,
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         // Accept: "application/json",
  //       },
  //     };
  //     axios(config)
  //       .then((response) => {
  //         console.log(response);
  //         if (response.data.status === "failed") {
  //           toast.error(response.message, {
  //             position: "top-right",
  //             theme: "colored",
  //           });
  //         } else if (response.status === "success") {
  //           toast.success(response.message, {
  //             position: "top-right",
  //             theme: "colored",
  //           });
  //         }
  //         navigate();
  //       })
  //       .catch((error) => {
  //         toast.error(error.response.data.message, {
  //           position: "top-right",
  //           theme: "colored",
  //         });
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // ! register api call ============================

  const handleImageChange = (event) => {
    setImages(event.target.files[0]);
    const selectedImages = Array.from(event.target.files);

    if (imageCount + selectedImages.length <= 15) {
      setImages([...images, ...selectedImages]);
      setImageCount(imageCount + selectedImages.length);
    } else {
      alert(
        `You can only select a maximum of 15 images. You tried to select${selectedImages.length} images.`
      );
    }
  };
  const handimgleDelete = (index) => {
    setImages(images.filter((image, i) => i !== index));
    setImageCount(imageCount - 1);
  };

  const handleSkillChange = (event) => {
    setNewSkill(event.target.value);
  };

  const handleAddSkill = () => {
    if (newSkill.trim() !== "") {
      setSkills([...skills, newSkill.trim()]);
      setNewSkill("");
    }
  };

  const handleCancelSkill = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);
  };
  // console.log(Image);
  const removeSkillIconSvg = (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00011 4.82129L10.1251 0.696289L11.3034 1.87462L7.17844 5.99962L11.3034 10.1246L10.1251 11.303L6.00011 7.17795L1.87511 11.303L0.696777 10.1246L4.82178 5.99962L0.696777 1.87462L1.87511 0.696289L6.00011 4.82129Z"
        fill="#1E2D38"
      />
    </svg>
  );
  const addIconSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#1E2D38">
      <path d="M10 2V10H18V12H10V20H8V12H0V10H8V2H10Z" />
    </svg>
  );

  const handleImage = (event) => {
    setSelectedImage(event.target.files[0]);
  };
  const eyeopen = (
    <svg
      className="clip-mar"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_26_1790)">
        <path
          d="M11.9987 3C17.3907 3 21.8767 6.88 22.8177 12C21.8777 17.12 17.3907 21 11.9987 21C6.60669 21 2.12069 17.12 1.17969 12C2.11969 6.88 6.60669 3 11.9987 3ZM11.9987 19C14.0382 18.9996 16.0171 18.3068 17.6115 17.0352C19.206 15.7635 20.3216 13.9883 20.7757 12C20.3199 10.0133 19.2036 8.24 17.6093 6.97003C16.015 5.70005 14.037 5.00853 11.9987 5.00853C9.96038 5.00853 7.98238 5.70005 6.38806 6.97003C4.79375 8.24 3.67745 10.0133 3.22169 12C3.67578 13.9883 4.79136 15.7635 6.38583 17.0352C7.9803 18.3068 9.95922 18.9996 11.9987 19ZM11.9987 16.5C10.8052 16.5 9.66062 16.0259 8.81671 15.182C7.97279 14.3381 7.49869 13.1935 7.49869 12C7.49869 10.8065 7.97279 9.66193 8.81671 8.81802C9.66062 7.97411 10.8052 7.5 11.9987 7.5C13.1922 7.5 14.3368 7.97411 15.1807 8.81802C16.0246 9.66193 16.4987 10.8065 16.4987 12C16.4987 13.1935 16.0246 14.3381 15.1807 15.182C14.3368 16.0259 13.1922 16.5 11.9987 16.5ZM11.9987 14.5C12.6617 14.5 13.2976 14.2366 13.7665 13.7678C14.2353 13.2989 14.4987 12.663 14.4987 12C14.4987 11.337 14.2353 10.7011 13.7665 10.2322C13.2976 9.76339 12.6617 9.5 11.9987 9.5C11.3356 9.5 10.6998 9.76339 10.2309 10.2322C9.76208 10.7011 9.49869 11.337 9.49869 12C9.49869 12.663 9.76208 13.2989 10.2309 13.7678C10.6998 14.2366 11.3356 14.5 11.9987 14.5Z"
          fill="#211B24"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_1790">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const eyeclose = (
    <svg
      className="clip-mar"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_26_1797)">
        <path
          d="M9.34178 18.782L7.41078 18.264L8.19778 15.325C7.01975 14.8905 5.9249 14.2574 4.96078 13.453L2.80778 15.607L1.39278 14.192L3.54678 12.039C2.33086 10.5828 1.51387 8.83576 1.17578 6.969L3.14378 6.61C3.90278 10.812 7.57878 14 11.9998 14C16.4198 14 20.0968 10.812 20.8558 6.61L22.8238 6.968C22.4861 8.83502 21.6695 10.5824 20.4538 12.039L22.6068 14.192L21.1918 15.607L19.0388 13.453C18.0747 14.2574 16.9798 14.8905 15.8018 15.325L16.5888 18.265L14.6578 18.782L13.8698 15.842C12.6321 16.0541 11.3674 16.0541 10.1298 15.842L9.34178 18.782Z"
          fill="#211B24"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_1797">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: { PersonalSchemas, ProfessionalSchemas },
    // onSubmit,
    onSubmit: (values) => {
      // console.log("Values.....!", values);
      setRegister(false);
    },
  });

  return (
    <>
      <Navbar />
      <div className="main_register_div">
        <div className="Create-an-acc-main-divv w-100">
          <span className="personaly-add">Create an Account</span>
        </div>
        <div className="haghit-ka-liya"></div>
        <div className="Professional-personal-main">
          <div className="pro-main-btn-divv">
            <button
              onClick={() => setRegister(true)}
              style={{
                backgroundColor: Register ? "#DDFDEE" : "#fff",
                color: Register ? "#00AD5A" : "#B4B1B6",
              }}
              className="Per-pro-button-one">
              <div
                className="per-divv-span-onee"
                style={{ backgroundColor: Register ? "#00AD5A" : "#B4B1B6" }}>
                <span className="Per-pro-one-span">1</span>
              </div>
              <div className="per-divv-span-personal">
                <span className="Per-pro-type-span">Personal Info</span>
              </div>
            </button>
          </div>
          <div className="pro-main-btn-divv">
            <button
              onClick={() => setRegister(false)}
              style={{
                backgroundColor: Register ? "#fff" : "#DDFDEE",
                color: Register ? "#B4B1B6" : "#00AD5A",
              }}
              className="Per-pro-button-two">
              <div
                className="per-divv-span-twoo"
                style={{ backgroundColor: Register ? "#B4B1B6" : "#00AD5A" }}>
                <span className="Per-pro-one-span">2</span>
              </div>
              <div className="per-divv-span-personal">
                <span className="Pro-per-type-span-twoo">
                  Professional Info
                </span>
              </div>
            </button>
          </div>
        </div>
        <div className="underline-divv-per-pro"></div>

        {Register ? <Personal setRegister={setRegister} /> : <Professional />}
      </div>
      <Footer />
    </>
  );
};
export default Registersp;

{
  /* {Register ? (
          <div className="RegisterTwo-main-sec">
            <form onSubmit={handleSubmit} className="RegisterTwo-main-div">
              <>
                <div className="mt-4 profile-info-register">
                  <span className="mb-4 personaly-add">Personal Info</span>
                  <div className="Ht-ml-for-imput mb-4 mt-4">
                    <label className="" htmlFor="mal">
                      <img src={Profile} alt="" className="pic-on-add" />
                      <div className="SandIcon-addeting-div">
                        <img className="Cemara-te-img" src={camera} alt="" />
                      </div>
                    </label>

                    <input
                      id="mal"
                      type="file"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                      className="Malte-pal-cls"
                    />

                    <img
                      src={Image ? URL.createObjectURL(Image) : ""}
                      width="100px"
                      height="100px"
                      className="add-kiya-muja"
                    />
                  </div>
                </div>
                <div className="input-from-r mt-3">
                  <span className="all-input-span-font">Full Name</span>
                  <input
                    className="mt-2 Full-Name-input-hova"
                    placeholder="Full Name"
                    type="text"
                    name="FullName"
                    value={values.FullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.FullName && touched.FullName ? (
                    <p className="mastu-to-eroor mt-2">{errors.FullName} </p>
                  ) : null}
                  <span className="mt-3 all-input-span-font">
                    Mobile Number
                  </span>
                  <input
                    className="mt-2 Full-Name-input-hova"
                    placeholder="Mobile Number"
                    type="number"
                    name="MobileNumber"
                    value={values.MobileNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.MobileNumber && touched.MobileNumber ? (
                    <p className="mastu-to-eroor mt-2">
                      {errors.MobileNumber}{" "}
                    </p>
                  ) : null}
                  <span className="mt-3 all-input-span-font">Email</span>
                  <input
                    className="mt-2 Full-Name-input-hova"
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email ? (
                    <p className="mastu-to-eroor mt-2">{errors.email} </p>
                  ) : null}
                  <span className="mt-3 all-input-span-font">Password</span>
                  <div className="adregister-div mt-2">
                    <input
                      className="Password-or-text-eksatha"
                      type={close2 ? "Password" : "text"}
                      placeholder="New Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <span onClick={() => setclose2(!close2)}>
                      {close2 ? eyeclose : eyeopen}
                    </span>
                  </div>
                  {errors.password && touched.password ? (
                    <p className="mastu-to-eroor mt-2">{errors.password} </p>
                  ) : null}
                  <span className="mt-3 all-input-span-font">
                    Confirm Password
                  </span>
                  <div className="adregister-div mt-2">
                    <input
                      className="Password-or-text-eksatha"
                      type={close ? "Password" : "text"}
                      placeholder="Confirm Password"
                      name="ConfirmPassword"
                      value={values.ConfirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span onClick={() => setclose(!close)}>
                      {close ? eyeclose : eyeopen}
                    </span>
                  </div>
                  {errors.ConfirmPassword && touched.ConfirmPassword ? (
                    <p className="mastu-to-eroor mt-2">
                      {errors.ConfirmPassword}{" "}
                    </p>
                  ) : null}
                  <button
                    type="submit"
                    className="Continue-btn-adr mt-4"
                    // onClick={onClick}
                  >
                    Continue
                  </button>
                </div>
              </>
            </form>
            <div className="haghit-ka-liya"></div>
          </div>
        ) : (
          <div className="RegisterTwo-main-sec">
            <form onSubmit={handleSubmit} className="RegisterTwo-main-div">
              <span className="mb-4 personaly-add mt-4">Professional Info</span>
              <div className="input-from-r mt-3">
                <span className="all-input-span-font">Rate (Per hour)</span>
                <div className="doler-ka-liya mt-2">
                  <span className="doler-span">$</span>
                  <input
                    className="ya-bot-input"
                    placeholder=""
                    type="Number"
                    value={values.RatePerhour}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="RatePerhour"
                  />
                </div>
                {errors.RatePerhour && touched.RatePerhour ? (
                  <p className="mastu-to-eroor mt-2">{errors.RatePerhour} </p>
                ) : null}
                <span className="mt-3 all-input-span-font">About You</span>
                <textarea
                  className="mt-2 Write-here-mahu"
                  id="w3review"
                  name="Writehere"
                  placeholder="Write here"
                  rows="6"
                  cols="40"
                  value={values.Writehere}
                  onChange={handleChange}
                  onBlur={handleBlur}></textarea>
                {errors.Writehere && touched.Writehere ? (
                  <p className="mastu-to-eroor mt-2">{errors.Writehere} </p>
                ) : null}
                <span className="all-input-span-font mt-3">Skills</span>
                <div className="doler-ka-liya mt-2">
                  <div className="Lorem-btn">
                    <div className="Lorem-btn">
                      <div className="add-skill-input">
                        <input
                          className="add-skill-input-field"
                          type="text"
                          placeholder="Add new skill"
                          name="Skills"
                          value={newSkill}
                          onChange={handleSkillChange}
                          onBlur={handleBlur}
                        />
                        <button
                          className="add-skill-btn"
                          onClick={handleAddSkill}
                          dangerouslySetInnerHTML={{ __html: addIconSvg }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Skills-main-divv-ppx mt-2">
                  {skills.map((skill, index) => (
                    <div key={index} className="skill-item">
                      <span className="skill-text">{skill}</span>
                      <img
                        className="remove-skill-icon"
                        src={close}
                        onClick={() => handleCancelSkill(index)}
                      />
                    </div>
                  ))}
                </div>
                {errors.Skills && touched.Skills ? (
                  <p className="mastu-to-eroor mt-2">{errors.Skills} </p>
                ) : null}
                <span className="all-input-span-font mt-3">
                  Your Speciality
                </span>
                <div className="selact-ka-liya-jugad mt-2 ">
                  <select
                    className="selact-tag-one"
                    name="YourSpeciality"
                    value={values.YourSpeciality}
                    onChange={handleChange}
                    onBlur={handleBlur}>
                    <option value="Select">Select</option>
                    <option value="Select1">Select1</option>
                    <option value="Select2">Select2</option>

                    {/* {specialist.map((item, index) => {
                  return (
                    <option key={index} value={item._id}>
                      {item.speciallityName}
                    </option>
                  );
                })} */
}
{
  /* </select>
                </div>
                {errors.YourSpeciality && touched.YourSpeciality ? (
                  <p className="mastu-to-eroor mt-2">
                    {errors.YourSpeciality}{" "}
                  </p>
                ) : null}
                <span className="mt-3 all-input-span-font">YourExperience</span>
                <textarea
                  className="mt-2 Write-here-mahu"
                  id="w3review"
                  name="ResolutionExperience"
                  placeholder="Write here"
                  rows="6"
                  cols="40"
                  value={values.ResolutionExperience}
                  onChange={handleChange}
                  onBlur={handleBlur}></textarea>
                {errors.ResolutionExperience && touched.ResolutionExperience ? (
                  <p className="mastu-to-eroor mt-2">
                    {errors.ResolutionExperience}{" "}
                  </p>
                ) : null}
                <span className="mt-3">Upload Work Photos</span> */
}
{
  /* <div className="add-delet-option">
                  <label className="Ht-ml-for" htmlFor={"mal"}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9 9V5H11V9H15V11H11V15H9V11H5V9H9ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18Z"
                        fill="#00AD5A"
                      />
                    </svg>
                  </label>
                  <input
                    id="mal"
                    type="file"
                    multiple
                    onChange={handleImageChange}
                    className="Malte-pal-cls"
                  />
                  <div className="Not-overflowing">
                    <div className="overflowing-add-immgg">
                      {images.map((image, index) => {
                        return (
                          <div className="sab-img-ka-div">
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Image-${index}`}
                              className="add-kiya-huva-pic"
                              // style={{width: "79.71px", height: "79.71px"}}
                            /> */
}
{
  /* <button
                              className="Btn-dada-delet"
                              onClick={() => handimgleDelete(index)}>
                              <svg
                                className="Ma-svg-top"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_25_3172)">
                                  <path
                                    d="M7.0014 6.17516L9.8889 3.28766L10.7137 4.11249L7.82623 6.99999L10.7137 9.88749L9.8889 10.7123L7.0014 7.82483L4.1139 10.7123L3.28906 9.88749L6.17656 6.99999L3.28906 4.11249L4.1139 3.28766L7.0014 6.17516Z"
                                    fill="#211B24"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_25_3172">
                                    <rect width="14" height="14" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            
              <button type="submit" className="cponform-regiestar mt-3">
                Register
              </button>
              <div className="hghu"></div>
            </form>
          </div>
        )}  */
}
