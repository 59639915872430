import React, { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../../../GlobalContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import yellowplumber from "../../../assets/yellowplumber.png";
import ystar from "../../../assets/svg/Ystar.svg";
import close from "../../../assets/svg/Close.svg";
import axios from "../../../../Common/Api/index";
import { toast } from "react-hot-toast";
import { Backdrop, CircularProgress, useIsFocusVisible } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AddressModal from "../AddressModal/AddressModal";

const CheckAvalabilityModal = ({ show, onHide }) => {
  const [loading, setloading] = useState(false);
  const userdata = JSON.parse(localStorage.getItem("login"));
  const { idget } = useContext(GlobalContext);

  const { setAvailidget, setAdressmodelshow, availidget } =
    useContext(GlobalContext);

  const [Startend, setStartend] = useState([]);
  const [availid, setAvailid] = useState();
  // console.log("🚀 ~ CheckAvalabilityModal ~ availid:", availid);
  const [myAvailid, setMyAvailid] = useState(0);
  const [Todaytime, setTodaytime] = useState();
  console.log("🚀 ~ CheckAvalabilityModal ~ Todaytime:", Todaytime);

  const [tomorrowtime, settomorrowtime] = useState();

  // console.log("🚀 ~ CheckAvalabilityModal ~ tomorrowtime:", tomorrowtime);

  const onSubmit = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "get-availability",
        {
          user_id: idget.id,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userdata.data.data.api_token}`,
          },
        }
      );
      setloading(false);

      // setStartend(response.data.data);
      setTodaytime(response.data.data.today);
      settomorrowtime(response.data.data.tomorrow);
    } catch (error) {
      setloading(false);
    }
  };
  useEffect(() => {
    onSubmit();
  }, [show]);

  const senddata = () => {
    setAvailidget(availid);
    if (availidget) {
      onHide();
      setAdressmodelshow(true);
    }
    // if (
    //   !Todaytime ||
    //   Todaytime.length === 0 ||
    //   !tomorrowtime ||
    //   Todaytime.length === 0
    // ) {
    //   toast.success("Time slot not available at this time", {
    //     position: "top-right",
    //     theme: "colored",
    //   });
    // }
  };

  const currentDate = new Date();
  const currentDateWithoutTime = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const currentTime = moment();
  return (
    <>
      <AddressModal />
      <Modal show={show} onHide={onHide} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title className="Address-main-payment-title">
            <span className="Address-main-span-divv">Check Availability</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="all-span-booking-time-divv">
            <div className="model-img mt-2">
              <div className="all-apn-immg-car-plu-main">
                <div className="ingg-broklyn-yellocap-divv">
                  <img src={idget?.image} className="hire1-model-img" alt="" />
                </div>
                <div className="broklynn-star-main-divv-uux">
                  <div className="broklyn-spann-tagg-divv">
                    <span className="brok-spann-tagg-ppyy">
                      {idget?.user_name || idget?.name}
                    </span>
                  </div>
                  <div className="star-span-number-text-divv">
                    <img src={ystar} alt="" />
                    <span className="rating-and-star-spann">
                      {idget?.average_rate != null
                        ? Number.isInteger(idget.average_rate)
                          ? idget.average_rate
                          : parseFloat(idget.average_rate).toFixed(1)
                        : "0"}
                    </span>

                    <span className="rating-and-star-spann">
                      ({idget?.rate_count})
                    </span>
                  </div>
                </div>
              </div>
              <div className="Spann-doller-merge-divv-ppx">
                <div className="span-clem-exp-divv">
                  <span className="clean-exp-divvv">
                    {" "}
                    {idget?.category_name}
                  </span>
                  <span className="clean-exp-divvv">
                    {" "}
                    {idget?.subcategory_name}
                  </span>
                </div>
                <div className="doller-thirty-exp-divv">
                  <span className="doller-thirty-span">₦ {idget?.price}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="Span-divv-Avail-pay">
            <span className="Avail-time-span">Availability</span>
          </div>
          <div className="mt-3 Today-tommoro-span">
            <span
              className="Today-span"
              style={{
                color: myAvailid == 0 ? "#00AD5A" : "#14161B",
              }}
              onClick={() => setMyAvailid(0)}>
              Today
            </span>
            <span
              className="Tomorrow-span"
              style={{
                color: myAvailid == 1 ? "#00AD5A" : "#14161B",
              }}
              onClick={() => setMyAvailid(1)}>
              Tomorrow
            </span>
          </div>

          <div className="main_time_slot_div">
            {myAvailid === 0 ? (
              <div className="Date_MAin_divvv">
                {Todaytime?.filter((item) => {
                  const currentTime = moment();
                  const startTime = moment(item.start_time, "HH:mm:ss");
                  return startTime.isAfter(currentTime);
                }).map((item, index) => {
                  const isSelected = item.id === availid;
                  const isBooked = item.is_booked === 1;
                  const buttonStyle = {
                    backgroundColor: isSelected
                      ? "#00ad5a"
                      : isBooked
                      ? "#ccc"
                      : "initial",
                    color: isSelected || isBooked ? "#fff" : "initial",
                    opacity: isBooked ? 0.6 : 1,
                    cursor: isBooked ? "notAllowed" : "pointer",
                  };
                  return (
                    <div className="mt-2 all-time-btn" key={item.id}>
                      <button
                        style={buttonStyle}
                        className="time-apoy-mant"
                        onClick={() => {
                          if (!isBooked) {
                            setAvailid(item.id);
                          }
                        }}
                        disabled={isBooked}>
                        {new Date(
                          `2000-01-01T${item.start_time}`
                        ).toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        })}{" "}
                        to{" "}
                        {new Date(
                          `2000-01-01T${item.end_time}`
                        ).toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </button>
                    </div>
                  );
                })}
                {/* {Todaytime?.map((item, index) => {
              const isSelected = item.id === availid;
              const isBooked = item.is_booked === 1;
              // console.log(item);
              const buttonStyle = {
                backgroundColor: isSelected
                  ? "#00ad5a"
                  : isBooked
                  ? "#ccc"
                  : "initial",
                color: isSelected || isBooked ? "#fff" : "initial",
                opacity: isBooked ? 0.6 : 1,
                cursor: isBooked ? "notAllowed" : "pointer",
              };

              return (
                <div className="mt-2 all-time-btn">
                  <button
                    style={buttonStyle}
                    className="time-apoy-mant"
                    onClick={() => {
                      setAvailid(item.id);
                      if (!isBooked) {
                        setAvailid(item.id);
                      }
                    }}
                    disabled={isBooked}>
                    {new Date(
                      `2000-01-01T${item.start_time}`
                    ).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    })}{" "}
                    to{" "}
                    {new Date(`2000-01-01T${item.end_time}`).toLocaleTimeString(
                      "en-US",
                      { hour: "numeric", minute: "2-digit", hour12: true }
                    )}
                  </button>
                </div>
              );
            })} */}
              </div>
            ) : null}
            {myAvailid === 1 ? (
              <div className="Date_MAin_divvv">
                {tomorrowtime?.map((item, index) => {
                  const isSelected = item.id === availid;
                  const isBooked = item.is_booked === 1;
                  const buttonStyle = {
                    backgroundColor: isSelected
                      ? "#00ad5a"
                      : isBooked
                      ? "#ccc"
                      : "initial",
                    color: isSelected || isBooked ? "#fff" : "initial",
                    opacity: isBooked ? 0.6 : 1,
                    cursor: isBooked ? "notAllowed" : "pointer",
                  };
                  return (
                    <div className="mt-2 all-time-btn">
                      <button
                        style={buttonStyle}
                        className="time-apoy-mant"
                        onClick={() => {
                          setAvailid(item.id);
                          if (!isBooked) {
                            setAvailid(item.id);
                          }
                        }}
                        disabled={isBooked}>
                        {moment.unix(item.sortStart_time).format("h:mm A")} to{" "}
                        {moment.unix(item.sortEnd_time).format("h:mm A")}
                      </button>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div className="Book-check-next-btnn-divv mt-3">
            <button className="check-book-btnn" onClick={senddata}>
              Book Now
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default CheckAvalabilityModal;

// {
//    {moment.unix(item.sortStart_time).format("h:mm A")} to{" "}
//                     {moment.unix(item.sortEnd_time).format("h:mm A")}
// }
// {
//    {item.start_time} to
//                     {item.end_time}
// }
