import "./Profile.css";
import React from "react";
import { Outlet } from "react-router-dom";
import Profilesidebar from "../../components/UI/ProfileSidebar/Profilesidebar";
import Navbar2 from "../../components/UI/Nvabar2";
import Footer from "../../components/UI/Footer";

const Profile = () => {
  return (
    <div
      className="white"
      style={{ backgroundColor: "#fff", minHeight: "100vh", height: "100%" }}>
      {/* <Navbar2 /> */}
      <div style={{ marginTop: "101px" }}></div>
      <div className="d-flex justify-content-center gap-3 pt-4 pe-3 ps-3 pb-4">
        <Profilesidebar />
        <div className="">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
