import React, { useContext, useState } from "react";
import "./Deals.css";
import Navbar from "../../components/UI/Nvabar2";
import Footer from "../../components/UI/Footer";
import img1 from "../../assets/DD_1.png";
import Sara from "../../assets/Sara.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import whiteboy from "../../assets/whiteboy.png";

import { GlobalContext } from "../../../GlobalContext";

const Dealsdeatail = () => {
  const {
    FirstModalShow,
    setFirstModalShow,
    SecondModalShow,
    setSecondModalShow,
  } = useContext(GlobalContext);

  const Ystar = (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_41_2379)">
        <path
          d="M10.5 14.875L5.35671 18.0163L6.75496 12.1538L2.17871 8.23375L8.18559 7.7525L10.5 2.1875L12.8143 7.7525L18.8221 8.23375L14.245 12.1538L15.6432 18.0163L10.5 14.875Z"
          fill="#FAD500"
        />
      </g>
      <defs>
        <clipPath id="clip0_41_2379">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const Gstar = (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_41_2380)">
        <path
          d="M10.5 14.875L5.35671 18.0163L6.75496 12.1538L2.17871 8.23375L8.18559 7.7525L10.5 2.1875L12.8143 7.7525L18.8221 8.23375L14.245 12.1538L15.6432 18.0163L10.5 14.875Z"
          fill="#C7C3B9"
        />
      </g>
      <defs>
        <clipPath id="clip0_41_2380">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const YYstar = (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_41_2386)">
        <path
          d="M7.00014 9.91683L3.5713 12.011L4.50347 8.10266L1.45264 5.48933L5.45722 5.1685L7.00014 1.4585L8.54305 5.1685L12.5482 5.48933L9.4968 8.10266L10.429 12.011L7.00014 9.91683Z"
          fill="#FAD500"
        />
      </g>
      <defs>
        <clipPath id="clip0_41_2386">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  /*=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- REVIEW BOX START HERE-=-=-=-=-=-=-=-=-=-=-=-=-=- */

  let five = 100;
  let four = 70;
  let three = 45;
  let two = 35;
  let one = 20;
  let total = five + four + three + two + one;

  const Myarr = [five, four, three, two, one];

  const newarr = [];

  Myarr.map((_) => {
    let parsantage = parseInt((_ * 100) / total);
    newarr.push(parsantage);
  });

  return (
    <>
      <Navbar />
      <section className="deals_details_sec">
        <div className="top_div">
          <div className="heading_div">
            <div className="head_text">
              <h2>Carpentry Lorem</h2>
            </div>
            <div className="details_top">
              <div className="img_box">
                <img src={img1} alt="" />
              </div>
              <div className="top__text">
                <h3>$ 2300</h3>
                <button
                  className="btn5"
                  variant="primary"
                  onClick={() => setFirstModalShow(true)}>
                  Contact this Pro
                </button>

                {/* <Therdmodal />
                <Fourmodal /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="details_sec">
          <div className="details__text">
            <h2>Deal Detail</h2>
            <p>
              Phasellus hendrerit orci nibh, non porttitor dolor dignissim
              tristique. Curabitur quis dolor eget enim hendrerit venenatis.
              Class aptent taciti sociosqu ad litora torquent per conubia
              nostra, per inceptos himenaeos. Maecenas sagittis non lectus sit
              amet malesuada. Nullam semper dui nulla, id sagittis elit
              facilisis at.
            </p>

            <ol>
              <li>
                There are many variations of passages of Lorem Ipsum available.
              </li>
              <li>
                But the majority have suffered alteration in some form, by
                injected humour, or
              </li>
              <li>
                Randomised words which don't look even slightly believable.
              </li>
              <li>If you are going to use a passage of Lorem Ipsum,</li>
              <li>
                You need to be sure there isn't anything embarrassing hidden in
                the middle of text.
              </li>
              <li>
                All the Lorem Ipsum generators on the Internet tend to repeat
                predefined chunks as necessary,
              </li>
              <li>Making this the first true generator on the Internet.</li>
              <li>
                It uses a dictionary of over 200 Latin words, combined with a
                handful of model sentence structures, to generate
              </li>
              <li>
                Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is
                therefore always free from repetition, injected humour, or
                non-characteristic words etc.
              </li>
            </ol>
          </div>
          {/*=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- REVIEW BOX START HERE-=-=-=-=-=-=-=-=-=-=-=-=-=- */}

          <div className="Reviews_sec">
            <div className="Reviews_sub_box">
              <h2>Reviews</h2>
              <div className="secnd_div_review">
                <div className="rate_box">
                  <h5>
                    Exceptional <b> 4.0 </b>
                  </h5>
                  <div className="star_div">
                    <span>{Ystar}</span>
                    <span>{Ystar}</span>
                    <span>{Ystar}</span>
                    <span>{Ystar}</span>
                    <span>{Gstar}</span>
                  </div>
                </div>
                <div className="total_rateing_box">
                  <p>{total} Reviews</p>
                </div>
              </div>
              <div className="therd_div_review">
                {newarr.map((i, index) => {
                  return (
                    <div className="fivestar_div">
                      <div className="text_strt">
                        <h5>{newarr.length - 1 - index + 1}</h5>
                        <span>{YYstar}</span>
                      </div>
                      <div className="rating_line_div">
                        <div
                          className="inner_div"
                          style={{ width: `${i}%` }}></div>
                      </div>
                      <div className="total_percentage">
                        <h6>{i}%</h6>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/*=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- Review details box -=-=-=-=--=-=-=-=-=-=-=-=-=-=-*/}
          <div>
            <div className="reweive-main mt-4">
              <div>
                <img src={Sara} className="rewive-user" alt="" />
              </div>
              <div className="Warren-span-rewvi">
                <div className="Warren-main-div">
                  <div className="date-and-name">
                    <span className="Wade-span"> Wade Warren</span>
                    <span className="dec-date">12 Dec 2022</span>
                  </div>
                  <div>
                    <span>{Ystar}</span>
                    <span>{Ystar}</span>
                    <span>{Ystar}</span>
                    <span>{Ystar}</span>
                    <span>{Gstar}</span>
                  </div>
                </div>
                <span className="venenatis-span">
                  Mauris venenatis, felis in dictum sagittis, elit nunc
                  dignissim massa, eget feugiat nibh est a nibh. Aliquam eu nibh
                  sit amet augue consectetur pretium sed sit amet leo.
                </span>
              </div>
            </div>
            <div className="line-div mt-4 mb-4"></div>
            <div className="reweive-main">
              <div>
                <img src={whiteboy} className="rewive-user" alt="" />
              </div>
              <div className="Warren-span-rewvi">
                <div className="Warren-main-div">
                  <div className="date-and-name">
                    <span className="Wade-span"> Wade Warren</span>
                    <span className="dec-date">12 Dec 2022</span>
                  </div>
                  <div>
                    <div>
                      <span>{Ystar}</span>
                      <span>{Ystar}</span>
                      <span>{Ystar}</span>
                      <span>{Ystar}</span>
                      <span>{Gstar}</span>
                    </div>
                  </div>
                </div>
                <span className="venenatis-span">
                  Mauris venenatis, felis in dictum sagittis, elit nunc
                  dignissim massa, eget feugiat nibh est a nibh. Aliquam eu nibh
                  sit amet augue consectetur pretium sed sit amet leo.
                </span>
              </div>
            </div>
            <div className="line-div mt-4 mb-4"></div>
            <div className="Review-footer w-100" style={{ color: "#00AD5A" }}>
              <b className="cursor-pointer">See All Reviews</b>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Dealsdeatail;
