import { initializeApp } from "firebase/app";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBcuMOSWx2TMQoMoiTP6eO5ib2e52f-PrQ",
  authDomain: "handy-connect-e7445.firebaseapp.com",
  projectId: "handy-connect-e7445",
  storageBucket: "handy-connect-e7445.appspot.com",
  messagingSenderId: "996168564315",
  appId: "1:996168564315:web:914f4efb4d978d60a6897e",
  measurementId: "G-69Y5PSFZ57",
};

export const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const GoogleProvider = new GoogleAuthProvider();
const TwitterProvider = new TwitterAuthProvider();
const FacebookProvider = new FacebookAuthProvider();

export { auth, GoogleProvider, FacebookProvider, db, TwitterProvider, storage };
