import React from "react";
import "./Successfully.css";
import Navbar2 from "../UI/Navbar_SP2";
import Footer from "../../../User/components/UI/Footer";
import tick from "../../../User/assets/svg/Successfully.svg";
import { useNavigate } from "react-router-dom";

const Successfullycreated = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <Navbar2 /> */}
      <div className="main_div">
        <div className="sub_main_div">
          <div className="_img__box">
            <img src={tick} alt="" />
          </div>
          <div className="text">
            <h2>Successfully Created!</h2>
            <p>
              You have successfully created the profile. <br />
              Wait for our admin team to review and <br />
              make your profile online soon.
            </p>
          </div>
          <button className="sbtn_1" onClick={() => navigate("/SP_Dashboard")}>
            Ok
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Successfullycreated;
