import { useState, useContext, useRef } from "react";
import { GlobalContext } from "../../../../GlobalContext";
import Modal from "react-bootstrap/Modal";
import "./verification.css";
import OTPInput from "react-otp-input";
import { useFormik } from "formik";
import axios from "../../../../Common/Api";
import { toast } from "react-hot-toast";
import { Backdrop, CircularProgress } from "@mui/material";

const initialValues = {
  otpvalues: "",
};

const Index = () => {
  const [otp, setOtp] = useState("");
  const [loading, setloading] = useState(false);
  const { otpVerify, setOtpVerify, setMyDashbord } = useContext(GlobalContext);
  const inputstyle = {
    color: "#000000",
    width: "30px",
    height: "30px",
    border: "1px solid #000000",
    background: "transparent",
  };
  const stylee = {
    display: "flex",
    gap: "10px",
  };
  const userdata = JSON.parse(localStorage.getItem("login"));
  const formRef = useRef(null);

  const CompletBooking = async () => {
    setloading(true);
    try {
      const Response = await axios.post(
        "get-worker-complete-booking",
        {},
        {
          headers: {
            Authorization: `Bearer ${userdata.data.data.api_token}`,
          },
        }
      );
      setloading(false);
      // console.log("complet booking Response ========>", Response.data.data);
      // setCompletBookingdata(Response.data.data);
    } catch (error) {
      setloading(false);
    }
  };

  const onSubmit = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "verify-otp",
        // { id: otpVerify.data, otp: values.otpvalues },
        { id: otpVerify.data, otp: otp },
        {
          headers: {
            Authorization: `Bearer ${userdata.data.data.api_token}`,
          },
        }
      );
      // console.log("checking response otp subbmitted===>", response);
      setloading(false);
      if (response?.data?.status === "success") {
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
        setloading(false);
        formRef.current.reset();
        setOtpVerify(false);
        setOtp();
        CompletBooking();
        setMyDashbord(2);
      }
    } catch (error) {
      setloading(false);
      toast.error(error?.response?.data.message, {
        position: "top-right",
        theme: "colored",
      });
      // console.log("check error in OTP verify ====>", error);
    }
  };

  const { values, handleBlur, handleChange, touched, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
    });

  return (
    <div>
      <Modal
        show={otpVerify.istrue}
        onHide={() => setOtpVerify(false)}
        size="sm"
        centered>
        <Modal.Header style={{ border: "0" }} closeButton></Modal.Header>
        <Modal.Body>
          <div className="main_send_otp_div">
            <div className="otp_sub_main_div">
              <div className="verify_div_heding">
                <h2>OTP Verification</h2>
                <p>We Will Send a OTP please check Notification</p>
              </div>
              <div className="main_verfyinput_div">
                <form ref={formRef} onSubmit={handleSubmit}>
                  <div className="sub_verfy">
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      inputType="number"
                      style={{ color: "black" }}
                      containerStyle={stylee}
                      // value={values.otpvalues}
                      // onChange={handleChange}
                      onBlur={handleBlur}
                      numInputs={6}
                      inputStyle={inputstyle}
                      // renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                  <div className="div">
                    <button type="submit" className="card_btn_2">
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </div>
  );
};

export default Index;
