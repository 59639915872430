// Img import
import S_2_1 from "../../../../User/assets/sec2_1.png";
import S_2_2 from "../../../../User/assets/sec2_2.png";
import S_2_3 from "../../../../User/assets/sec2_3.png";
import S_2_4 from "../../../../User/assets/sec2_4.png";

const start = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_35_8969)">
      <path
        d="M7.50001 10.625L3.82626 12.8687L4.82501 8.68125L1.55626 5.88125L5.84688 5.5375L7.50001 1.5625L9.15313 5.5375L13.4444 5.88125L10.175 8.68125L11.1738 12.8687L7.50001 10.625Z"
        fill="#F2B006"
      />
    </g>
    <defs>
      <clipPath id="clip0_35_8969">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const sec2data = [
  {
    id: 1,
    pic: S_2_1,
    title: "Deck & Porche",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    price: "$265",
  },
  {
    id: 2,
    pic: S_2_2,
    title: "Home Cleaning",
    ico: start,
    rateing: "4.0",
    totalrating: "(988)",
    price: "$265",
  },
  {
    id: 3,
    pic: S_2_3,
    title: "Roof Repair",
    ico: start,
    rateing: "5.0",
    totalrating: "(322)",
    price: "$265",
  },
  {
    id: 4,
    pic: S_2_4,
    title: "Fence Repair",
    ico: start,
    rateing: "4.8",
    totalrating: "(700)",
    price: "$265",
  },
  {
    id: 5,
    pic: S_2_1,
    title: "Deck & Porche",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    price: "$265",
  },
  {
    id: 6,
    pic: S_2_2,
    title: "Home Cleaning",
    ico: start,
    rateing: "4.0",
    totalrating: "(988)",
    price: "$265",
  },
  {
    id: 7,
    pic: S_2_3,
    title: "Roof Repair",
    ico: start,
    rateing: "5.0",
    totalrating: "(322)",
    price: "$265",
  },
  {
    id: 8,
    pic: S_2_4,
    title: "Fence Repair",
    ico: start,
    rateing: "5.0",
    totalrating: "(700)",
    price: "$265",
  },
];
export default sec2data;
