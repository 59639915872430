import React from "react";
import "./about.css";
import about1 from "../../../User/assets/About_1.png";
import about2 from "../../../User/assets/About_2.png";
import about3 from "../../../User/assets/About_3.png";
import Navbar2 from "../../components/UI/Navbar_SP";
import Footer from "../../../User/components/UI/Footer";

const Aboutus = () => {
  return (
    <>
      {/* <Navbar2 /> */}
      <div className="about_main_section">
        <div className="sub_div_aboutsection">
          <div className="section1">
            <div className="sec1_img">
              <img src={about1} alt="" />
            </div>
            <div className="sec1_text">
              <h1>
                Lorem Ipsum <br />
                is simply dummy
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing <br />
                elit.Phasellus aliquet dignissim finibus. Quisque <br />
                condimentum.
              </p>
            </div>
          </div>
          <div className="section2">
            <div className="sec2_text">
              <h1>
                Lorem Ipsum <br />
                is simply dummy
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing <br />
                elit.Phasellus aliquet dignissim finibus. Quisque <br />
                condimentum.
              </p>
            </div>
            <div className="sec2_img">
              <img src={about2} alt="" />
            </div>
          </div>
          <div className="section3">
            <div className="sec3_div">
              <div className="sec3_heading">
                <h1>Our Success Rate</h1>
              </div>
              <div className="sec3_count_box">
                <div className="Clients">
                  <h1>4126</h1>
                  <p>Happy Clients</p>
                </div>
                <div className="Project">
                  <h1>250+</h1>
                  <p>Project Completed</p>
                </div>
                <div className="Average">
                  <h1>4+</h1>
                  <p>Average Rating</p>
                </div>
                <div className="Service">
                  <h1>3K+</h1>
                  <p>Service Providers</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section4">
            <div className="sec4img">
              <img src={about3} alt="" />
            </div>
          </div>
          <div className="section5">
            <div className="sec5_text">
              <h1>Contrary to popular belief</h1>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Aboutus;
