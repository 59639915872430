import { useContext } from "react";
import { GlobalContext } from "../../../GlobalContext";
import { Button, Modal } from "react-bootstrap";

const ConectproModel3 = () => {
  const {
    setSecondModalShow,
    ThirdModalShow,
    setThirdModalShow,
    setFourModalShow,
  } = useContext(GlobalContext);
  return (
    <Modal
      show={ThirdModalShow}
      onHide={() => setThirdModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header
        style={{
          display: "grid",
          placeItems: "end",
          width: "100% ",
          justifyContent: "end",
          border: "0",
        }}
        closeButton
        onClick={() => setThirdModalShow(false)}></Modal.Header>
      <Modal.Body>
        <div className="modelthree_main">
          <div className="thard_model_box">
            <p>
              Are there any additional details you’d like to include about your
              project?
            </p>
          </div>

          <div className="textfild_box">
            <textarea
              name=""
              id=""
              placeholder="Provide a brief description of your project and include your preferred contact time. Budget, and scheduling details"></textarea>
          </div>
          <div className="div_btn_div">
            <Button
              className="btn_model3"
              onClick={() => {
                setThirdModalShow(false);
                setSecondModalShow(true);
              }}>
              Back
            </Button>
            <Button
              className="btn_model3"
              onClick={() => {
                setThirdModalShow(false);
                setFourModalShow(true);
              }}>
              Submit
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConectproModel3;
