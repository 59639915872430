import React from "react";
import "./sec6.css";
import sec6_1 from "../../../assets/sec6_1.png";
import { useNavigate } from "react-router-dom";

const Section6 = () => {
  const Navigate = useNavigate();
  return (
    <div className="main_div_sec6">
      <div className="sub_div_sec6">
        <div className="right_side_text">
          <div className="text_div">
            <h5>
              Grow your business <br />
              with us. Become a <br />
              service provider today.
            </h5>
            <button onClick={() => Navigate("/AboutUs")} className="btn5">
              {" "}
              Know more
            </button>
          </div>
        </div>
        <div className="left_side_img">
          <img src={sec6_1} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section6;
