import "./sec2.css";
import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import sec2data from "../section2/sec2data.js";
import axios from "../../../../Common/Api/index.jsx";
import { Backdrop, CircularProgress } from "@mui/material";
import { Await, useLocation, useNavigate } from "react-router-dom";
import S_2_2 from "../../../assets/sec2_2.png";
import { toast } from "react-hot-toast";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Section2 = () => {
  const navigate = useNavigate();
  const SwiperRef = useRef(null);
  const [loading, setloading] = useState(true);
  const [Sec2data, setSec2data] = useState([]);
  const Lat = JSON.parse(localStorage.getItem("lat"));
  const Long = JSON.parse(localStorage.getItem("long"));
  const Sec2details = async () => {
    // setloading(true);
    try {
      const Response = await axios.post("popular-projects", {
        latitude: Lat,
        longitude: Long,
      });
      setloading(false);

      setSec2data(Response.data.Top_10_workers);
      // if ((Response.data.status = "success")) {
      // toast.success(Response.data.message, {
      //   position: "top-right",
      //   theme: "colored",
      // });
      // }
    } catch (error) {
      setloading(false);
      // toast.error(error.message, {
      //   position: "top-left",
      //   theme: "colored",
      // });
    }
  };
  useEffect(() => {
    Sec2details();
  }, []);

  const start = (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_35_8969)">
        <path
          d="M7.50001 10.625L3.82626 12.8687L4.82501 8.68125L1.55626 5.88125L5.84688 5.5375L7.50001 1.5625L9.15313 5.5375L13.4444 5.88125L10.175 8.68125L11.1738 12.8687L7.50001 10.625Z"
          fill="#F2B006"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_8969">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const Prev = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_35_9001)">
        <path
          className="mySvgPath"
          d="M11.828 12L14.657 9.17202L13.243 7.75702L8.99998 12L13.243 16.243L14.657 14.828L11.828 12Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_9001">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
  const next = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_32)">
        <path
          className="mySvgPath"
          d="M12.172 12L9.34299 9.17202L10.757 7.75702L15 12L10.757 16.243L9.34299 14.828L12.172 12Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_32">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const handleclick = async (item) => {
    // console.log("🚀 ~ handledetail ~ item:", item);
    navigate("/WorkerProfile", {
      state: {
        item,
      },
    });
  };

  const items = [0, 1, 2, 3];
  return (
    <section className="sec2">
      <div className="container">
        <div className="hheading__sec2">
          <h2>Popular Projects</h2>
          <div className="div_bttn">
            <button
              className="swiper_btn"
              type="button"
              onClick={() => SwiperRef.current.swiper.slidePrev()}>
              <span>{Prev}</span>
            </button>
            <button
              className="swiper_btn"
              type="button"
              onClick={() => SwiperRef.current.swiper.slideNext()}>
              <span>{next}</span>
            </button>
          </div>
        </div>

        <div className="main_swiper_div">
          <Swiper
            ref={SwiperRef}
            slidesPerView={4}
            spaceBetween={10}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            style={{ width: "100%" }}>
            {loading ? (
              // <div className="swiper_loading_card">
              //   {Array(4)
              //     .fill(0)
              //     .map((_, index) => (
              //       <div key={index} className="swiper_main_box">
              //         <SkeletonTheme
              //           baseColor="#949495"
              //           highlightColor="#D1D1D1">
              //           <Skeleton height={`324px`} />
              //           {/* <Skeleton circle={true} height={50} width={50} /> */}
              //           {/* <Skeleton height={24} width={`60%`} /> */}
              //         </SkeletonTheme>
              //       </div>
              //     ))}
              // </div>
              <div className="swiper_loading_card">
                {items.map((_, index) => (
                  <div key={index} className={`swiper_main_box box-${index}`}>
                    <SkeletonTheme baseColor="#949495" highlightColor="#D1D1D1">
                      <Skeleton height={`324px`} />
                    </SkeletonTheme>
                  </div>
                ))}
              </div>
            ) : (
              Sec2data.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      style={{ cursor: "pointer" }}
                      className="swiper_main_box"
                      onClick={() => handleclick(item)}>
                      <div className="div_of_img_sec2">
                        <img
                          src={item.worker_image}
                          alt={item.subcategory_name}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="text_box">
                        <h6>{item.subcategory_name}</h6>
                        <p className="p__text">
                          {start}
                          {item.average_rate} ( {item.rate_count} )
                        </p>

                        <p>
                          From <span className="price"> ₦ {item.price} </span>
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            )}
          </Swiper>
        </div>
        {/* {!loading && <div></div>$}
        {loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )} */}
      </div>
    </section>
  );
};

export default Section2;
