import React, { useEffect, useContext } from "react";
import { GlobalContext } from "../../../GlobalContext";
import "../WorkerProfile/WorkerProfile.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import star from "../../assets/star.png";
import yellowplumber from "../../assets/yellowplumber.png";
import bluecap from "../../assets/bluecap.png";
import driller from "../../assets/driller.png";
import headphoneman from "../../assets/headphoneman.png";
import pantman from "../../assets/pantman.png";
import calculatingman from "../../assets/calulatingman.png";
import Sara from "../../assets/Sara.png";
import whiteboy from "../../assets/whiteboy.png";
import ystar from "../../assets/svg/Ystar.svg";
import Gstar from "../../assets/svg/greystar.svg";
import CheckAvalabilityModal from "./CheckAvalability/CheckAvalabilityModal";
import AddressModal from "./AddressModal/AddressModal";
import Navbar2 from "../UI/Nvabar2";
import Footer from "../UI/Footer";
import Chatbox from "../Modals/Chabox";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../Common/Api/index";
import { toast } from "react-hot-toast";
import Profile from "../../assets/svg/Profile.svg";

import { Backdrop, CircularProgress } from "@mui/material";
import { generateChatId } from "../../../ServiceProvider/components/generateChatId";

const WorkerProfile = () => {
  const userdata = JSON.parse(localStorage.getItem("login"));
  const [loading, setloading] = useState(false);
  const {
    setIdget,
    setLoginmodalshow,
    Userid,
    setUserid,
    availidget,
    Adressmodelshow,
    setAdressmodelshow,
  } = useContext(GlobalContext);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [chatbox, setChatbox] = useState(false);
  const [Favoritess, setFavoritess] = useState([]);
  const [workerdata, setWorkerdata] = useState();
  const [workerrating, setWorkingrating] = useState([0]);
  const [showReviews, setShowReviews] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [startcount, setStartCount] = useState();
  // const [startcount, setStartCount] = useState();
  const navigate = useNavigate();

  const handleOpen = () => {
    setShowReviews(!showReviews);
    setShowMore(!showMore);
  };

  const handleClick = () => {
    setShowMore(!showMore);
  };

  let route = useLocation();

  const getdata = route?.state?.item;

  setUserid(getdata);
  const Workerlist = async () => {
    setloading(true);
    const headers = {
      "content-Type": "application/json",
      ...(userdata
        ? { Authorization: `Bearer ${userdata.data.data.api_token}` }
        : {}),
    };
    try {
      const res = await axios.post(
        "show-worker-details",
        {
          // user_id: getdata.id,
          worker_id: getdata.id,
        },
        {
          headers: headers,
        }

        // {
        //   headers: {
        //     "content-Type": "application/json",
        //     Authorization: `Bearer${userdata.data.data.api_token}`,
        //   },
        // }
      );

      setWorkerdata(res.data.data);
      setWorkingrating(res.data.data.UserRate);
      setStartCount(res.data.data.PercentageRatings);
      // console.log("Worker list-----------", res.data.data);
      setloading(false);
    } catch (error) {
      // toast.error(error.response.message, {
      //   position: "top-right",
      //   theme: "colored",
      // });
      setloading(false);
      // console.log(error);
    }
  };

  useEffect(() => {
    Workerlist();
  }, []);
  const worker = [
    {
      name: "Lorem Ipsum",
    },
    {
      name: "Contrary",
    },
    {
      name: "Bonorum",
    },
    {
      name: "Pellentesque",
    },
    {
      name: "Lorem Ipsum",
    },
    {
      name: "Contrary",
    },
    {
      name: "Bonorum",
    },
    {
      name: "Pellentesque",
    },
    {
      name: "Lorem Ipsum",
    },
    {
      name: "Contrary",
    },
    {
      name: "Bonorum",
    },
    {
      name: "Pellentesque",
    },
  ];
  const Mywork = [
    {
      img: bluecap,
    },
    {
      img: driller,
    },
    {
      img: headphoneman,
    },
    {
      img: pantman,
    },
    {
      img: calculatingman,
    },
    {
      img: bluecap,
    },
    {
      img: driller,
    },
    {
      img: headphoneman,
    },
    {
      img: pantman,
    },
    {
      img: calculatingman,
    },
  ];

  const addfavorites = async () => {
    setloading(true);
    // console.log(userdata.data.data.api_token);
    try {
      const response = await axios.post(
        "add-fav",
        {
          worker_id: getdata.id,
          is_like: 1,
        },
        {
          headers: {
            "content-Type": "application/json",
            Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
          },
        }
      );
      setloading(false);
      setFavoritess(response.data.favorite);
      if (response.data.status == "success") {
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
        Workerlist();
        // setIsAlreadyInFavorites(true);
      }
      // console.log(response, "addfavorite------");
    } catch (error) {
      // console.log("addfavorite------", error);
      // toast.error(error.response.data.message, {
      //   position: "top-left",
      //   theme: "colored",
      // });
      setloading(false);
    }
  };

  /*=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- REVIEW BOX START HERE-=-=-=-=-=-=-=-=-=-=-=-=-=- */

  let five = startcount?.five;
  let four = startcount?.four;
  let three = startcount?.three;
  let two = startcount?.two;
  let one = startcount?.one;
  let total = five + four + three + two + one;

  const Myarr = [five, four, three, two, one];

  const newarr = [];

  Myarr.map((_) => {
    let parsantage = parseInt(
      (_ * 100) / total
      // _ / 100 / getdata?.rate_count == null ? 0 : getdata.rate_count
    );

    // let parsantage = parseInt(_ / 100 / getdata?.rate_count);
    newarr.push(parsantage);
  });
  const handledata = () => {
    if (!userdata) {
      setLoginmodalshow(true);
    } else if (!availidget || availidget.length === 0) {
      setShow(true);
      toast.success("Please select an availability time first", {
        position: "top-right",
        theme: "colored",
      });
    } else {
      setAdressmodelshow(true);
    }
    setIdget(getdata);
  };
  const handleget = () => {
    setIdget(getdata);
    if (!userdata) {
      setLoginmodalshow(true);
    } else {
      setShow(true);
    }
  };

  const back_btn = (
    <svg
      width="25"
      height="15"
      viewBox="0 0 30 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.7013 8.21502H4.43366L10.2243 2.87187C10.7316 2.40408 10.7316 1.64524 10.2243 1.17752C9.71698 0.709461 8.89454 0.709461 8.38799 1.17752L0.380471 8.56593C-0.126824 9.03372 -0.126824 9.79262 0.380471 10.2603L8.38799 17.649C8.64156 17.8831 8.97389 18 9.30613 18C9.63838 18 9.9707 17.8831 10.2243 17.649C10.7316 17.1812 10.7316 16.4224 10.2243 15.9547L4.43366 10.6113H28.7013C29.4185 10.6113 30 10.0748 30 9.41313C30 8.75145 29.4185 8.21502 28.7013 8.21502Z"
        fill="black"
      />
    </svg>
  );
  const BacktoServicepage = (item) => {
    navigate(-1);
  };

  // ! for chat this code
  const chatid = generateChatId(userdata?.data?.data?.id, getdata?.id);
  // console.log("check id", userdata?.data?.data?.id);
  const messageSenderdata = {
    Myname: userdata?.data?.data?.name,
    Myid: userdata?.data?.data?.id,
    OppName: getdata?.user_name,
    OppId: getdata?.id,
    image: userdata?.data?.data?.profile_image,
    Roomid: chatid,
    Oppimage: workerdata?.Worker?.profile_image,
    RoomAvail: false,
  };

  // Convert object to JSON string
  const messageSenderdataJSON = JSON.stringify(messageSenderdata);

  // Store data in localStorage
  localStorage.setItem("messageSenderdata", messageSenderdataJSON);

  // !
  return (
    <>
      {/* <Navbar2 /> */}
      <Chatbox show={chatbox} onHide={() => setChatbox(false)} />
      {/* <AddressModal show={open} onHide={() => setOpen(false)} /> */}
      <AddressModal
        show={Adressmodelshow}
        onHide={() => setAdressmodelshow(false)}
      />
      <CheckAvalabilityModal show={show} onHide={() => setShow(false)} />
      <div className="AdvocateDetail-main-sec">
        <div className="AdvocateDetail-main-div">
          <div className="main_back_btn_div">
            <span className="back_bton" onClick={() => BacktoServicepage()}>
              {back_btn}
            </span>
          </div>
          <div className="AdvocateDetail-second-div">
            <div className="card-plumber-single-div">
              <div
                className="svg-image-div"
                onClick={(event) => {
                  event.stopPropagation();
                  {
                    userdata ? addfavorites() : setLoginmodalshow(true);
                  }
                }}>
                {workerdata?.IsLike == 1 ? (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_38_1771)">
                      <path
                        d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3Z"
                        fill="#00AD5A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_38_1771">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6_799)">
                      <path
                        d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604Z"
                        fill="#00AD5A"
                        // fill="#14161B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6_799">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
                {/* <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_6_799)">
                    <path
                      d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604Z"
                      fill="#14161B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6_799">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg> */}
              </div>
              <div>
                <img
                  src={workerdata?.Worker?.profile_image}
                  alt=""
                  className="item-image-card"
                />
              </div>
              <span className="item-span-card">{workerdata?.Worker?.name}</span>
              <div className="item-dono-div">
                <div className="image-review-dv">
                  <img src={star} alt="" className="star-img-90" />
                  <span className="image-review-span">
                    {getdata?.average_rate == null
                      ? 0
                      : parseFloat(getdata?.average_rate.toFixed(1))}
                  </span>
                  <span className="image-review-span">
                    ({getdata?.rate_count})
                  </span>
                </div>
                <div className="doller-thirty-exp-divv">
                  <span className="doller-thirty-span">₦ {getdata?.price}</span>
                </div>
              </div>

              <div className="item-dono-div">
                <div className="span-clem-exp-divv">
                  <span className="clean-exp-divv">
                    {getdata?.category_name}
                  </span>
                </div>
              </div>
              <div className="item-dono-div">
                <div className="span-clem-exp-divv">
                  <span className="clean-exp-divv_sub">
                    {getdata?.subcategory_name}
                  </span>
                </div>
              </div>
              <div className="book-btnn-divv">
                <button
                  className="book-button-spann"
                  onClick={(event) => {
                    event.stopPropagation();
                    {
                      userdata ? setChatbox(true) : setLoginmodalshow(true);
                    }
                  }}>
                  Send Message
                </button>
              </div>
            </div>
            <div className="About-maindib-jocob">
              <span className="About-span">
                About {workerdata?.Worker?.name}
              </span>
              <div className="redmore-spann">
                <span className="span-para-lorem-brooklyn">
                  {workerdata?.Worker?.description}
                </span>
                {/* <span className="red-read-more">...Read More</span> */}
              </div>
              <div className="Skills-div">
                <span className="Skills-span">Specialties</span>
                <div className="plumber-srch-divv mt-3">
                  {workerdata?.Worker?.skil.map((item, index) => {
                    return (
                      <>
                        <div key={index} className="plumber-single-text-boxx">
                          <span className="carpenter-header-divv">{item}</span>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="Resolution-Experience-spann-divv mt-4"></div>
              <div className="Resolution-Experience-spann-divv">
                <span className="Resolution-span">Experience</span>
                <div className="Contrary-spann">
                  <span className="span-para-lorem-brooklyn">
                    {workerdata?.Worker?.experience}
                  </span>
                </div>
              </div>
              <div className="Launch-model-divv mt-4">
                <div className="Modal-check-and-book-divv">
                  <Button
                    className="Check-Availability-btn"
                    variant="primary"
                    onClick={handleget}>
                    Check Availability
                  </Button>
                  <div className="book-btnn-divv-sec-check">
                    <button
                      className="book-button-sec-check-spann"
                      onClick={handledata}>
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="line-span"></div>
              <div className="My-Work-Images-Main-divv">
                <div className="My-work-imgg-spann-divv mt-4">
                  <span className="Reviews-span">My Work</span>
                </div>
                <div className="Work-images-divv-here mt-2">
                  {workerdata?.Worker_Images.map((item, index) => {
                    return (
                      <img
                        className="Work-images-of-workers"
                        src={item?.worker_img}
                        alt=""
                      />
                    );
                  })}
                </div>
              </div>

              {/*=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- REVIEW BOX START HERE-=-=-=-=-=-=-=-=-=-=-=-=-=- */}

              <div className="Reviews_sec">
                <div className="Reviews_sub_box">
                  <h2 className="mt-3">Reviews</h2>
                  <div className="secnd_div_review">
                    <div className="rate_box">
                      <h5>
                        Exceptional
                        <b>
                          {getdata?.average_rate == null
                            ? 0
                            : parseFloat(getdata?.average_rate.toFixed(1))}
                        </b>
                      </h5>
                      <div className="star_div">
                        {workerrating.slice(0, 1).map((item, index) => {
                          const RatingStar = () => {
                            if (item?.rating === "5") {
                              return (
                                <div className="Jerome-prof-rating-star-divv">
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                </div>
                              );
                            } else if (item?.rating === "4") {
                              return (
                                <div className="Jerome-prof-rating-star-divv">
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                </div>
                              );
                            } else if (item?.rating === "3") {
                              return (
                                <div className="Jerome-prof-rating-star-divv">
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                </div>
                              );
                            } else if (item?.rating === "2") {
                              return (
                                <div className="Jerome-prof-rating-star-divv">
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                </div>
                              );
                            } else if (item?.rating === "1") {
                              return (
                                <div className="Jerome-prof-rating-star-divv">
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                </div>
                              );
                            } else if (item?.rating === "0") {
                              return (
                                <div className="Jerome-prof-rating-star-divv">
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                </div>
                              );
                            }
                          };
                          return (
                            <>
                              <div
                                key={index}
                                className="Reviews-rating-desc-mian-divv">
                                <div className="jermo-reviews-date-june-divv mt-3">
                                  <RatingStar />
                                </div>
                                {/* <div className="Underline-main-divv mt-3"></div> */}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="total_rateing_box">
                      <p>({getdata?.rate_count}) Reviews</p>
                    </div>
                  </div>
                  {getdata?.rate_count == 0 ? (
                    <div className="norecorddiv">
                      <h1>No rating and reviews</h1>
                    </div>
                  ) : (
                    <>
                      <div className="therd_div_review">
                        {newarr.map((i, index) => {
                          return (
                            <div className="fivestar_div">
                              <div className="text_strt">
                                <h5>{newarr.length - 1 - index + 1}</h5>
                                <img src={ystar}></img>
                              </div>
                              <div className="rating_line_div">
                                <div
                                  className="inner_div"
                                  style={{ width: `${i}%` }}></div>
                              </div>
                              <div className="total_percentage">
                                <h6>{i}%</h6>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {/*=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- Review details box -=-=-=-=--=-=-=-=-=-=-=-=-=-=-*/}

                      <div className="Reviews-see-all-rating-main-div">
                        {workerrating.slice(0, 2).map((item, index) => {
                          const RatingStar = () => {
                            if (item?.rating === "5") {
                              return (
                                <div className="Jerome-prof-rating-star-divv">
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                </div>
                              );
                            } else if (item?.rating === "4") {
                              return (
                                <div className="Jerome-prof-rating-star-divv">
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                </div>
                              );
                            } else if (item?.rating === "3") {
                              return (
                                <div className="Jerome-prof-rating-star-divv">
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                </div>
                              );
                            } else if (item?.rating === "2") {
                              return (
                                <div className="Jerome-prof-rating-star-divv">
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                </div>
                              );
                            } else if (item?.rating === "1") {
                              return (
                                <div className="Jerome-prof-rating-star-divv">
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={ystar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                </div>
                              );
                            } else if (item?.rating === "0") {
                              return (
                                <div className="Jerome-prof-rating-star-divv">
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                  <div className="jermo-star-divv-yelow">
                                    <img
                                      className="yellow-star-bell-imgg"
                                      src={Gstar}
                                    />
                                  </div>
                                </div>
                              );
                            }
                          };
                          return (
                            <>
                              <div
                                key={index}
                                className="Reviews-rating-desc-mian-divv">
                                <div className="jermo-reviews-date-june-divv mt-3">
                                  <div className="Bell-rev-date-june-divv">
                                    <div className="Reviews-jermo-bell-mian-divv">
                                      <div className="Reviews-profile-photo-divv">
                                        <img
                                          className="Reviews-profile-imgg"
                                          src={
                                            item?.user_image
                                              ? item.user_image
                                              : Profile
                                          }
                                        />
                                      </div>
                                      <div className="Jerome-Bell-name-tagg">
                                        <div className="Jerome-PROF-BEL-tagg">
                                          <span className="Jerome-Bell-spnn-name">
                                            <b>{item?.user_name}</b>
                                          </span>
                                          <div className="date-jermo-text-june-divv">
                                            <span className="date-jermo-spann-june-divv">
                                              {item?.create_date}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <RatingStar />
                                </div>
                                <div className="lorem-date-jermo-divv mt-1">
                                  <span className="lorem-jermo-spn-divv-aat">
                                    {item?.review}
                                  </span>
                                </div>
                                <div className="Underline-main-divv mt-3"></div>
                              </div>
                            </>
                          );
                        })}

                        {showReviews && (
                          <div className="Reviews-see-all-rating-main-div">
                            {workerrating.slice(2).map((item, index) => {
                              const RatingStar = () => {
                                if (item?.rating === "5") {
                                  return (
                                    <div className="Jerome-prof-rating-star-divv">
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                    </div>
                                  );
                                } else if (item?.rating === "4") {
                                  return (
                                    <div className="Jerome-prof-rating-star-divv">
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                    </div>
                                  );
                                } else if (item?.rating === "3") {
                                  return (
                                    <div className="Jerome-prof-rating-star-divv">
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                    </div>
                                  );
                                } else if (item?.rating === "2") {
                                  return (
                                    <div className="Jerome-prof-rating-star-divv">
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                    </div>
                                  );
                                } else if (item?.rating === "1") {
                                  return (
                                    <div className="Jerome-prof-rating-star-divv">
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={ystar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                    </div>
                                  );
                                } else if (item?.rating === "0") {
                                  return (
                                    <div className="Jerome-prof-rating-star-divv">
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                      <div className="jermo-star-divv-yelow">
                                        <img
                                          className="yellow-star-bell-imgg"
                                          src={Gstar}
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              };
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="Reviews-rating-desc-mian-divv">
                                    <div className="jermo-reviews-date-june-divv mt-3">
                                      <div className="Bell-rev-date-june-divv">
                                        <div className="Reviews-jermo-bell-mian-divv">
                                          <div className="Reviews-profile-photo-divv">
                                            <img
                                              className="Reviews-profile-imgg"
                                              src={item?.user_image}
                                            />
                                          </div>
                                          <div className="Jerome-Bell-name-tagg">
                                            <div className="Jerome-PROF-BEL-tagg">
                                              <span className="Jerome-Bell-spnn-name">
                                                <b>{item?.user_name}</b>
                                              </span>
                                              <div className="date-jermo-text-june-divv">
                                                <span className="date-jermo-spann-june-divv">
                                                  {item?.create_date}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <RatingStar />
                                    </div>
                                    <div className="lorem-date-jermo-divv mt-1">
                                      <span className="lorem-jermo-spn-divv-aat">
                                        {item?.review}
                                      </span>
                                    </div>
                                    <div className="Underline-main-divv mt-3"></div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <div className="lorem-show-more-divv-ppx mt-4 mb-4">
                        <button
                          className="Lorem-read-mor-btnn"
                          onClick={handleOpen}>
                          {showMore ? (
                            <span className="Read-less-arrow-span">
                              See less Reviews
                            </span>
                          ) : (
                            <span className="Read-less-arrow-span">
                              See all Reviews
                            </span>
                          )}
                          {/* <img className="Lorem-imgg-downarrow" src={downarrow} /> */}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default WorkerProfile;
