import React, { useState, useContext } from "react";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import "../Modals/Rating.css";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import close from "../../assets/svg/Close.svg";
import bluejacket from "../../assets/svg/bluejacket.svg";
import axios from "../../../Common/Api/index";
import { toast } from "react-hot-toast";
import { Backdrop, CircularProgress } from "@mui/material";
import { GlobalContext } from "../../../GlobalContext";
// import ChangePassword from "../Modals/ChnagePassword/ChangePassword";bookingreload, setBookingreload
const initialValues = {
  w3review: "",
};
const Rating = ({ show, onHide, open, setOpen }) => {
  const { bookingreload, setBookingreload } = useContext(GlobalContext);
  // console.log("🚀 ~ Rating ~ setOpen:", setOpen);
  // console.log("🚀 ~ Rating ~ open:", open?.data?.worker_id);
  const userdata = JSON.parse(localStorage.getItem("login"));
  // const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  // const [workerID, setWorkerid] = useState();
  // console.log("🚀 ~ Rating ~ workerID:", workerID);
  const [loading, setloading] = useState(false);
  const StarGenerator = (numofstar = 5) => {
    return Array(numofstar)
      .fill()
      .map((item, i) => (
        <Star
          key={i}
          selected={selected > i}
          onSelected={() => setSelected(i + 1)}
        />
      ));
  };
  const Star = ({ selected, onSelected }) => {
    const StarIcon = selected ? AiFillStar : AiOutlineStar;

    return (
      <StarIcon
        className="fastar-mnb"
        color={selected ? "gold" : "gold"}
        onClick={onSelected}
      />
    );
  };
  // console.log(selected, "uuuuuu");
  const workerID = open?.data;
  // console.log("🚀 ~ Rating ~ workerID:", workerID);
  const onSubmit = async (values, { resetForm }) => {
    setloading(true);
    try {
      const response = await axios.post(
        "rate",
        {
          booking_id: workerID?.id,
          worker_id: workerID?.worker_id,
          rating: selected,
          review: values.w3review,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userdata.data.data.api_token}`,
          },
        }
      );

      // console.log("Rating updated====>", response);
      if (response.data.status == 1) {
        setloading(false);
        onHide();
        resetForm();
        setSelected("");
        setBookingreload(true);
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } else if (
        response.data.message == "You have already rated this worker"
      ) {
        toast.error(response.data.message, {
          position: "top-left",
          theme: "colored",
        });
      }
    } catch (error) {
      // toast.error(error.response.data.message, {
      //   position: "top-left",
      //   theme: "colored",
      // });
      setloading(false);
      // console.log("check error", error);
    }
  };

  const { values, handleBlur, handleChange, touched, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
    });
  return (
    <>
      {/* <ChangePassword show={open} onHide={() => setOpen(false)} /> */}
      <Modal show={show} onHide={onHide} size="md" centered>
        <div className="Close-btnn-modall-divv">
          <div className="Address-close-popup-divv">
            <img
              style={{ cursor: "pointer" }}
              className="close-Address-main-span"
              onClick={() => onHide()}
              src={close}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit} className="model-contain">
          <div className="Address-main-payment-title">
            <span className="Address-main-span-divv">Rate Now</span>
          </div>
          <div className="Rate-user-name">
            <div className="rating_profile_img">
              <img
                src={open?.data?.worker_image}
                className="Rateuser-img"
                alt=""
              />
            </div>
            <div className="jacob-spann-diivv">
              <span className="jacob-spann-tagg">
                {open?.data?.worker_name}
              </span>
            </div>
          </div>
          <div
            className="mb-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              flexWrap: "wrap",
            }}>
            {StarGenerator(5)}
          </div>
          <div className="Review-span-div">
            <span className="textatea-span">Write a Review</span>
            <textarea
              className="Write-a-text"
              placeholder="Write here"
              id="w3review"
              name="w3review"
              rows="5"
              value={values.w3review}
              onChange={handleChange}
              onBlur={handleBlur}
              cols="25"></textarea>
          </div>

          <div className="">
            <button
              type="submit"
              className="Submit-btn"
              // onClick={() => {
              //   setOpen(true);
              //   // onHide();
              // }}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Rating;
