import { useState, useEffect } from "react";
import Navbar from "../../../components/UI/Navbar";
import Footer from "../../../components/UI/Footer";
import "./Faq.css";
import Accordion from "react-bootstrap/Accordion";
import { BiRightArrowAlt } from "react-icons/bi";
import axios from "../../../../Common/Api/index";
import { Backdrop, CircularProgress } from "@mui/material";

const Faq = () => {
  const [FAQdata, setFAQdata] = useState();
  // console.log("🚀 ~ Faq ~ FAQdata:", FAQdata);
  const [loading, setLoading] = useState(true);
  const getApiData = async () => {
    try {
      const response = await axios.get("get-faqData");
      // console.log("🚀 res....", response);

      setFAQdata(response.data.faq);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getApiData();
  }, []);

  // const FAQdata = [
  //   {
  //     id: 1,
  //     No: 1,
  //     question: "How do I get started?",
  //     answer: "You can contact us at EMAIL",
  //   },
  //   {
  //     id: 2,
  //     No: 2,
  //     question: "How do I get started?",
  //     answer: "You can contact us at EMAIL",
  //   },
  //   {
  //     id: 3,
  //     No: 3,
  //     question: "How do I get started?",
  //     answer: "You can contact us at EMAIL",
  //   },
  //   {
  //     id: 4,
  //     No: 4,
  //     question: "How do I get started?",
  //     answer: "You can contact us at EMAIL",
  //   },
  //   {
  //     id: 5,
  //     No: 5,
  //     question: "How do I get started?",
  //     answer: "You can contact us at EMAIL",
  //   },
  // ];
  return (
    <>
      <Navbar />
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <div className="MAIN_FAQ">
            <div className="sub_main_div_FAQ">
              <div className="heading_text">
                <h1>FAQ's</h1>
              </div>
              <div className="accordion_div_FAQ">
                {FAQdata?.map((Item, Index) => {
                  return (
                    <Accordion style={{ width: "100%" }} key={Index}>
                      <Accordion.Item style={{ width: "100%" }} eventKey="0">
                        <Accordion.Header>
                          <div className="main_Qus_div">
                            <div className="Qusetion_No">
                              <h3>{Item.id}</h3>
                            </div>
                            <div className="Qusetion_text">{Item.title}</div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="ans_div">
                          <BiRightArrowAlt className="arrow_ans" />{" "}
                          <p>{Item.message}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Faq;
