import React, { useState } from "react";
import "../Chatscreen/Chatscreen.css";

// import List from "./List/List";
// import MessageBox from "";
import List from "../Chatscreen/List";
const Chatscreen = () => {
  const [showscreen, setshowscreen] = useState(false);
  const [toggle, settoggle] = useState(false);
  const [shownew, setshownew] = useState(false);
  return (
    <div className="All-Conatinor-perfect-divv">
      <div className="All-Containor-perfect-second-divv">
        <div className="Settings-Main-Span-divv">
          <span className="Settikgs-main-span">Messages</span>
        </div>
        <List
          setshowscreen={setshowscreen}
          settoggle={settoggle}
          toggle={toggle}
          onClick={() => setshownew(!shownew)}
        />
      </div>
    </div>
  );
};

export default Chatscreen;
