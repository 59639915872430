import React, { useState, useContext } from "react";
import "./Successfully.css";
import Navbar2 from "../UI/Nvabar2";
import Footer from "../UI/Footer";
import tick from "../../assets/svg/Successfully.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "../../../Common/Api/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { GlobalContext } from "../../../GlobalContext";

const Successfully = () => {
  const navigate = useNavigate();
  const { idget, Successdata, setSuccessdata } = useContext(GlobalContext);

  const userdata = JSON.parse(localStorage.getItem("login"));
  const bookingdata = JSON.parse(localStorage.getItem("Booking Success"));
  const [loading, setloading] = useState(false);
  const verfiyPayment = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "payment-verification",
        {
          booking_id: bookingdata?.booking_id,
          reference: bookingdata?.paystack_response?.reference,
        },
        {
          headers: {
            "content-Type": "application/json",
            Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
          },
        }
      );

      navigate("/");
      localStorage.removeItem("Booking Success");
      localStorage.removeItem("Paymentverify");
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <>
      {/* <Navbar2 /> */}
      <div className="main_div">
        <div className="sub_main_div">
          <div className="_img__box">
            <img src={tick} alt="" />
          </div>
          <div className="text">
            <h2>Successfully Done!</h2>
            <p>
              Your booking has been submitted successfully. Click on the below
              button to verify your booking
            </p>
          </div>
          <button className="sbtn_1" onClick={() => verfiyPayment()}>
            Verify
          </button>
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Successfully;
