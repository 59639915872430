// import { useState, useEffect, useContext } from "react";
// import Footer from "../../components/UI/Footer";
// import { useLocation, useNavigate } from "react-router-dom";
// import { GlobalContext } from "../../../GlobalContext";
// import "./subCat.css";

// const Subcategory = () => {
//   const { category } = useContext(GlobalContext);
//   const location = useLocation();
//   const CategoryID = location?.state?.cat_id;
//   return (
//     <div className="main_subcat_div">
//       <div className="sub_div_of_subcat">Subcategory</div>
//       <Footer />
//     </div>
//   );
// };

// export default Subcategory;
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import Footer from "../../components/UI/Footer";
import "./subCat.css";

const Subcategory = () => {
  const { category } = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const CategoryID = location?.state?.cat_id; // Adjusted for correct property access

  const [currentCategoryName, setCurrentCategoryName] = useState("");

  const [subCategories, setSubCategories] = useState([]);
  // console.log("🚀 ~ Subcategory ~ subCategories:", subCategories);
  useEffect(() => {
    // console.log("CategoryID:", CategoryID);
    // console.log("Category Data:", category);

    const currentCategory = category?.find(
      (cat) => cat.CatId === Number(CategoryID.CatId)
    );

    // console.log("Current Category:", currentCategory);

    if (currentCategory && currentCategory.Subcategory) {
      setSubCategories(currentCategory.Subcategory);
      setCurrentCategoryName(currentCategory.CatName);
    } else {
      setSubCategories([]);
      setCurrentCategoryName("");
    }
  }, [category, CategoryID.CatId]);
  const handleSubcategoryClick = (SubcateId) => {
    const cat_id = {
      CatId: CategoryID.CatId, // Pass CategoryID as CatId
      SubcateId: SubcateId,
    };
    navigate("/Services", {
      state: { cat_id },
    });
  };
  const back_btn = (
    <svg
      width="25"
      height="15"
      viewBox="0 0 30 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.7013 8.21502H4.43366L10.2243 2.87187C10.7316 2.40408 10.7316 1.64524 10.2243 1.17752C9.71698 0.709461 8.89454 0.709461 8.38799 1.17752L0.380471 8.56593C-0.126824 9.03372 -0.126824 9.79262 0.380471 10.2603L8.38799 17.649C8.64156 17.8831 8.97389 18 9.30613 18C9.63838 18 9.9707 17.8831 10.2243 17.649C10.7316 17.1812 10.7316 16.4224 10.2243 15.9547L4.43366 10.6113H28.7013C29.4185 10.6113 30 10.0748 30 9.41313C30 8.75145 29.4185 8.21502 28.7013 8.21502Z"
        fill="black"
      />
    </svg>
  );
  const BacktoServicepage = (item) => {
    navigate(-1);
  };

  return (
    <div className="main_subcat_div">
      <div className="sub_div_of_subcat">
        <div className="main_cat_name">
          <span className="back_bton" onClick={() => BacktoServicepage()}>
            {back_btn}
          </span>
          <h1>{currentCategoryName || "Category"}</h1>
        </div>
        <div className="subcategory_show_div">
          <div className="sub_cat_title">
            <h3>Subcategory</h3>
          </div>
          {subCategories.length > 0 ? (
            <div className="subcategory_name_main_div">
              {subCategories.map((sub) => (
                <div
                  className="subcat_card_div"
                  key={sub.SubcateId}
                  onClick={() => handleSubcategoryClick(sub.SubcateId)}>
                  <h4>{sub.SubcateName}</h4>
                </div>
              ))}
            </div>
          ) : (
            <p>No subcategories found for this category.</p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Subcategory;
