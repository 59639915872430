import "./sec7.css";
import Sec7data from "./sec7Data.js";
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import sec2data from "../section2/sec2data";

const Section7 = () => {
  const SwiperRef = useRef(null);
  const Prev = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_35_9001)">
        <path
          className="mySvgPath"
          d="M11.828 12L14.657 9.17202L13.243 7.75702L8.99998 12L13.243 16.243L14.657 14.828L11.828 12Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_9001">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
  const next = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_32)">
        <path
          className="mySvgPath"
          d="M12.172 12L9.34299 9.17202L10.757 7.75702L15 12L10.757 16.243L9.34299 14.828L12.172 12Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_32">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  return (
    <section className="sec7">
      <div className="container">
        <div className="hheading__sec2">
          <h2>Find our Experts</h2>
          <div className="div_bttn">
            <button
              className="swiper_btn"
              type="button"
              onClick={() => SwiperRef.current.swiper.slidePrev()}>
              <span>{Prev}</span>
            </button>
            <button
              className="swiper_btn"
              type="button"
              onClick={() => SwiperRef.current.swiper.slideNext()}>
              <span>{next}</span>
            </button>
          </div>
        </div>

        <div className="main_swiper_div">
          <Swiper
            ref={SwiperRef}
            slidesPerView={6}
            spaceBetween={10}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 50,
              },
            }}
            style={{ width: "100%" }}>
            {Sec7data.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="sec7_swiper_main_box">
                    <img
                      src={item.img}
                      alt=""
                      // style={{ width: "10%", height: "auto" }}
                    />
                    <div className="sec7_text_box">
                      <h6>{item.Name}</h6>
                      <p className="p__text">
                        {item.ico}
                        {item.rateing}
                        {item.totalrating}
                      </p>

                      <p>{item.disc}</p>
                    </div>
                    <button className="btn5">BOOK now</button>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Section7;
