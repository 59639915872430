import React from "react";
import "./sec3.css";
import sec3img from "../../../assets/sec3_1.png";
import { useNavigate } from "react-router-dom";

const Section3 = () => {
  const Naviagte = useNavigate();
  const check = (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 0C4.93281 0 0 4.93281 0 11C0 17.0672 4.93281 22 11 22C17.0672 22 22 17.0672 22 11C22 4.93281 17.0672 0 11 0Z"
        fill="#00AD5A"
      />
      <path
        d="M8.88164 16.0317C7.47656 14.6266 6.08008 13.2086 4.6707 11.8036C4.51602 11.6489 4.51602 11.3911 4.6707 11.2364L6.29062 9.61646C6.44531 9.46177 6.70313 9.46177 6.85781 9.61646L9.17383 11.9325L15.1293 5.97271C15.2883 5.81802 15.5418 5.81802 15.7008 5.97271L17.325 7.59692C17.484 7.75591 17.484 8.00942 17.325 8.16411L9.44883 16.0317C9.29414 16.1907 9.04062 16.1907 8.88164 16.0317Z"
        fill="white"
      />
    </svg>
  );
  const work_data = [
    { id: 1, ico: check, tittle: "Reliable Repairs" },
    { id: 1, ico: check, tittle: "Quick Repair" },
    { id: 1, ico: check, tittle: "Absolute Works" },
    { id: 1, ico: check, tittle: "Standard Works" },
  ];
  return (
    <div className="main_div_sec3">
      <div className="sub_main_div_sec3">
        <div className="sec3_left_side_text">
          <div className="abt_btn">
            <button onClick={() => Naviagte("/AboutUs")}>About Us</button>
          </div>
          <div className="text_box_sec3">
            <h2>
              We are expert in <br />
              all Handy Services
            </h2>
            <p>
              At HandyConnect, we believe in the power of <br />
              connections and the untapped potential within <br />
              each individual
            </p>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
              <br />
              blandit lorem. Morbi eleifend pulvinar tortor vel ullamcorper.
              <br />
              Curabitur ac pellentesque dolor. Proin quis neque sit amet. <br />
            </p> */}
          </div>

          <div className="work_category">
            {work_data.map((item, index) => {
              return (
                <div className="w_butn" key={index}>
                  <div className="data_box">
                    <span>{item.ico}</span>
                    <p>{item.tittle}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="sec3_right_side_img">
          <img src={sec3img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section3;
