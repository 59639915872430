import * as Yup from "yup";

export const LoginSchemas = Yup.object({
  email: Yup.string().email().required("Please Enter Your Email"),
  password: Yup.string().min(4).required("Please Enter Your Password "),
});

export const RegisterSchemas = Yup.object({
  FullName: Yup.string()
    .matches(
      /^[a-zA-Z\s]+$/,
      "Full Name should only contain letters and spaces"
    )
    .min(2, "Full Name must be at least 2 characters long")
    .max(25, "Full Name must be less than 25 characters long")
    .required("Please Enter Your Full Name"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid Email Format"
    )
    .required("Please Enter Your Email"),
  password: Yup.string().min(4).required("Please Enter Your Password "),
  MobileNumber: Yup.string()
    .matches(/^\d{3,10}$/, "Please enter a valid mobile number")
    .required("Please Enter Your Mobile Number"),
  // ConfirmPassword: Yup.string()
  //   .required()
  //   .oneOf([Yup.ref("NewPassword"), null], "Password Must Match"),
});

export const ForgotPasswordSchemas = Yup.object({
  email: Yup.string().email().required("Please Enter Your Email"),
});

export const PersonalSchemas = Yup.object({
  FullName: Yup.string()
    .matches(
      /^[a-zA-Z\s]+$/,
      "Full Name should only contain letters and spaces"
    )
    .min(2, "Full Name must be at least 2 characters long")
    .max(25, "Full Name must be less than 25 characters long")
    .required("Please Enter Your Full Name"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid Email Format"
    )
    .required("Please Enter Your Email"),
  password: Yup.string().min(4).required("Please Enter Your Password "),
  MobileNumber: Yup.string()
    .matches(/^\d{3,10}$/, "Please enter a valid mobile number")
    .required("Please Enter Your Mobile Number"),
  ConfirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), null], "Password Must Match"),
});

export const ProfessionalSchemas = Yup.object({
  RatePerhour: Yup.string().required("Required field can't be empty"),
  Writehere: Yup.string().required("Required field can't be empty"),
  ResolutionExperience: Yup.string()
    .min(5, "please add your experience in details")
    .required("Required field can't be empty"),
  Skills: Yup.string().required("Please add at least one skill"),
  SelectedCatId: Yup.string().required("Please select category"),
  SelectedSubcatId: Yup.string().required("Please select subcategory"),
});

export const AddressSchemas = Yup.object({
  YourName: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .max(25, "Please enter a valid name for your account")
    .required("Please Enter Your Full Name"),

  Mobile: Yup.string()
    .matches(/^\d{3,10}$/, "Please enter a valid mobile number")
    .required("Please Enter Your Mobile Number "),

  City: Yup.string()
    .min(2, "City name must be at least 2 characters")
    .max(20, "Please enter a valid city name")
    .required("Required field can't be empty"),

  Address: Yup.string()
    .min(10, "Address must be at least 10 characters")
    .max(40, "Please enter a valid Address ")
    .required("Required field can't be empty"),

  WorkRequirement: Yup.string()
    .min(3, "Work requirement must be at least")
    .max(100, "Work requirement cannot exceed 100 characters")
    .required("Please provide details about the work requirement"),
});

export const ModeoneSchemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please Enter Your Full Name"),
  LastName: Yup.string().min(2).max(25).required("Please Enter Your Last Name"),
  Address: Yup.string().min(2).max(25).required("Please Enter Your Address"),
  city: Yup.string().required("City is Required"),
  state: Yup.string().required("State is Required"),
});

export const ModeltwoSchemas = Yup.object({
  Number: Yup.string()
    .min(2)
    .max(10)
    .required("Please Enter Your Phone Number"),
});
export const EditProfileSchemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please Enter Your Full Name"),
  email: Yup.string().email().required("Please Enter Your Email"),
  MobileNumber: Yup.string()
    .matches(/^\d{3,10}$/, "Please enter a valid mobile number")
    .required("Please Enter Your Mobile Number"),
});

export const ChangePasswordSchemas = Yup.object({
  CurrentPassword: Yup.string()
    .min(4)
    .required("Please Enter  Current Password"),
  NewPassword: Yup.string().min(4).required("Please Enter Your Password"),
  ConfirmNewPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("NewPassword"), null], "Password Must Match"),
});

export const ContactusSchemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please Enter Your Full Name"),
  Email: Yup.string().email().required("Please Enter Your Email"),
  MobileNumber: Yup.number().required("Please Enter Your Mobile Number"),
  Message: Yup.string().min(2).max(25).required("Please Enter Message"),
});

export const EditProfile2Schemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please Enter Your Full Name"),
  email: Yup.string().email().required("Please Enter Your Email"),
  MobileNumber: Yup.string()
    .matches(/^\d{3,10}$/, "Please enter a valid mobile number")
    .required("Please Enter Your Mobile Number"),
  RatePerhour: Yup.string().required("Required field can't be empty"),
  Writehere: Yup.string().required("Required field can't be empty"),

  ResolutionExperience: Yup.string().required("Required field can't be empty"),
  SelectedCatId: Yup.string().required("Please select category"),
  SelectedSubcatId: Yup.string().required("Please select subcategory"),
});
