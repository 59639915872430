import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
// import {
//   requestPermission,
//   onMessageListener,
// } from "../../../Common/Firebase/firebase.jsx";

function Notification() {
  const [notification, setNotification] = useState({ title: "", body: "" });
  useEffect(() => {
    // requestPermission();

    // const unsubscribe = onMessageListener().then((payload) => {
    //   setNotification({
    //     title: payload.Notification.title,
    //     body: payload.Notification.body,
    //   });
    // });

    return () => {
      <div>
        {/* // unsubscribe.catch((err) => console.log("failed", err)); */}
      </div>;
    };
  }, []);

  return (
    <div>
      <Toaster />
    </div>
  );
}

export default Notification;
