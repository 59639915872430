import React, { useRef, useState, useEffect } from "react";
import "./nav.css";
import Logo from "../../../../User/assets/logo.png";
import hammer from "../../../../User/assets/Hammer.png";
import { FaAngleDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BiLogIn } from "react-icons/bi";

import Hamburger from "hamburger-react";
import axios from "../../../../Common/Api/index";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [category, setcategory] = useState([]);
  const [open, setIsopen] = useState(false);
  const ico = (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
        fill="white"
      />
    </svg>
  );
  const toggle = () => {
    setIsopen(false);
    // setshow(false);
  };
  const handlehide = () => {
    setIsopen(!open);
    // setshow(false);
  };

  let menuref = useRef();
  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsopen(false);
          // setonshow(false);
          // setshow(false);
        }
      }
    };
    // document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  }, []);
  const getcategory = async () => {
    setloading(true);
    try {
      const response = await axios.get("get-category", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setloading(false);
      // console.log("category list", response);
      setcategory(response.data.category);
    } catch (error) {
      setloading(false);
      // console.log(error);
    }
  };
  useEffect(() => {
    getcategory();
  }, []);
  const changecategory = () => {
    navigate("/Services");
  };

  return (
    <nav id="nav" ref={menuref}>
      <div className=" nav__container">
        <div className="Logo-lap-Divvv">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className="Logo-Phone-Divv">
          <Link to="/SP_Home">
            <img src={hammer} alt="" />
          </Link>
        </div>
        <div className="Search-login-Nav-divv">
          <div className="srch-loin-pc">
            <li>
              <NavLink to="" onClick={toggle}>
                {ico}
              </NavLink>
            </li>
            <button className="loginbtn" onClick={() => navigate("/Login")}>
              <BiLogIn className="loginicon" />
            </button>
          </div>

          <ul className={open ? "nav_menu mobilemenu" : "nav_menu"}>
            <li>
              <NavLink className="nav__link" to="/SP_Home" onClick={toggle}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className="nav__link" to="/HowitWorks" onClick={toggle}>
                How it Works
              </NavLink>
            </li>
            {/* <div class="dropdown">
              <button class="dropbtn">
                Services
                <FaAngleDown />
              </button>
              <div class="dropdown-content">
                <NavLink to="#" onClick={toggle}>
                  Services 1
                </NavLink>
                <NavLink to="#" onClick={toggle}>
                  Services 2
                </NavLink>
                <NavLink to="#" onClick={toggle}>
                  Services 3
                </NavLink>
              </div>
            </div> */}
            <select name="" id="" class="dropdown" onChange={changecategory}>
              <option class="dropbtn" value="">
                Services
              </option>
              {category.map((item, index) => {
                return (
                  // <div class="dropdown-content" >
                  <option key={index} value={item.id} onClick={toggle}>
                    {item.name}
                  </option>
                  // </div>
                );
              })}
            </select>
            <li>
              <NavLink
                className="nav__link"
                to="/SP_Contactus"
                onClick={toggle}>
                Contact Us
              </NavLink>
            </li>
            <div className="Login-search-green">
              <li>
                <NavLink to="/SP_Home" onClick={toggle}>
                  {ico}
                </NavLink>
              </li>
              <button
                className="btn1 primary"
                onClick={() => navigate("/SP_Login")}>
                Login
              </button>
            </div>
            <div className="btn_div">
              <button className="btn2" onClick={() => navigate("/SP_Login")}>
                Become a Service Provider
              </button>
            </div>
          </ul>
          <div className="menubtn">
            <Hamburger onToggle={handlehide} toggled={open} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
