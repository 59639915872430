import React from "react";
import Navbar from "../../../components/UI/Navbar_SP";
import Footer from "../../../components/UI/Footer";

const Index = () => {
  return (
    <>
      <Navbar />
      <div className="main_trems_div">
        <h1>Support</h1>
        <h6>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut magnam et
          repellendus dignissimos minus, totam quia tempore blanditiis rem
          reiciendis harum, ratione, est dolorem labore hic iure molestias rerum
          at aperiam voluptatibus voluptates maxime repellat amet! Vero odit
          deleniti corporis maxime explicabo vitae corrupti provident nulla
          placeat cupiditate molestias rerum, iste ipsa. Incidunt, blanditiis
          rerum sequi est accusamus nam asperiores tempora facilis veniam
          explicabo fugit nemo quo dolor maiores, assumenda voluptas consequatur
          vero quisquam! Hic voluptatem aliquid quod qui, molestias eligendi
          repellat veniam dolores? Beatae fugiat assumenda magnam quidem
          deserunt ipsam numquam doloremque praesentium unde, dolorem
          blanditiis. Necessitatibus, et recusandae.
        </h6>{" "}
        <h6>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut magnam et
          repellendus dignissimos minus, totam quia tempore blanditiis rem
          reiciendis harum, ratione, est dolorem labore hic iure molestias rerum
          at aperiam voluptatibus voluptates maxime repellat amet! Vero odit
          deleniti corporis maxime explicabo vitae corrupti provident nulla
          placeat cupiditate molestias rerum, iste ipsa. Incidunt, blanditiis
          rerum sequi est accusamus nam asperiores tempora facilis veniam
          explicabo fugit nemo quo dolor maiores, assumenda voluptas consequatur
          vero quisquam! Hic voluptatem aliquid quod qui, molestias eligendi
          repellat veniam dolores? Beatae fugiat assumenda magnam quidem
          deserunt ipsam numquam doloremque praesentium unde, dolorem
          blanditiis. Necessitatibus, et recusandae.
        </h6>
        <h6>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut magnam et
          repellendus dignissimos minus, totam quia tempore blanditiis rem
          reiciendis harum, ratione, est dolorem labore hic iure molestias rerum
          at aperiam voluptatibus voluptates maxime repellat amet! Vero odit
          deleniti corporis maxime explicabo vitae corrupti provident nulla
          placeat cupiditate molestias rerum, iste ipsa. Incidunt, blanditiis
          rerum sequi est accusamus nam asperiores tempora facilis veniam
          explicabo fugit nemo quo dolor maiores, assumenda voluptas consequatur
          vero quisquam! Hic voluptatem aliquid quod qui, molestias eligendi
          repellat veniam dolores? Beatae fugiat assumenda magnam quidem
          deserunt ipsam numquam doloremque praesentium unde, dolorem
          blanditiis. Necessitatibus, et recusandae.
        </h6>
      </div>
      <Footer />
    </>
  );
};

export default Index;
