import { useState, useEffect } from "react";

import Navbar from "../../../components/UI/Navbar";
import Footer from "../../../components/UI/Footer";
import axios from "../../../../Common/Api/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const [loading, setLoading] = useState(true);
  const [ApiData, setApiData] = useState();
  // console.log("🚀 ~ Index ~ ApiData:", ApiData);
  const [ApiError, setApiError] = useState();
  const navigate = useNavigate();
  const getApiData = async () => {
    try {
      const response = await axios.post("get-cmsData", {
        id: 2,
      });
      // console.log("🚀 res....", response);
      if (response.status === 200) {
        setApiData(response.data.data);
      }
    } catch (error) {
      setApiError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getApiData();
  }, []);

  return (
    <>
      <Navbar />
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div className="main_div_trems">
          <div className="main_trems_div">
            <div className="inner_div_trems">
              <div className="heading_text">
                <h1>Terms of Use</h1>
              </div>
              <div className="inner_text_main_div">
                <div dangerouslySetInnerHTML={{ __html: ApiData }}></div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Index;
