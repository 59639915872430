import React from "react";
import "./about.css";
import about1 from "../../assets/About_1.png";
import about2 from "../../assets/About_2.png";
import about3 from "../../assets/About_3.png";
import Navbar2 from "../../components/UI/Navbar";
import Footer from "../../components/UI/Footer";

const Aboutus = () => {
  return (
    <>
      {/* <Navbar2 /> */}
      <div className="about_main_section">
        <div className="sub_div_aboutsection">
          <div className="section1">
            <div className="sec1_img">
              <img src={about1} alt="" />
            </div>
            <div className="main_sec1_text">
              <div className="sec1_text">
                <h1>Welcome to HandyConnect</h1>
                <p>
                  At HandyConnect, we believe in the power of connections and
                  the untapped potential within each individual. Our platform is
                  more than just a marketplace—it's a community where skilled
                  workers and service seekers come together to unleash their
                  creativity, pursue their passions, and thrive in the digital
                  economy.
                </p>
              </div>
              <div className="sec1_text">
                <h1>Connecting Skilled Workers with Potential Customers</h1>
                <p>
                  Our mission is simple: to bridge the gap between skilled
                  workers and potential customers. Whether you're a seasoned
                  artisan with years of experience, a professional seeking to
                  reach out to more audience, or someone looking to explore a
                  new side hustle, our platform provides the tools and resources
                  you need to showcase your talents and connect with those in
                  need of your services.
                </p>
              </div>
            </div>
          </div>
          <div className="section2">
            <div className="main_sec1_text">
              <div className="sec2_text">
                <h1>Empowering Side Hustles</h1>
                <p>
                  We understand that not everyone has the luxury of relying
                  solely on traditional employment. That's why we're dedicated
                  to empowering individuals to pursue their passions and turn
                  their skills into viable side hustles. Whether you're a
                  carpenter, graphic designer, or aspiring photographer, our
                  platform offers a supportive environment where you can market
                  your services, grow your client base, and achieve financial
                  independence.
                </p>
              </div>
              <div className="sec2_text">
                <h1>Unlocking Hidden Potential</h1>
                <p>
                  At HandyConnect, we believe that everyone has something
                  valuable to offer. Even if you think you don't have
                  traditional skills or experience, we're here to show you that
                  you do. Whether it's offering tutoring services, freelance
                  writing, or virtual assistance, there's a place for you in our
                  community. We're committed to helping you discover your unique
                  talents, build confidence in your abilities, and realize your
                  full potential.
                </p>
              </div>
            </div>
            <div className="sec2_img">
              <img src={about2} alt="" />
            </div>
          </div>
          <div className="section3">
            <div className="sec3_div">
              <div className="sec3_heading">
                <h1>Our Success Rate</h1>
              </div>
              <div className="sec3_count_box">
                <div className="Clients">
                  <h1>4126</h1>
                  <p>Happy Clients</p>
                </div>
                <div className="Project">
                  <h1>250+</h1>
                  <p>Project Completed</p>
                </div>
                <div className="Average">
                  <h1>4+</h1>
                  <p>Average Rating</p>
                </div>
                <div className="Service">
                  <h1>3K+</h1>
                  <p>Service Providers</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section4">
            <div className="sec4img">
              <img src={about3} alt="" />
            </div>
          </div>
          <div className="section5">
            <div className="sec5_text">
              <h1>Join Our Community Today</h1>
              <p>
                Ready to embark on your journey to success? Join the
                HandyConnect community today and unlock endless opportunities to
                connect, create, and thrive. Whether you're a skilled worker
                looking to expand your clientele or someone eager to explore
                your hidden talents, we're here to support you every step of the
                way.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Aboutus;
