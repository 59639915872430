import { useState, useContext, useEffect } from "react";
import "./Model.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../../../GlobalContext";
import SetAvailability from "../SetAvailability/SetAvailability";

export const Availabletimeslot = () => {
  const { ModalofAvaliable, setModalofAvaliable, Avail, setAvail } =
    useContext(GlobalContext);
  const handleclickbtn = () => {
    setModalofAvaliable(false);
    setAvail(true);
  };
  return (
    <>
      <Modal
        show={ModalofAvaliable}
        onHide={setModalofAvaliable}
        size="md"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="newtitle">Set Availbility</Modal.Title>
        </Modal.Header>
        <Modal.Body className="Modalbody">
          <h4>please Set your Availbale Date and Time</h4>

          <button className="btn1" onClick={() => handleclickbtn()}>
            set Availability
          </button>
        </Modal.Body>
      </Modal>
      <SetAvailability
        show={Avail}
        onHide={() => setAvail(false)}
        setAvail={setAvail}
      />
    </>
  );
};
