import { createContext, useState } from "react";

export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [FirstModalShow, setFirstModalShow] = useState(false);
  const [SecondModalShow, setSecondModalShow] = useState(false);
  const [ThirdModalShow, setThirdModalShow] = useState(false);
  const [FourModalShow, setFourModalShow] = useState(false);
  const [SuccessfullyShow, setSuccessfullyShow] = useState(false);
  const [RegisterdataShow, setRegisterdataShow] = useState(null);
  const [profiledata, setprofiledata] = useState();
  const [ChangepassShow, setChangepassShow] = useState(false);
  const [idget, setIdget] = useState(null);
  const [availidget, setAvailidget] = useState(null);
  const [category, setcategory] = useState([]);
  const [Loginmodalshow, setLoginmodalshow] = useState(false);
  const [Catid, setCatid] = useState();
  const [Userid, setUserid] = useState();
  const [Sendotpnumber, setSendotpnumber] = useState({
    data: null,
    istrue: false,
  });
  const [otpVerify, setOtpVerify] = useState({
    data: null,
    istrue: false,
  });
  const [MyDashboard, setMyDashbord] = useState(0);
  const [Adressmodelshow, setAdressmodelshow] = useState(false);
  const [chats, setChats] = useState([]);
  const [user, setUser] = useState(null);
  const [chat, setChat] = useState();
  const [text, setText] = useState("");
  const [groupshow, setgroupshow] = useState();
  const [personallistdata, setpersonallistdata] = useState();
  const [listdata, setlistdata] = useState();
  const [Successdata, setSuccessdata] = useState();
  const [userNotificationcount, setusernotificationcount] = useState();
  const [WorkerNotificationcount, setWorkernotificationcount] = useState();
  const [position, setPosition] = useState({
    latitude: null,
    longitude: null,
  });
  const [ModalofAvaliable, setModalofAvaliable] = useState(false);
  const [Avail, setAvail] = useState(false);
  const [bookingreload, setBookingreload] = useState(false);
  const [profilereload, setProfilereload] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        FirstModalShow,
        setFirstModalShow,
        SecondModalShow,
        setSecondModalShow,
        ThirdModalShow,
        setThirdModalShow,
        FourModalShow,
        setFourModalShow,
        RegisterdataShow,
        setRegisterdataShow,
        profiledata,
        setprofiledata,
        ChangepassShow,
        setChangepassShow,
        idget,
        setIdget,
        availidget,
        setAvailidget,
        category,
        setcategory,
        Loginmodalshow,
        setLoginmodalshow,
        Catid,
        setCatid,
        Userid,
        setUserid,
        Sendotpnumber,
        setSendotpnumber,
        otpVerify,
        setOtpVerify,
        MyDashboard,
        setMyDashbord,
        Adressmodelshow,
        setAdressmodelshow,
        chats,
        setChats,
        user,
        setUser,
        chat,
        setChat,
        text,
        setText,
        Successdata,
        setSuccessdata,
        bookingreload,
        setBookingreload,
        profilereload,
        setProfilereload,
        // SuccessfullyShow,
        // setSuccessfullyShow,

        groupshow,
        setgroupshow,
        personallistdata,
        setpersonallistdata,
        listdata,
        setlistdata,
        userNotificationcount,
        setusernotificationcount,
        WorkerNotificationcount,
        setWorkernotificationcount,
        position,
        setPosition,
        ModalofAvaliable,
        setModalofAvaliable,
        Avail,
        setAvail,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
