import React, {
  useRef,
  useState,
  useEffect,
  useHistory,
  useContext,
} from "react";
import "./Navbar2.css";
import Logo from "../../../assets/logo.png";
import hammer from "../../../assets/Hammer.png";
import { FaAngleDown } from "react-icons/fa";
import Hamburger from "hamburger-react";
import { Link, NavLink } from "react-router-dom";
import profilepic from "../../../assets/Profilepic.png";
// import Dropdown from "react-bootstrap/Dropdown";
import NavDropdown from "react-bootstrap/NavDropdown";
import Profile from "../../../assets/svg/Profile.svg";
import axios from "../../../../Common/Api";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../GlobalContext";

const svgStyle = {
  transition: "fill 0.2s", // Optional: Add a transition for a smoother color change effect
};

const Navbar2 = () => {
  const navigate = useNavigate();
  const [open, setIsopen] = useState(false);
  const [loading, setloading] = useState(false);
  const userdata = JSON.parse(localStorage.getItem("login"));
  const { profiledata, setprofiledata, category, setcategory } =
    useContext(GlobalContext);

  const ico = (
    <svg
      style={svgStyle}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
        fill="white"
      />
    </svg>
  );
  const noti = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_91_3614)">
        <path
          d="M20 17H22V19H2V17H4V10C4 7.87827 4.84285 5.84344 6.34315 4.34315C7.84344 2.84285 9.87827 2 12 2C14.1217 2 16.1566 2.84285 17.6569 4.34315C19.1571 5.84344 20 7.87827 20 10V17ZM18 17V10C18 8.4087 17.3679 6.88258 16.2426 5.75736C15.1174 4.63214 13.5913 4 12 4C10.4087 4 8.88258 4.63214 7.75736 5.75736C6.63214 6.88258 6 8.4087 6 10V17H18ZM9 21H15V23H9V21Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_91_3614">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const profilee = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      id="profileico"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17_4180)">
        <path
          d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H18C18 20.4087 17.3679 18.8826 16.2426 17.7574C15.1174 16.6321 13.5913 16 12 16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
          fill="#14161B"
          // fill={hover ? "white" : "black"}
        />
      </g>
      <defs>
        <clipPath id="clip0_17_4180">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const logout = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      id="profileico"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17_4182)">
        <path
          d="M5 22C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V6H18V4H6V20H18V18H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z"
          fill="#14161B"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_4182">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const toggle = () => {
    setIsopen(false);
    // setShowDropdown(false);
    // setshow(false);
  };
  const handlehide = () => {
    setIsopen(!open);
  };
  let menuref = useRef();
  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsopen(false);
          // setonshow(false);
          // setshow(false);
        }
      }
    };
    // document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  }, []);

  const Logout = async () => {
    try {
      const response = await axios.get("logout", {
        headers: {
          // Authorization: `${token.data.api_token}`,
          Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
        },
      });
      // console.log(response);
      if (response.data.message == "User logged out successfully") {
        localStorage.removeItem("login", JSON.stringify(response));
        // localStorage.setItem("login", JSON.stringify(response));
        toast.success(`${response.data.message}`, {
          position: "top-right",
          theme: "colored",
        });
        navigate("/");
        // <Navbar />;
        window.location.reload(true);
      }
    } catch (error) {
      // console.log(error);
      // toast.error(`${error?.response?.message}`, {
      //   position: "top-left",
      //   theme: "colored",
      // });
    }
  };

  const handleLogoutClick = () => {
    Logout();
    // console.log("Logout clicked");
    // Add your logic here to handle the logout action.
  };

  //  ! get profile api calling this function
  const getProfile = async () => {
    try {
      const response = await axios.get("get-profile", {
        headers: {
          // Authorization: `${token.data.api_token}`,
          Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
        },
      });
      // console.log(
      //   "🚀 ~ file: EditProfile.jsx:97 ~ getProfile ~ response:",
      //   response
      // );
      setprofiledata(response.data.data);
    } catch (error) {
      // console.log(error);
      // toast.error(`${error?.response?.message}`);
    }
  };
  useEffect(() => {
    getProfile();
  }, [setprofiledata]);
  //! get profile api function End
  const changecategory = (e) => {
    const cat_id = e.target.value;
    // console.log("change category ====>", cat_id);
    setTimeout(() => {
      navigate("/Services", {
        state: {
          cat_id,
        },
      });
    }, 100);
  };
  // // ! get Category api call
  // const getcategory = async () => {
  //   setloading(true);
  //   try {
  //     const response = await axios.get("get-category", {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     setloading(false);
  //     console.log("category list", response.data.category);
  //     setcategory(response.data.category);
  //   } catch (error) {
  //     setloading(false);
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   getcategory();
  // }, []);
  // //! get Category api function End
  return (
    <nav id="nav" ref={menuref}>
      <div className="nav-component-divv">
        <div className="Logo-lap-Divvv">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className="mobile_main_menu_box">
          <div className="Logo-Phone-Divv">
            <Link to="/">
              <img src={hammer} alt="" />
            </Link>
          </div>
          <div className="menu_text_div">
            <div className="Noti-guy-Srch-divv">
              <li>
                <NavLink to="/Search" onClick={toggle}>
                  {ico}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Profile/Notification"
                  onClick={toggle}
                  className="notification">
                  {noti}
                  <span class="badge">3</span>
                </NavLink>
              </li>
              <div className="btn_div">
                <div className="profile_div">
                  <div className="profile_pic_box">
                    <img
                      src={
                        profiledata?.profile_image
                          ? profiledata?.profile_image
                          : Profile
                      }
                      alt=""
                    />
                  </div>
                  <div className="dropdown_div">
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title=""
                      className="drop_tittle"
                      menuVariant="dark">
                      <NavDropdown.Item href="Profile" className="drop_itme">
                        {profilee} My Profile
                        {/* <NavLink to="/Profile">
                      </NavLink> */}
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/" className="drop_itme">
                        {logout}Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="menubtn">
              <Hamburger onToggle={handlehide} toggled={open} />
            </div>
          </div>
        </div>
        <ul className={open ? "nav_menu mobilemenu" : "nav_menu"}>
          <li>
            <NavLink className="nav__link" to="/" onClick={toggle}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink className="nav__link" to="/HowitWorks" onClick={toggle}>
              How it Works
            </NavLink>
          </li>
          {/* <div class="dropdown">
            <button class="dropbtn">
              Services
              <FaAngleDown />
            </button>
            <div class="dropdown-content">
              <NavLink to="/Services" onClick={toggle}>
                Services 1
              </NavLink>
              <NavLink to="/Services" onClick={toggle}>
                Services 2
              </NavLink>
              <NavLink to="/Services" onClick={toggle}>
                Services 3
              </NavLink>
            </div>
          </div> */}
          <select
            name=""
            id=""
            className="dropdown"
            // value={idd}
            onChange={changecategory}>
            <option class="dropbtn" value="" selected disabled>
              Services
            </option>
            {category.map((item, index) => {
              return (
                // <div class="dropdown-content" >
                <option class="dropbtn" key={index} value={item.id}>
                  {item.name}
                </option>
                // </div>
              );
            })}
          </select>
          <li>
            <NavLink className="nav__link" to="/ContactUs" onClick={toggle}>
              Contact Us
            </NavLink>
          </li>
          <div className="Srch-noti-guy-divv">
            <li>
              <NavLink to="/Search" onClick={toggle}>
                {ico}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Profile/Notification"
                onClick={toggle}
                className="notification">
                {noti}
                <span class="badge">3</span>
              </NavLink>
            </li>
            <div className="btn_div">
              <div className="profile_div">
                <div className="profile_pic_box">
                  <img
                    src={
                      profiledata?.profile_image
                        ? profiledata?.profile_image
                        : Profile
                    }
                    alt=""
                  />
                </div>
                <div className="dropdown_div">
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title={profiledata?.name}
                    className="drop_tittle"
                    menuVariant="dark">
                    <NavDropdown.Item href="Profile" className="drop_itme">
                      {profilee} My Profile
                      {/* <NavLink to="/Profile">
                      </NavLink> */}
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      // href="/"
                      onClick={handleLogoutClick}
                      className="drop_itme">
                      {logout}Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar2;
