import { useState, useContext } from "react";
import { GlobalContext } from "../../../../GlobalContext";
import "../BookNowModal/BookNow.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Payment from "../PaymentModal/Payment";
import close from "../../../assets/svg/Close.svg";

const BookNow = ({ show, onHide }) => {
  const [startDate, setStartDate] = useState(new Date());
  const { idget } = useContext(GlobalContext);
  const { availidget } = useContext(GlobalContext);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  // console.log(idget, "kkkkkk");
  // console.log(availidget, "kkkkkk");
  return (
    <>
      <Payment show={open} onHide={() => setOpen(false)} />
      <Modal show={show} onHide={onHide} size="md" centered>
        <div className="Close-btnn-modall-divv">
          <div className="Address-close-popup-divv">
            <img
              className="close-Address-main-span"
              onClick={() => onHide()}
              src={close}
            />
          </div>
        </div>
        <div className="Address-main-payment-title">
          <span className="Address-main-span-divv">Book Now</span>
        </div>
        <div className="Select-div">
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
          <div className="Span-divv-select-pay">
            <span className="Select-time-span">Select Time</span>
          </div>
          <div className="mt-2 all-time-btn">
            <button className="time-apoy-mant">8:00 to 8:30 AM</button>
            <button className="time-apoy-mant">8:30 to 9:00 AM</button>
            <button className="time-apoy-mant">9:00 to 9:30 AM</button>
            <button className="time-apoy-mant">9:30 to 10:00 AM</button>
            <button className="time-apoy-mant">10:00 to 10:30 AM</button>
            <button className="time-apoy-mant">10:30 to 11:00 AM</button>
            <button className="time-apoy-mant">11:00 to 11:30 AM</button>
            <button className="time-apoy-mant">11:30 to 12:00 PM</button>
            <button className="time-apoy-mant">12:00 to 12:30 PM</button>
            <button className="time-apoy-mant">12:30 to 1:00 PM</button>
          </div>
          <button
            className="Book-Now-text mt-4"
            onClick={() => {
              onHide();
              setOpen(true);
            }}>
            Book Now
          </button>
          <div className="mb-4"></div>
        </div>
      </Modal>
    </>
  );
};

export default BookNow;
