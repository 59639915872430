import sec5_1 from "../../../assets/sec5_1.png";
import sec5_2 from "../../../assets/sec5_2.png";
import sec5_3 from "../../../assets/sec5_3.png";
import sec5_4 from "../../../assets/sec5_4.png";

const sec5data = [
  {
    id: 1,
    pic: sec5_1,
    title: "1. Explore & Search",
    pre: "Explore available services by browsing categories, using keywords, and applying filters to find the perfect fit for your needs.",
  },
  {
    id: 2,
    pic: sec5_2,
    title: "2. Connect",
    pre: "Reach out to your preferred service provider and start discussing your project. ",
  },
  {
    id: 3,
    pic: sec5_3,
    title: "3. Book Your Service",
    pre: "Select a Gig package and place your order, or request a personalized offer before confirming your requirements and placing your order",
  },
  {
    id: 4,
    pic: sec5_4,
    title: "4. Review & confirm",
    pre: "Receive the service delivery and provide feedback to the provider, helping others in the community make informed decisions. ",
  },
];

export default sec5data;
