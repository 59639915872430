/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useRef, useState } from "react";
import "./MessageBox.css";
// import { Icons } from "../../Constant/Icons";
// import { Cardimages } from "../../Constant/Cardimages";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "@firebase/firestore";
import { db } from "../../../Common/Firebase/firebase";

const Messages = ({ toggle }) => {
  const [message, setmessage] = useState("");
  const [messagedata, setmessagedata] = useState([]);
  const [meData, setMeData] = useState(null);
  const divforscroll = useRef(null);
  //   const { groupshow } = useContext(GlobalContext);
  const route = useLocation();
  const userdata = route?.state?.item;
  // console.log("🚀 ~ Messages ~ userdata:", userdata);
  const Mydata = JSON.parse(localStorage.getItem("login"));
  //   const Mydata = store.getState().session[KEY_USER_DATA];
  const [loading, setloading] = useState(false);

  const Arrow = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_11_1600)">
        <path
          d="M3 13H9V11H3V1.84598C3.00001 1.7591 3.02267 1.67372 3.06573 1.59825C3.1088 1.52279 3.17078 1.45985 3.24558 1.41564C3.32037 1.37143 3.4054 1.34747 3.49227 1.34613C3.57915 1.34479 3.66487 1.36611 3.741 1.40798L22.203 11.562C22.2814 11.6051 22.3468 11.6686 22.3923 11.7456C22.4378 11.8226 22.4618 11.9105 22.4618 12C22.4618 12.0895 22.4378 12.1773 22.3923 12.2544C22.3468 12.3314 22.2814 12.3948 22.203 12.438L3.741 22.592C3.66487 22.6339 3.57915 22.6552 3.49227 22.6538C3.4054 22.6525 3.32037 22.6285 3.24558 22.5843C3.17078 22.5401 3.1088 22.4772 3.06573 22.4017C3.02267 22.3263 3.00001 22.2409 3 22.154V13Z"
          fill="#00AD5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_1600">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  // useEffect for get Chat data....................
  useEffect(() => {
    if (userdata) {
      setloading(true);
      const q = query(
        collection(db, "chatrooms", userdata.a.chatid, "mychat"),
        orderBy("time", "asc")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const arr = [];
        snapshot.forEach((doc) => {
          arr.push(doc.data());
        });
        setmessagedata(arr);
        setloading(false);
        if (divforscroll.current) {
          divforscroll.current.scrollTop = divforscroll.current.scrollHeight;
        }
      });
      return unsubscribe;
    }
    fetchMeData();
  }, [userdata]);
  // console.log(userdata);
  const fetchMeData = async () => {
    try {
      const meRef = doc(
        db,
        "users",
        JSON.stringify(Mydata.data.data.id),
        "mychat",
        userdata.OppId
      );

      const meDoc = await getDoc(meRef);
      if (meDoc.exists()) {
        setMeData(meDoc.data());
        // console.log(meDoc.data());
      } else {
        // console.log("Document does not exist");
      }
    } catch (error) {
      // console.error("Error fetching document:", error);
    }
  };
  // for send message.........................
  // const OnsendMessages = async (e) => {
  //   if (message) {
  //     var sentMsg = message;
  //     e.preventDefault();
  //     setmessage("");
  //     const messdata = {
  //       message: message,
  //       sendername: Mydata.data.data.name,
  //       fromid: Mydata.data.data.id,
  //       time: Date.now() * 1000,
  //       seen: false,
  //     };

  //     const otherdata = {
  //       chatid: userdata.a.chatid,
  //       lastmessageid: Mydata.data.data.id,
  //       lastmessage: sentMsg,
  //       lastmessagetime: Date.now() * 1000,
  //       unreadcount: "0",
  //     };
  //     const mydata = {
  //       chatid: userdata.a.chatid,
  //       lastmessageid: Mydata.data.data.id,
  //       lastmessage: sentMsg,
  //       lastmessagetime: Date.now() * 1000,
  //       unreadcount:
  //         meData != null ? (+meData?.unreadcount + 1).toString() : "0",
  //     };

  //     if (divforscroll.current) {
  //       divforscroll.current.scrollTop = divforscroll.current.scrollHeight;
  //     }

  //     sendMessage(messdata);

  //     // for save last message in my
  //     const mydetail = doc(
  //       collection(db, "users"),
  //       JSON.stringify(Mydata.data.data.id)
  //     );
  //     const oppdetail = doc(
  //       collection(mydetail, "mychat"),
  //       JSON.stringify(userdata.a.OppId)
  //     );
  //     setDoc(oppdetail, otherdata);
  //     // for save last message in other
  //     const oppdocref = doc(
  //       collection(db, "users"),
  //       JSON.stringify(userdata.a.OppId)
  //     );
  //     const oppndocref = doc(
  //       collection(oppdocref, "mychat"),
  //       JSON.stringify(Mydata.data.data.id)
  //     );
  //     // const messageRef = await addDoc(
  //     //   collection(db, "groups", userdata.a.chatid)
  //     // );
  //     // await updateDoc(doc(db, "groups", userdata.a.chatid), {
  //     //   lastmessage: sentMsg,
  //     //   lastmessagetime: new Date(),
  //     // });
  //     setDoc(oppndocref, mydata);
  //   }
  // };
  // ?
  const OnsendMessages = async (e) => {
    if (message) {
      var sentMsg = message;
      e.preventDefault();
      setmessage("");
      const messdata = {
        message: message,
        sendername: Mydata.data.data.name,
        fromid: Mydata.data.data.id,
        time: Date.now() * 1000,
        seen: false,
      };

      const otherdata = {
        chatid: userdata.a.chatid,
        lastmessageid: Mydata.data.data.id,
        lastmessage: sentMsg,
        lastmessagetime: Date.now() * 1000,
        unreadcount: "0",
      };

      // Increase the unread count for the recipient
      let newUnreadCount = 1;
      if (meData && meData.unreadcount) {
        newUnreadCount = parseInt(meData.unreadcount) + 1;
      }

      const mydata = {
        chatid: userdata.a.chatid,
        lastmessageid: Mydata.data.data.id,
        lastmessage: sentMsg,
        lastmessagetime: Date.now() * 1000,
        unreadcount: newUnreadCount,
      };

      // Scroll to bottom of chat
      if (divforscroll.current) {
        divforscroll.current.scrollTop = divforscroll.current.scrollHeight;
      }

      // Send the message to Firestore
      await sendMessage(messdata);

      // Update the last message in the current user's chat data
      const mydetail = doc(
        collection(db, "users"),
        JSON.stringify(Mydata.data.data.id)
      );
      const oppdetail = doc(
        collection(mydetail, "mychat"),
        JSON.stringify(userdata.a.OppId)
      );
      await setDoc(oppdetail, otherdata);

      // Update the unread count in the recipient's chat data
      const oppdocref = doc(
        collection(db, "users"),
        JSON.stringify(userdata.a.OppId)
      );
      const oppndocref = doc(
        collection(oppdocref, "mychat"),
        JSON.stringify(Mydata.data.data.id)
      );
      await setDoc(oppndocref, mydata, { merge: true });
    }
  };
  // ?

  // console.log(Mydata.detail);

  // sendmessage............................
  const sendMessage = async (mess) => {
    const docRef = await addDoc(
      collection(db, "chatrooms", userdata.a.chatid, "mychat"),
      mess
    );
  };

  // console.log(messagedata);
  function splitTextIntoLines(message) {
    const maxLength = 35;
    const chunks = [];

    for (let i = 0; i < message.length; i += maxLength) {
      chunks.push(message.substr(i, maxLength));
    }

    return chunks.map((chunk, index) => (
      <React.Fragment key={index}>
        {chunk}
        <br />
      </React.Fragment>
    ));
  }
  // console.log(userdata);
  const Navigate = useNavigate();
  const back_btn = (
    <svg
      width="25"
      height="15"
      viewBox="0 0 30 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.7013 8.21502H4.43366L10.2243 2.87187C10.7316 2.40408 10.7316 1.64524 10.2243 1.17752C9.71698 0.709461 8.89454 0.709461 8.38799 1.17752L0.380471 8.56593C-0.126824 9.03372 -0.126824 9.79262 0.380471 10.2603L8.38799 17.649C8.64156 17.8831 8.97389 18 9.30613 18C9.63838 18 9.9707 17.8831 10.2243 17.649C10.7316 17.1812 10.7316 16.4224 10.2243 15.9547L4.43366 10.6113H28.7013C29.4185 10.6113 30 10.0748 30 9.41313C30 8.75145 29.4185 8.21502 28.7013 8.21502Z"
        fill="black"
      />
    </svg>
  );
  const BacktoServicepage = (item) => {
    Navigate(-1);
  };
  return (
    <div className="All-Conatinor-perfect-divv">
      <div
        className="All-Containor-perfect-second-divv"
        style={{ width: "100%", marginTop: "1rem" }}>
        <div
          // className="main_chat_container"
          className={toggle ? "boxmaindivactive" : "boxmaindiv"}
          style={{ borderRadius: "10px" }}>
          <div className="main_header_div_of_chat">
            <div className="main_back_btn_div">
              <span className="back_bton" onClick={() => BacktoServicepage()}>
                {back_btn}
              </span>
            </div>
            <div className="boxheader_chatscreen_div">
              <div className="chatboxheader_chatscreen">
                {userdata && (
                  <img src={userdata?.a.image || userdata?.b?.image} alt="" />
                )}
                <h3>
                  {userdata?.a.OppName || userdata?.b?.name || userdata?.a.name}
                </h3>
              </div>
            </div>
          </div>

          <div
            className="chatmessages"
            ref={divforscroll}
            style={{
              overflowY: "scroll",
              scrollBehavior: "smooth",
            }}>
            <>
              {/* {!userdata ? null : loading ? (
            //   <SimpleLoader style={{ height: "400px" }} />
            ) : ( */}
              <>
                {messagedata?.map((item, index) => {
                  const itemTimeMicroseconds = item.time;
                  const itemTimeMilliseconds = Math.floor(
                    itemTimeMicroseconds / 1000
                  );
                  const createdTime = new Date(itemTimeMilliseconds);
                  const formattedTime = createdTime.toLocaleTimeString(
                    "en-US",
                    {
                      hour: "numeric",
                      minute: "numeric",
                    }
                  );
                  return (
                    <div
                      key={index}
                      style={{
                        alignItems:
                          item.fromid == Mydata.data.data.id
                            ? "flex-end"
                            : "flex-start",
                      }}>
                      <div>
                        {item.sendername !== Mydata?.detail?.firstname && (
                          <span style={{ fontSize: "13px" }}>
                            {item.sendername}
                          </span>
                        )}
                        <span
                          style={{
                            paddingLeft: "5px",
                            alignSelf:
                              item.fromid == Mydata.data.data.id
                                ? "flex-end"
                                : "flex-start",
                          }}
                          className="mestime">
                          {formattedTime}
                        </span>
                      </div>
                      <p
                        className={
                          item.fromid == Mydata.data.data.id ? "sent" : "chatp"
                        }
                        style={{
                          alignSelf:
                            item.fromid == Mydata.data.data.id
                              ? "flex-end"
                              : "flex-start",
                        }}>
                        {splitTextIntoLines(item.message)}
                      </p>
                    </div>
                  );
                })}
              </>
              {/* )} */}
            </>
            <span>{userdata?.time}</span>
          </div>
          <div className="sendmessage">
            <textarea
              value={message}
              onChange={(e) => setmessage(e.target.value)}
              type="text"
              placeholder="Write here"
            />
            <div
              type="submit"
              disabled={message === "" ? "disable" : ""}
              onClick={OnsendMessages}>
              <span>{Arrow}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
