import { useContext } from "react";
import { GlobalContext } from "../../../GlobalContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/logo.png";
import logo from "../../assets/Hammer.png";

const Loginmodal = () => {
  const { Loginmodalshow, setLoginmodalshow, Catid, Userid } =
    useContext(GlobalContext);
  // console.log("🚀 ~ Loginmodal ~ Catid:", Catid);

  const navigate = useNavigate();
  const location = useLocation();
  // console.log("🚀 ~ Loginmodal ~ location:", location);

  const handleLoginClick = () => {
    setLoginmodalshow(false);
    const cat_id = {
      pathname: location.pathname,
      Catid: Catid,
      Userid: location?.state?.item,
    };

    navigate("/Login", {
      state: { cat_id },
    });
    // console.log("handleLoginClick ====> ", cat_id);
  };
  const bluehamer = (
    <svg
      width="50"
      height="52.48"
      viewBox="0 0 102 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.8193 84.4461C44.9933 83.3756 44.6521 81.9972 44.8835 80.6648C45.1219 79.2897 45.9235 78.1418 47.0246 77.4303C46.4867 76.5123 46.2375 75.4394 46.3309 74.3647C46.5196 72.1889 48.0012 70.4468 49.9879 69.856C49.4223 68.8892 49.1745 67.7553 49.3097 66.6289C49.4993 65.0426 50.3976 63.6971 51.6619 62.887C51.026 62.0498 50.643 61.0084 50.643 59.8773C50.643 58.7739 51.0077 57.7517 51.6202 56.922C51.4474 56.9309 51.2724 56.9361 51.095 56.9361C49.4434 56.9361 47.9289 56.5254 46.598 55.7285C46.3234 56.7582 45.9362 57.9094 45.3913 59.0644C44.1265 61.7419 42.3694 63.7248 40.195 64.9764L32.2104 94.5111L44.3091 99.7323L48.0125 86.0352C47.1532 85.725 46.3863 85.1811 45.8193 84.4461Z"
        fill="#1A59F3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M82.7562 28.6825L78.5187 27.5589L78.5173 27.5641L73.214 26.1571L72.0905 30.3917C71.6282 31.5219 68.2566 31.5941 67.0462 31.267C64.6504 30.6189 62.7107 29.0373 62.9721 27.7645L64.0492 23.7035L54.7186 21.3114C43.7816 18.4105 32.5639 24.9247 29.6631 35.8616L33.7983 36.9584C37.1459 24.5755 50.5961 29.5629 49.3398 34.2111L47.5038 41.0016L48.2636 41.9313L56.3622 51.971L56.4636 52.0967L58.4521 44.7435L58.4675 44.7472L59.3419 41.4512L59.3428 41.4479L59.5446 40.6862C59.9478 39.4515 62.2925 39.0042 64.8132 39.6856C66.0236 40.0128 68.9137 41.7553 68.7555 42.9662L67.632 47.2008L77.1727 49.7314L82.7548 28.6877L82.7562 28.6825Z"
        fill="#1A59F3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M61.9515 56.4717C61.8928 56.4717 61.8337 56.4736 61.7741 56.4768C59.9831 56.5829 57.7412 56.7153 55.9184 56.8223C55.7649 56.8312 55.6081 56.8406 55.4612 56.849C53.8571 56.9424 52.6045 58.271 52.6045 59.878C52.6045 60.7838 53.0011 61.5882 53.6234 62.1415C53.8937 62.0979 54.1697 62.0707 54.4513 62.0707C54.4672 62.0707 54.4836 62.0721 54.4996 62.0721C54.5888 62.073 54.6784 62.0749 54.7685 62.0805C56.6223 62.1931 59.0041 62.3368 60.9814 62.455L62.2054 62.3476C63.7255 62.2143 64.8913 60.9415 64.8913 59.4152C64.8913 57.7806 63.564 56.4717 61.9515 56.4717Z"
        fill="#1A59F3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M61.3429 64.4341C61.2327 64.4275 61.1149 64.4205 61.0017 64.4139C58.9963 64.2933 56.5427 64.1459 54.6429 64.03C54.5768 64.0263 54.5106 64.0239 54.4444 64.0239C54.2778 64.0239 54.1145 64.0399 53.9535 64.0648C53.7085 64.1028 53.471 64.1671 53.2462 64.2581C52.1879 64.6847 51.3938 65.6586 51.2502 66.8624C51.1183 67.9624 51.559 68.9874 52.3245 69.6614C52.3635 69.6623 52.4005 69.6637 52.4399 69.6647C54.3135 69.7177 56.5437 69.7792 58.3407 69.8285C59.2916 69.8547 60.2105 70.157 60.9952 70.6742L61.0571 70.676C61.0905 70.677 61.1238 70.6775 61.1571 70.6775C62.7326 70.6775 64.0697 69.499 64.2579 67.924C64.4719 66.1387 63.1381 64.5421 61.3429 64.4341Z"
        fill="#1A59F3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.2964 71.7854C56.3441 71.7319 53.8867 71.6638 51.9249 71.6084C51.84 71.6061 51.7517 71.6033 51.6691 71.6014C51.6419 71.6005 51.6133 71.5995 51.5865 71.5991C51.5551 71.5981 51.5241 71.5977 51.4936 71.5977C49.8289 71.5977 48.4322 72.8714 48.2872 74.5393C48.2164 75.3536 48.4552 76.1181 48.9006 76.7236C49.147 76.6851 49.3986 76.6616 49.6553 76.6611C49.8383 76.6611 50.0242 76.6719 50.2067 76.6926C50.3137 76.7052 50.4292 76.7179 50.54 76.7306C52.2797 76.9291 54.4494 77.175 56.1756 77.3703C56.8622 77.4486 57.5174 77.6805 58.0993 78.0334H58.2124C59.8325 78.032 61.1823 76.7925 61.3226 75.179C61.4104 74.1728 61.0119 73.2426 60.3276 72.6109C59.7931 72.1181 59.0844 71.807 58.2964 71.7854Z"
        fill="#1A59F3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M57.4604 79.9807C57.0474 79.6179 56.5236 79.372 55.9332 79.3053C55.1269 79.2138 54.2211 79.111 53.3083 79.0073C52.158 78.8768 50.9969 78.745 50.0024 78.6319C49.9893 78.6305 49.9757 78.6286 49.963 78.6272C49.8752 78.6168 49.7889 78.6145 49.7025 78.6126C49.6795 78.6117 49.656 78.6084 49.6331 78.6084C49.1722 78.6084 48.7315 78.7206 48.3382 78.9196C47.5511 79.318 46.9575 80.0685 46.796 80.9986C46.5604 82.3554 47.3132 83.6395 48.5114 84.1468C48.7845 84.2623 49.0802 84.3378 49.3932 84.3646L55.3827 84.8715C55.4629 84.878 55.5418 84.8813 55.6206 84.8813C56.968 84.8813 58.1399 83.9126 58.3741 82.5629C58.5482 81.5623 58.1667 80.6007 57.4604 79.9807Z"
        fill="#1A59F3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.6612 66.0537L38.276 63.7563C39.3451 63.2908 40.2481 62.6558 41.0178 61.9246C44.9507 58.1884 45.298 51.8624 45.298 51.8624C47.0218 54.3071 49.2022 54.9853 51.0917 54.9853C53.4453 54.9853 55.347 53.9326 55.347 53.9326L46.2328 42.6342L46.1981 42.5911L34.2267 45.8317L25.5504 61.9434L24.5287 62.4878L23.5079 63.0327L14.4717 67.8507V69.7364C14.4717 76.0403 16.7413 81.9677 20.6531 86.5788L34.0226 79.6399L37.6612 66.0537Z"
        fill="#1A59F3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M87.6653 25.3449V69.7392C87.6653 80.1412 81.4947 89.5252 71.9441 93.6463L51.0697 102.654L44.3082 99.7371L32.2096 94.5159L30.1953 93.6463C26.4327 92.0224 23.1982 89.5796 20.6554 86.5816C16.7436 81.9706 14.474 76.0431 14.474 69.7392V22.2868L51.0697 6.81389L82.8149 20.2369L87.6653 22.2868V25.3449ZM83.6376 13.955V1.46271H76.7138V11.0326L51.0697 0.189453L24.6765 11.3489L0.620605 21.2971L2.59363 26.0677L8.37283 23.6779V69.7392C8.37283 70.1916 8.38503 70.6417 8.4038 71.0904C8.68821 77.8795 11.1057 84.266 15.1306 89.4496C18.3876 93.6453 22.6951 97.0545 27.7778 99.2481L30.6008 100.466L51.0697 109.3L74.362 99.2481C86.1391 94.1658 93.7665 82.5666 93.7665 69.7392V23.7305L99.4181 26.0677L101.391 21.2971L83.6376 13.955Z"
        fill="#1A59F3"
      />
    </svg>
  );
  return (
    <Modal
      size="ms"
      show={Loginmodalshow}
      onHide={() => setLoginmodalshow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body style={{ padding: "0" }}>
        <div className="main_login_div">
          <div className="right_div">
            <img src={logo} alt="" />
            <div className="header_text">
              <h4 style={{ fontSize: "20px", letterSpacing: "1.6px" }}>
                We fix all your{" "}
              </h4>{" "}
              <h1 style={{ fontSize: "30px" }}>
                Handy <span className="h_text">Works</span>
              </h1>
            </div>
            {/* <img src={Logo} alt="" /> */}
          </div>
          <div className="left_div">
            <Modal.Header
              closeButton
              onClick={() => setLoginmodalshow(false)}
              style={{
                display: "grid",
                placeItems: "end",
                width: "100% ",
                justifyContent: "end",
                border: "0",
              }}></Modal.Header>
            <div className="main_model_div">
              <div
                className="sub_model_div"
                style={{
                  paddingTop: "1rem",
                }}>
                <div className="model_title">
                  <span>{bluehamer}</span>
                  <h1>Login</h1>
                  <p>Please login to use service</p>

                  <button
                    className="btn1"
                    style={{
                      display: "grid",
                      placeItems: "end",
                      width: "100% ",
                      justifyContent: "end",
                      border: "0",
                    }}
                    // onClick={() => {
                    //   setLoginmodalshow(false);
                    //   navigate("/Login");
                    // }}>
                    onClick={() => {
                      handleLoginClick();
                    }}>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Loginmodal;
