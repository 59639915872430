import { useContext } from "react";
import { GlobalContext } from "../../../GlobalContext";
import { useFormik } from "formik";
import { ModeoneSchemas } from "../../../schemas";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ConecctProModal1 = () => {
  const initialValues = {
    FullName: "",
    LastName: "",
    Address: "",
    city: "",
    state: "",
  };
  const { FirstModalShow, setFirstModalShow, setSecondModalShow } =
    useContext(GlobalContext);

  const { values, handleBlur, handleChange, touched, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ModeoneSchemas,
      onSubmit: (values) => {
        // console.log("Values.....!", values);
        setFirstModalShow(false);
        setSecondModalShow(true);
      },
    });

  return (
    <Modal
      size="md"
      show={FirstModalShow}
      onHide={() => setFirstModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header
        closeButton
        onClick={() => setFirstModalShow(false)}
        style={{
          display: "grid",
          placeItems: "end",
          width: "100% ",
          justifyContent: "end",
          border: "0",
        }}></Modal.Header>
      <Modal.Body>
        <div className="main_model_div">
          <div className="sub_model_div">
            <div className="model_title">
              <h1>Finish ceating your account</h1>
              <p>
                Your address allows us to locate the best <br />
                Pros in your area.
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="name_box">
                <div className="fname">
                  <label htmlFor="text">First Name</label>
                  <div className="input_div">
                    <input
                      type="text"
                      placeholder="First Name"
                      name="FullName"
                      value={values.FullName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.FullName && touched.FullName ? (
                    <p className="errors_msg_p">{errors.FullName} </p>
                  ) : null}
                </div>
                <div className="fname">
                  <label htmlFor="text">Last Name</label>
                  <div className="input_div">
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="LastName"
                      value={values.LastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.LastName && touched.LastName ? (
                    <p className="errors_msg_p">{errors.LastName} </p>
                  ) : null}
                </div>
              </div>
              <div className="Address_box">
                <label htmlFor="Address">Address line 1</label>
                <div className="Address_input">
                  <input
                    type="text"
                    name="Address"
                    value={values.Address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id=""
                    placeholder="Address line 1"
                  />
                </div>
                {errors.Address && touched.Address ? (
                  <p className="errors_msg_p">{errors.Address} </p>
                ) : null}
              </div>
              <div className="Address_box">
                <label htmlFor="Address">Address line 1</label>
                <div className="Address_input">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Address line 1"
                  />
                </div>
              </div>
              <div className="name_box">
                <div className="fname">
                  <label htmlFor="text">City</label>
                  <div className="input_div">
                    <select
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}>
                      <option value="" disabled selected hidden>
                        City
                      </option>
                      <option value="City 1">City 1</option>
                      <option value="City 2">City 2</option>
                      <option value="City 3">City 3</option>
                    </select>
                  </div>
                  {errors.city && touched.city ? (
                    <p className="errors_msg_p">{errors.city} </p>
                  ) : null}
                </div>
                <div className="fname">
                  <label htmlFor="text">State</label>
                  <div className="input_div">
                    <select
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}>
                      <option value="" disabled selected hidden>
                        State
                      </option>
                      <option value="State 1">State 1</option>
                      <option value="State 2">State 2</option>
                      <option value="State 3">State 3</option>
                    </select>
                  </div>
                  {errors.state && touched.state ? (
                    <p className="errors_msg_p">{errors.state} </p>
                  ) : null}
                </div>
              </div>
              <Button
                type="submit"
                className="model_btn"
                onClick={() => setFirstModalShow(false)}
                // s={openSecondModal}
              >
                Continue
              </Button>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConecctProModal1;
