import sec5_1 from "../../../../User/assets/sec5_1.png";
import sec5_2 from "../../../../User/assets/sec5_2.png";
import sec5_3 from "../../../../User/assets/sec5_3.png";
import sec5_4 from "../../../../User/assets/sec5_4.png";

const sec5data = [
  {
    id: 1,
    pic: sec5_1,
    title: "1. Book Online",
    pre: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a blandit lorem...",
  },
  {
    id: 2,
    pic: sec5_2,
    title: "2. Confirmation",
    pre: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a blandit lorem...",
  },
  {
    id: 3,
    pic: sec5_3,
    title: "3. Work Status",
    pre: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a blandit lorem...",
  },
  {
    id: 4,
    pic: sec5_4,
    title: "4. Completion",
    pre: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a blandit lorem...",
  },
];

export default sec5data;
