import { useState, useEffect } from "react";
import "./SetAvalability.css";
// import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Modal from "react-bootstrap/Modal";
import close from "../../../../User/assets/svg/Close.svg";
import { toast } from "react-hot-toast";
import axios from "../../../../Common/Api/index";
import { Backdrop, CircularProgress } from "@mui/material";
import Calendar from "react-calendar";

const SetAvailability = ({ show, onHide, setAvail }) => {
  const [isToggled, setIsToggled] = useState(0);

  const [startDate, setStartDate] = useState(new Date());

  const [endDate, setEndDate] = useState(null);
  const [value, setValue] = useState();
  const [loading, setloading] = useState(false);
  const [starttimeshow, setstarttimeshow] = useState([]);

  const [sectectstarttime, setsectectstarttime] = useState("");

  const [sectectendtime, setsectectendtime] = useState("");

  const userdata = JSON.parse(localStorage.getItem("login"));

  const newstartDate = new Date(startDate);
  const formattedDate = newstartDate.toISOString().split("T")[0];

  const [endTimes, setEndTimes] = useState([]);

  const [selectedStartIndex, setSelectedStartIndex] = useState(null);
  // ! set-availability  api start here

  const setavailabe = async (isavailable) => {
    setloading(true);
    try {
      const response = await axios.post(
        "set-availability",
        {
          date: formattedDate,
          start_time: sectectstarttime,
          end_time: sectectendtime,
          full_month: isToggled,
          is_available: isavailable,
        },
        {
          headers: {
            "content-Type": "application/json",
            Authorization: `Bearer ${userdata.data.data.api_token}`,
          },
        }
      );
      setloading(false);

      if ((response.data.status = "success")) {
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };
  // ! set-availability  api End  here
  // ! getTimePickerData  api start  here
  const timepicker = async () => {
    setloading(true);
    try {
      const Response = await axios.get("getTimePickerData", {
        headers: {
          "content-Type": "application/json",
          Authorization: `Bearer ${userdata.data.data.api_token}`,
        },
      });
      setloading(false);
      // console.log("TimePicker show success ====>", Response.data.data);
      setstarttimeshow(Response.data.data.time);
    } catch (error) {
      setloading(false);
    }
  };
  useEffect(() => {
    timepicker();
  }, []);
  // ! getTimePickerData  api End  here

  const handleSelectChange = (event) => {
    setsectectstarttime(event.target.value);
  };
  const handleSelectendChange = (event) => {
    setsectectendtime(event.target.value);
  };

  const toggleButton = () => {
    setIsToggled((prevIsToggled) => (prevIsToggled ? 0 : 1));
  };

  // Function to handle date selection
  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Function to check if a date is in the current month
  const isDateInCurrentMonth = (date) => {
    return (
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  // Function to filter out past time slots unless tomorrow is selected
  const filterFutureTimeSlots = (timeSlots) => {
    const currentTime = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(currentTime.getDate() + 1);

    return timeSlots.filter((slot) => {
      const [time, period] = slot.time.split(" ");
      let [hours, minutes] = time.split(":").map(Number);

      if (period === "PM" && hours !== 12) {
        hours += 12;
      } else if (period === "AM" && hours === 12) {
        hours = 0;
      }

      const slotTime = new Date();
      slotTime.setHours(hours, minutes, 0, 0);

      if (startDate.toDateString() === tomorrow.toDateString()) {
        return true;
      }

      return slotTime > currentTime;
    });
  };
  const futureTimeSlots = filterFutureTimeSlots(starttimeshow);
  // console.log("🚀 ~ SetAvailability ~ futureTimeSlots:", futureTimeSlots);

  return (
    <>
      <section>
        <div className="model-book">
          <Modal show={show} onHide={onHide} size="md" centered>
            <div className="Close-btnn-modall-divv">
              <div className="Address-close-popup-divv">
                <img
                  className="close-Address-main-span"
                  onClick={() => onHide()}
                  src={close}
                />
              </div>
            </div>
            <div className="Address-main-payment-title">
              <span className="Address-main-span-divv">Set Availability</span>
            </div>
            <div className="Select-div">
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                inline
                minDate={today}
                filterDate={(date) =>
                  isToggled ? !isDateInCurrentMonth(date) : true
                }
                dateFormat="yyyy-MM-dd"
              />

              <div className="Availability-line-ho mt-3"></div>
              <div className="Whole-Month-and-togle-divv">
                <div className="Whole-unavail-divv">
                  <span className="Whole-unavail-spaan-tagg">
                    Current Whole Month Unavailable
                  </span>
                </div>
                <div className="Scroll-Toggle-divv">
                  <button
                    className={`round-switch-button${
                      isToggled ? "active" : ""
                    }`}
                    onClick={toggleButton}>
                    <div className={`slider${isToggled ? "active" : ""}`}></div>
                  </button>
                </div>
              </div>
              <div className="Availability-line-ho"></div>
              <div className="Select-Time-Main-divv mt-4">
                <div className="Select-Time-sppan-mainn">
                  <span className="Select-Time-Spannn-tagg">Select Time</span>
                </div>

                <div className="Drop-Down-Eight-and-Nine mt-2">
                  <div className="Dropp_div">
                    <select name="time" onChange={handleSelectChange}>
                      <option value="" disabled selected hidden>
                        Start Time
                      </option>
                      {futureTimeSlots.map((item, index) => {
                        return (
                          <option class="dropbtn" key={index} value={item.id}>
                            {item.time}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="Too-Time-Divv mt-3">
                    <span className="Tooo-Time-spann">to</span>
                  </div>
                  <div className="Dropp_div">
                    <select name="time" onChange={handleSelectendChange}>
                      <option value="" disabled selected hidden>
                        End Time
                      </option>
                      {futureTimeSlots?.map((item, index) => {
                        if (index !== 0) {
                          return (
                            <option
                              className="dropbtn"
                              key={index}
                              value={item.id}>
                              {item.time}
                            </option>
                          );
                        }
                        return null;
                      })}
                    </select>
                  </div>
                </div>
                <div className="Unavail_and_Avail_Main_Divv mt-4 mb-4">
                  <div className="UnAvail_btnn_divv">
                    <button
                      className="Unavil_btnn_main"
                      onClick={() => {
                        setavailabe(2);
                        onHide();
                      }}>
                      Unavailable
                    </button>
                  </div>
                  <div className="UnAvail_btnn_divv">
                    <button
                      className="avil_btnn_main"
                      onClick={() => {
                        setavailabe(1);
                        onHide();
                      }}>
                      Available
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        {!loading && <div></div>}
        {loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </section>
    </>
  );
};

export default SetAvailability;
