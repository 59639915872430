import React from "react";
import Navbar from "../../../components/UI/Navbar";
import Footer from "../../../components/UI/Footer";
import "./Help.css";

const Help = () => {
  return (
    <>
      <Navbar />
      <div className="main_div_trems">
        <div className="main_trems_div">
          <div className="inner_div_trems">
            <div className="heading_text">
              <h1>Help</h1>
            </div>
            <div className="inner_text_main_div">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod,
              suscipit atque soluta quae eveniet nemo omnis sequi ipsa nam
              quibusdam necessitatibus quis labore a voluptatum repellendus
              explicabo, temporibus quaerat qui. Error ad hic molestias eum
              dolores ratione provident magni aut amet. Tenetur debitis magni
              quaerat officia corrupti corporis labore error delectus aliquam
              ratione, quos, velit adipisci, neque repellendus autem eligendi
              illum laudantium itaque nihil iure. Commodi, inventore odio!
              Pariatur laboriosam eos consequatur ex repellendus fugit doloribus
              velit possimus? Esse, ratione optio ipsam repudiandae laborum
              incidunt quaerat rem hic quidem ipsa nesciunt ipsum magni fugiat
              voluptas rerum dignissimos impedit excepturi. Ut magni quos nam
              voluptas aliquid. Sunt, tempora ea! Aliquam quibusdam perspiciatis
              saepe earum explicabo! Illo laboriosam facilis explicabo placeat
              ipsum ipsa adipisci magnam, corporis incidunt perferendis, eos aut
              itaque voluptatibus odio consequuntur consequatur quibusdam
              officia aliquid nemo! Nobis, magnam eveniet! Eveniet voluptas,
              reprehenderit architecto modi possimus, provident consequatur
              enim, ipsam esse id officia nisi assumenda incidunt illo illum.
              Est, voluptate. Consequuntur aliquam voluptatibus nostrum deserunt
              a officiis nemo magnam neque dolor delectus facere quidem
              accusamus ipsum, quod nulla vitae officia saepe pariatur placeat
              impedit? Voluptas dolores laborum unde itaque expedita ea quae
              molestias recusandae neque, labore praesentium laboriosam ex esse.
              Placeat delectus labore obcaecati eius dicta magni, molestias
              aliquam recusandae dolores nostrum vero itaque deserunt voluptate
              laborum ex sunt porro sequi illum doloremque accusamus sed
              deleniti. Rem consequuntur architecto doloribus quas aspernatur
              dolore ex! Autem quasi eligendi vitae ipsum id quia labore. Nulla
              eaque culpa iusto ut ab maxime quidem voluptatibus illo. A quaerat
              saepe, modi maiores dolore sequi, voluptas et reprehenderit minima
              ullam molestiae ipsam id eos aliquid, rem cumque perspiciatis
              voluptates explicabo at sit facere optio. Officiis dolor neque ad
              sed, eius ipsa ea magni saepe, cumque numquam veritatis dolorem
              molestias. Soluta obcaecati recusandae rerum minima quia maiores
              consequatur iusto nisi iste deserunt error itaque fugit, delectus
              aperiam neque omnis non totam pariatur natus corrupti! Natus
              soluta velit sint est sed error provident nihil voluptas,
              necessitatibus dolore, ullam officia accusantium officiis vel, eum
              dolorum veritatis eligendi quis ratione rerum maiores quae quasi!
              Praesentium laboriosam mollitia adipisci repellat? In quis, nemo
              vitae deserunt fugit, quas, delectus dolores necessitatibus eos
              nam corrupti consequuntur quisquam dolor culpa perspiciatis quae?
              Nemo nostrum eius similique rem nam labore blanditiis, adipisci
              maiores fugit ut ipsam incidunt assumenda, possimus itaque amet
              doloremque iure reiciendis quod voluptate molestias est neque
              porro voluptas nobis! Libero, magni distinctio.
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Help;
