import React from "react";
import { Outlet } from "react-router-dom";
import ProfileSdebar from "../../components/UI/ProfileSideBar2/ProfileSdebar2";
import Navbar2 from "../../components/UI/Navbar_SP2/index";
import Footer from "../../../User/components/UI/Footer";

const Profile2 = () => {
  return (
    <div
      className="white"
      style={{ backgroundColor: "#fff", minHeight: "100vh", height: "100%" }}>
      {/* <Navbar2 /> */}
      <div style={{ marginTop: "101px" }}></div>
      <div className="d-flex justify-content-center gap-3 pt-4 pe-3 ps-3 pb-4">
        <ProfileSdebar />
        <div className="">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile2;
