import React from "react";
import "./payment.css";
import Navbar2 from "../UI/Nvabar2";
import Footer from "../UI/Footer";
import cross from "../../assets/svg/Paymnetfaild.svg";
const Paymnetfaild = () => {
  return (
    <>
      {/* <Navbar2 /> */}
      <div className="main_div">
        <div className="sub_main_div">
          <div className="_img__box">
            <img src={cross} alt="" />
          </div>
          <div className="text">
            <h2>Payment Failed!</h2>
            <p>Your payment has been failed please check payment method.</p>
          </div>
          <button className="sbtn_1">Ok</button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Paymnetfaild;
