import React from "react";
import "./Landing.css";
import Navbar from "../../components/UI/Navbar";
import Navbar2 from "../../components/UI/Nvabar2";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import Section6 from "./section6";
import Section7 from "./section7";
import Section8 from "./section8";
import Footer from "../../components/UI/Footer";
import Scroltotop from "../../components/ScrollTop.js";
import { json } from "react-router-dom";

const Landingpage = () => {
  const userData = JSON.parse(localStorage.getItem("login"));
  // console.log("login data ====>", userData);
  // console.log(userData?.data?.status === "success");
  return (
    <div className="main_landing_divv">
      <Scroltotop />
      {/* {userData ? <Navbar2 /> : <Navbar />} */}
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Footer />
    </div>
  );
};

export default Landingpage;
