import { useContext } from "react";
import { GlobalContext } from "../../../GlobalContext";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { ModeltwoSchemas } from "../../../schemas";
import { useNavigate } from "react-router-dom";

const ConectProModel4 = () => {
  const navigate = useNavigate();
  const initialValues = {
    Number: "",
  };

  const { values, handleBlur, handleChange, touched, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ModeltwoSchemas,
      onSubmit: (values, { setSubmitting }) => {
        // Check if there are no validation errors
        if (Object.keys(errors).length === 0) {
          // console.log("Values.....$$$", values);
          setFourModalShow(false);
          // Navigate to "Successfully" screen
          navigate("/Successfully");
        }
        setSubmitting(false);
      },
    });
  const { setThirdModalShow, FourModalShow, setFourModalShow } =
    useContext(GlobalContext);

  return (
    <Modal
      show={FourModalShow}
      onHide={() => FourModalShow(false)}
      size="md"
      aria-labelledby="contained-model-title-vcenter"
      centered>
      <Modal.Header
        style={{
          display: "grid",
          placeItems: "end",
          width: "100% ",
          justifyContent: "end",
          border: "0",
        }}
        closeButton
        onClick={() => setFourModalShow(false)}></Modal.Header>
      <Modal.Body>
        <div className="four_main_div">
          <div className="model_title">
            <h1>Please review your information</h1>
            <p>
              We will share this information with your <br /> service provider.
            </p>
          </div>
          <div className="fourth_text_box">
            <h2>Service Request Information</h2>
            <p>
              4517 Washington Ave. Manchester, <br /> Kentucky 39495
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mobile_number_input">
              <label htmlFor="text">
                Please provide a contact Phone number
              </label>
              <div className="number_input_div">
                <input
                  name="Number"
                  type="number"
                  value={values.Number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id=""
                  placeholder="Phone number"
                />
              </div>
              {errors.Number && touched.Number ? (
                <p className="errors_msg_p">{errors.Number} </p>
              ) : null}
              <div className="disc_text_model">
                <p>
                  Bu clicking Next, Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an
                  unknown printer took a galley of type and scrambled it to make
                  a type specimen book. It has survived not only five centuries,
                  but also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </p>
              </div>
              <div className="div_btn_div">
                <Button
                  className="btn_model3"
                  onClick={() => {
                    setThirdModalShow(true);
                    setFourModalShow(false);
                  }}>
                  Back
                </Button>
                <Button
                  type="submit"
                  className="btn_model3 "
                  onClick={handleSubmit}
                  //   onClick={() => {
                  //     setFourModalShow(false);
                  //     navigate("/Successfully");
                  //   }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConectProModel4;
