import { useContext, useState } from "react";
import { GlobalContext } from "../../../GlobalContext";
import { Button, Modal } from "react-bootstrap";
import newimg from "../../assets/DD_1.png";

const ConectproModel2 = () => {
  const { SecondModalShow, setSecondModalShow, setThirdModalShow } =
    useContext(GlobalContext);
  return (
    <Modal
      show={SecondModalShow}
      onHide={() => setSecondModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header
        closeButton
        onClick={() => setSecondModalShow(false)}
        style={{
          display: "grid",
          placeItems: "end",
          width: "100% ",
          justifyContent: "end",
          border: "0",
        }}></Modal.Header>
      <Modal.Body>
        <div className="modeltwo_main">
          <div className="heding_second_model">
            <div className="sub_model_2nd_box">
              <img src={newimg} alt="" />
              <div className="second_model_head_text">
                <h3>Brooklyn Simmons</h3>
                <span>$ 2300</span>
              </div>
            </div>
          </div>
          <div className="modeltwo_seconddiv">
            <div className="sub_secnd_div">
              <p>
                Is there an ideal time frame to start this project? This will
                help your pro schedule the work you later.
              </p>
              <div className="btn_model2_div">
                <div className="model_btn2">
                  <label htmlFor="text">Timing is flexible</label>
                </div>
                <div className="model_btn2">
                  <label htmlFor="text">Within a week</label>
                </div>
                <div className="model_btn2">
                  <label htmlFor="text">1 -2 weeks</label>
                </div>
                <div className="model_btn3">
                  <label htmlFor="text">This is an urgent request</label>
                </div>
              </div>
            </div>
          </div>

          <Button
            type="submit"
            className="model_btn"
            onClick={() => {
              setSecondModalShow(false);
              setThirdModalShow(true);
            }}>
            Next
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConectproModel2;
