import sec4_1 from "../../../../User/assets/sec4_1.png";
import sec4_2 from "../../../../User/assets/sec4_2.png";
import sec4_3 from "../../../../User/assets/sec4_3.png";

const start = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_35_8969)">
      <path
        d="M7.50001 10.625L3.82626 12.8687L4.82501 8.68125L1.55626 5.88125L5.84688 5.5375L7.50001 1.5625L9.15313 5.5375L13.4444 5.88125L10.175 8.68125L11.1738 12.8687L7.50001 10.625Z"
        fill="#F2B006"
      />
    </g>
    <defs>
      <clipPath id="clip0_35_8969">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const sec4_data = [
  {
    id: 1,
    Imgs: sec4_1,
    ico: start,
    tittle: "Plumbing Work",
    rateing: "3.2",
    totalrating: "(233)",
  },
  {
    id: 1,
    Imgs: sec4_2,
    tittle: "Carpentry",
    ico: start,
    rateing: "5.0",
    totalrating: "(233)",
  },
  {
    id: 1,
    Imgs: sec4_3,
    tittle: "Electric Work",
    ico: start,
    rateing: "4.2",
    totalrating: "(233)",
  },
];

export default sec4_data;
