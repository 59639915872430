import React from "react";
import "./sec4.css";
import sec4data from "./sec4_data.js";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules
import { EffectCards } from "swiper/modules";

const Section4 = () => {
  return (
    <div className="main_sec4_div">
      <div className="sub_sec4_div">
        <h2>Our Best Projects</h2>

        <div className="card_box">
          {sec4data.map((item, index) => {
            return (
              <div className="card_div" key={index}>
                <img src={item.Imgs} alt="" />
                <div className="card_data_sec4">
                  <div className="text_sec4_card">
                    <h4>{item.tittle}</h4>
                    <div className="raiting">
                      <p>
                        {item.ico}
                        {item.rateing}
                      </p>
                      <p>{item.totalrating}</p>
                    </div>
                  </div>
                  <button className="btn4">Book Now</button>
                </div>
              </div>
            );
          })}
        </div>

        <div className="ccard_box">
          <Swiper effect={"cards"} modules={[EffectCards]}>
            {sec4data.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="main_swiper_box">
                    <img src={item.Imgs} alt="" />

                    <div className="card_data_sec4">
                      <div className="text_sec4_card">
                        <h4>{item.tittle}</h4>
                        <div className="raiting">
                          <p>
                            {item.ico}
                            {item.rateing}
                          </p>
                          <p>{item.totalrating}</p>
                        </div>
                      </div>
                      <button className="btn4">Book Now</button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Section4;
