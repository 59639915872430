import React from "react";
import Navbar from "../../../components/UI/Navbar_SP";
import Footer from "../../../components/UI/Footer";
import Accordion from "react-bootstrap/Accordion";
import { BiRightArrowAlt } from "react-icons/bi";

const Index = () => {
  const FAQdata = [
    {
      id: 1,
      No: 1,
      question: "How do I get started?",
      answer: "You can contact us at EMAIL",
    },
    {
      id: 2,
      No: 2,
      question: "How do I get started?",
      answer: "You can contact us at EMAIL",
    },
    {
      id: 3,
      No: 3,
      question: "How do I get started?",
      answer: "You can contact us at EMAIL",
    },
    {
      id: 4,
      No: 4,
      question: "How do I get started?",
      answer: "You can contact us at EMAIL",
    },
    {
      id: 5,
      No: 5,
      question: "How do I get started?",
      answer: "You can contact us at EMAIL",
    },
  ];
  return (
    <>
      <Navbar />
      <div className="MAIN_FAQ">
        <div className="sub_main_div_FAQ">
          <h1>FAQ's</h1>
          <div className="accordion_div_FAQ">
            {FAQdata.map((Item, Index) => {
              return (
                <Accordion style={{ width: "100%" }}>
                  <Accordion.Item style={{ width: "100%" }} eventKey="0">
                    <Accordion.Header>
                      <div className="main_Qus_div">
                        <div className="Qusetion_No">
                          <h3>{Item.No}</h3>
                        </div>
                        <div className="Qusetion_text">{Item.question}</div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <BiRightArrowAlt /> {Item.answer}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Index;
