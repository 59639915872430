import React from "react";
import "./Contact.css";
import Navbar2 from "../../components/UI/Nvabar2";
import Footer from "../../components/UI/Footer";
import { useFormik } from "formik";
import { ContactusSchemas } from "../../../schemas";
import map_img1 from "../../assets/contactus_map.png";
import GoogleMaps from "../Map/GoogleMaps";
import axios from "../../../Common/Api/index";

const Contactus = () => {
  const initialValues = {
    FullName: "",
    Email: "",
    MobileNumber: "",
    Message: "",
  };
  const address = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_27_8531)">
        <path
          d="M13 19H19V9.978L12 4.534L5 9.978V19H11V13H13V19ZM21 20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V9.49C2.99989 9.33761 3.03462 9.18721 3.10152 9.0503C3.16841 8.91338 3.26572 8.79356 3.386 8.7L11.386 2.478C11.5615 2.34144 11.7776 2.2673 12 2.2673C12.2224 2.2673 12.4385 2.34144 12.614 2.478L20.614 8.7C20.7343 8.79356 20.8316 8.91338 20.8985 9.0503C20.9654 9.18721 21.0001 9.33761 21 9.49V20Z"
          fill="#00AD5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_27_8531">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const Email = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_27_8533)">
        <path
          d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 7.238L12.072 14.338L4 7.216V19H20V7.238ZM4.511 5L12.061 11.662L19.502 5H4.511Z"
          fill="#00AD5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_27_8533">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const phone = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_27_8535)">
        <path
          d="M9.366 10.682C10.3043 12.3305 11.6695 13.6957 13.318 14.634L14.202 13.396C14.3442 13.1969 14.5543 13.0569 14.7928 13.0023C15.0313 12.9478 15.2814 12.9825 15.496 13.1C16.9103 13.8729 18.4722 14.3378 20.079 14.464C20.3298 14.4839 20.5638 14.5975 20.7345 14.7823C20.9052 14.9671 21 15.2094 21 15.461V19.923C21.0001 20.1706 20.9083 20.4094 20.7424 20.5932C20.5765 20.777 20.3483 20.8927 20.102 20.918C19.572 20.973 19.038 21 18.5 21C9.94 21 3 14.06 3 5.5C3 4.962 3.027 4.428 3.082 3.898C3.10725 3.6517 3.22298 3.42352 3.40679 3.25763C3.5906 3.09175 3.82941 2.99995 4.077 3H8.539C8.79056 2.99997 9.0329 3.09475 9.21768 3.26545C9.40247 3.43615 9.51613 3.67022 9.536 3.921C9.66222 5.52779 10.1271 7.08968 10.9 8.504C11.0175 8.71856 11.0522 8.96874 10.9977 9.2072C10.9431 9.44565 10.8031 9.65584 10.604 9.798L9.366 10.682ZM6.844 10.025L8.744 8.668C8.20478 7.50409 7.83535 6.26884 7.647 5H5.01C5.004 5.166 5.001 5.333 5.001 5.5C5 12.956 11.044 19 18.5 19C18.667 19 18.834 18.997 19 18.99V16.353C17.7312 16.1646 16.4959 15.7952 15.332 15.256L13.975 17.156C13.4287 16.9437 12.898 16.6931 12.387 16.406L12.329 16.373C10.3676 15.2567 8.74328 13.6324 7.627 11.671L7.594 11.613C7.30691 11.102 7.05628 10.5713 6.844 10.025Z"
          fill="#00AD5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_27_8535">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const onSubmit = async () => {
    try {
      const Response = await axios.post("contactUs", {
        name: values.FullName,
        email: values.Email,
        phone: values.MobileNumber,
        message: values.Message,
      });
    } catch (error) {}
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ContactusSchemas,
    onSubmit,
    // onSubmit: (values, { resetForm }) => {
    //   console.log("Values.....!", values);
    //   resetForm();
    // },
  });

  return (
    <>
      {/* <Navbar2 /> */}
      <div className="main_contact_section">
        <div className="sub_contact_div">
          <div className="comtact_info_div">
            <div className="contact_form_div">
              <h1>Get in Touch</h1>
              <form className="contactUS_form" onSubmit={handleSubmit}>
                <div className="Name_box">
                  <div className="Fname_box">
                    <label htmlFor="text">Full Name</label>
                    <div className="Fname_input_box">
                      <input
                        type="text"
                        placeholder="Full Name"
                        name="FullName"
                        value={values.FullName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.FullName && touched.FullName ? (
                      <p className="errors_msg_p">{errors.FullName} </p>
                    ) : null}
                  </div>
                  <div className="Email_box">
                    <label htmlFor="Email">Email</label>
                    <div className="Email_input_box">
                      <input
                        type="Email"
                        placeholder="Email"
                        name="Email"
                        value={values.Email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.Email && touched.Email ? (
                      <p className="errors_msg_p">{errors.Email} </p>
                    ) : null}
                  </div>
                </div>
                <div className="PhoneNumber_div">
                  <label htmlFor="number">Phone Number</label>
                  <div className="Phonenumber_input_box">
                    <input
                      type="number"
                      name="MobileNumber"
                      value={values.MobileNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Phone Number"
                    />
                  </div>
                  {errors.MobileNumber && touched.MobileNumber ? (
                    <p className="errors_msg_p">{errors.MobileNumber}</p>
                  ) : // <p className="mastu-to-eroor mt-2">{errors.MobileNumber}</p>
                  null}
                </div>
                <div className="Message_box">
                  <label htmlFor="Message">Message</label>
                  <div className="Message_input_box">
                    <textarea
                      id=""
                      cols="30"
                      rows="10"
                      name="Message"
                      value={values.Message}
                      onChange={handleChange}
                      onBlur={handleBlur}></textarea>
                  </div>
                  {errors.Message && touched.Message ? (
                    <p className="errors_msg_p">{errors.Message} </p>
                  ) : // <p className="mastu-to-eroor mt-2">{errors.Message} </p>
                  null}
                </div>
                <button type="submit" className="sub_btn">
                  Submit
                </button>
              </form>
            </div>
            <div className="contact_details_box">
              <h1>Contact information</h1>
              <p>
                We love to hear from you on our customer service, merchandise,
                website or any topics you want to share with us. Your comments
                and suggestions will be appreciated. Please complete the form
                below.
              </p>
              <div className="contact_info_details">
                <div className="adress_box">
                  <span>{address}</span>
                  <p>2715 Ash Dr. San Jose, South Dakota 83475</p>
                </div>
                <div className="Email_boxx">
                  <span>{Email}</span>
                  <a href="mailto:dolores.chambers@example.com">
                    dolores.chambers@example.com
                  </a>
                </div>
                <div className="PHone_box">
                  <span>{phone}</span>
                  <div className="call_box">
                    <a href="tel:+219 555-0114">(219) 555-0114 ,</a>
                    <a href="tel:+480 555-0192"> (480) 555-0192</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="map_box">
            <div className="map_sec_divv">
              {/* <img src={map_img1} alt="" /> */}
              <GoogleMaps />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contactus;
