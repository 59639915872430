import React, {useEffect, useState} from "react";
import mapStyle from "./mapStyle";
import {withGoogleMap, GoogleMap} from "react-google-maps";
const containerStyle = {
  width: "100%",
  height: "300px",
};

// const markerOptions = {
//   icon: {
//     url: "https://path-to-house-logo.png", // Replace with the URL of your house logo image
//     scaledSize: new window.google.maps.Size(50, 50),
//   },
// };
const apiKey = "AIzaSyB86XJujeIEHyCA9YZE15d3rKYfKNVfP0A";

const Map = withGoogleMap((props) => (
  <GoogleMap
    defaultZoom={11}
    defaultCenter={{lat: 40.76258, lng: -73.56411}}
    options={{
      styles: mapStyle,
    }}
  >
    <CustomMarker />
    {/* Your markers or other map components can go here */}
  </GoogleMap>
));
function CustomMarker() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    ></div>
  );
}

const GoogleMaps = ({MyVar}) => {
  //   const [map, setMap] = useState(null);
  //   const center = {
  //     lat: -34.397,
  //     lng: 150.644,
  //   };

  return (
    <>
      <div>
        <Map
          containerElement={<div style={containerStyle} />}
          mapElement={<div style={{height: "100%"}} />}
        />
      </div>
    </>
  );
};

export default GoogleMaps;
