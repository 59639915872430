import React, { useState } from "react";
import "../PaymentModal/Payment.css";
import Modal from "react-bootstrap/Modal";
import Greencard from "../../../assets/Greencard.png";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Calender from "../../../assets/svg/Calender.svg";
import Time from "../../../assets/svg/Time.svg";
import close from "../../../assets/svg/Close.svg";
import master from "../../../assets/svg/Master.svg";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

const initialValues = {
  CardName: "",
  CardNumber: "",
  Expiry: "",
  password: "",
  ConfirmPassword: "",
};
const Payment = ({ show, onHide }) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const { values, handleBlur, handleChange, touched, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      // validationSchema: RegisterSchemas,

      onSubmit: (values) => {},
    });
  return (
    <>
      <Modal show={show} onHide={onHide} size="md" centered>
        <div className="Close-btnn-modall-divv">
          <div className="Address-close-popup-divv">
            <img
              className="close-Address-main-span"
              onClick={() => onHide()}
              src={close}
            />
          </div>
        </div>
        <div className="Address-main-payment-title">
          <span className="Address-main-span-divv">Payment</span>
        </div>
        <div className="Payment-all-sec-main-divv">
          <div className="payent-sec-divv-pppx">
            <div className="Calender-and-date-span-divv">
              <div className="Calender-main-span-divv">
                <img src={Calender} alt="" />
              </div>
              <div className="Date-spann-divv">
                <div className="date-payment-divv">
                  <span className="date-edit-spann">Date</span>
                </div>
                <div className="written-span-payment-divv">
                  <span className="written-span-date-edit-spann">
                    22 May 2023
                  </span>
                </div>
                <div className="Edit-payment-divv">
                  <span className="Edit-date-edit-spann">Edit</span>
                </div>
              </div>
            </div>
            <div className="Calender-and-date-span-divv">
              <div className="Calender-main-span-divv">
                <img className="Img-greencard-divv" src={Time} alt="" />
              </div>
              <div className="Date-spann-divv">
                <div className="date-payment-divv">
                  <span className="date-edit-spann">Time</span>
                </div>
                <div className="written-span-payment-divv">
                  <span className="written-span-date-edit-spann">
                    10:30 to 11:00 AM
                  </span>
                </div>
                <div className="Edit-payment-divv">
                  <span className="Edit-date-edit-spann">Edit</span>
                </div>
              </div>
            </div>
          </div>
          <div className="Imgg-Greencard-number-divv">
            <div className="green-card-main-divv">
              <img className="Greencard-imgg" src={Greencard} alt="" />
            </div>
            <div className="Master-card-main-divv">
              <img src={master} alt="" />
            </div>
            <div className="number-of-card-divv-ppx">
              <div className="Number-Divv-spann-cardd">
                <span className="Number-spann-cardd">1234</span>
                <span className="Number-spann-cardd">55678</span>
                <span className="Number-spann-cardd">9012</span>
                <span className="Number-spann-cardd">4567</span>
              </div>
            </div>
            <div className="Jane-cooper-card-main-divv">
              <span className="Jane-cooper-span">Jane Cooper</span>
            </div>
            <div className="Expiry-card-main-divv">
              <span className="Expiry-divv-span">Expiry</span>
            </div>
            <div className="nine-twenty-main-divv">
              <span className="Nine-twenty-five-divv-span">9/ 2025</span>
            </div>
          </div>
          <div className="Radio-button-divv-ppx mt-3">
            <div className="Four-Card-radio-buttons">
              <div className="Two-Radio-btnn-divv-Main">
                <div className="Radio-btnn-divv-imgg">
                  <input
                    className="Radio-btnn-master"
                    type="radio"
                    value="option1"
                    checked={selectedOption === "option1"}
                    onChange={handleOptionChange}
                  />
                  <svg
                    width="33"
                    height="26"
                    viewBox="0 0 33 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect
                      x="0.5"
                      y="0.500061"
                      width="32"
                      height="25"
                      rx="3.5"
                      fill="white"
                      stroke="#C2C2C2"
                    />
                    <path
                      d="M14.2188 9L12.9641 16.8079H14.9695L16.2235 9H14.2188ZM20.268 12.1805C19.5672 11.8344 19.1376 11.6008 19.1376 11.2468C19.1461 10.925 19.5009 10.5953 20.293 10.5953C20.9446 10.5789 21.4235 10.7321 21.7868 10.8844L21.9688 10.9656L22.2414 9.33203C21.8453 9.17891 21.2172 9.0102 20.4414 9.0102C18.461 9.0102 17.0664 10.0399 17.0578 11.5126C17.0414 12.5993 18.0562 13.2024 18.8156 13.5641C19.5914 13.9352 19.8555 14.1758 19.8555 14.5056C19.8469 15.0118 19.2282 15.2454 18.6509 15.2454C17.8509 15.2454 17.4212 15.1251 16.7689 14.843L16.5048 14.7227L16.2243 16.4204C16.6954 16.6298 17.5618 16.8141 18.4611 16.8235C20.5658 16.8235 21.9353 15.8094 21.9525 14.2399C21.9594 13.379 21.4242 12.7195 20.268 12.1805ZM27.3828 9.02416H25.8312C25.3531 9.02416 24.9898 9.16169 24.7828 9.65232L21.8038 16.8079H23.9085L24.4882 15.2587H26.8429L27.1437 16.8141H29L27.3828 9.02416ZM25.0719 13.6952C25.1125 13.6992 25.8797 11.1663 25.8797 11.1663L26.4907 13.6952H25.0719ZM11.2883 9L9.32345 14.3047L9.10941 13.2586C8.7461 12.0515 7.60708 10.7398 6.33594 10.0883L8.13517 16.8001H10.2571L13.4102 9.00086H11.2883V9Z"
                      fill="#2394BC"
                    />
                    <path
                      d="M8.44846 10.0069C8.29534 9.41159 7.80701 9.01006 7.14381 9.0015H4.03205L4 9.14596C6.42739 9.74439 8.46482 11.5858 9.1258 13.3178L8.44846 10.0069Z"
                      fill="#EFC75E"
                    />
                  </svg>

                  <span className="Credit-card-divv-main">Credit Card</span>
                </div>
                <div className="Radio-btnn-divv-imgg">
                  <input
                    className="Radio-btnn-master"
                    type="radio"
                    value="option2"
                    checked={selectedOption === "option2"}
                    onChange={handleOptionChange}
                  />
                  <svg
                    width="33"
                    height="26"
                    viewBox="0 0 33 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect
                      x="0.5"
                      y="0.5"
                      width="32"
                      height="25"
                      rx="3.5"
                      fill="white"
                      stroke="#C2C2C2"
                    />
                    <path
                      d="M26.7969 17.5116V17.2596H26.7313L26.6554 17.4325L26.5799 17.2596H26.514V17.5116H26.5607V17.3218L26.6316 17.4855H26.6799L26.7509 17.3213V17.5116H26.7971H26.7969ZM26.3806 17.5116V17.3028H26.4646V17.2602H26.25V17.3028H26.334V17.5116H26.3802H26.3806Z"
                      fill="#F79410"
                    />
                    <path
                      d="M19.3385 18.5922H13.6602V8.38742H19.3386L19.3385 18.5922Z"
                      fill="#FF5F00"
                    />
                    <path
                      d="M14.0211 13.49C14.0211 11.42 14.9903 9.57599 16.4996 8.38763C15.3571 7.48678 13.9441 6.99786 12.4892 7.00001C8.90522 7.00001 6 9.90565 6 13.49C6 17.0744 8.90522 19.9801 12.4892 19.9801C13.9441 19.9822 15.3572 19.4933 16.4997 18.5924C14.9905 17.4043 14.0211 15.5602 14.0211 13.49Z"
                      fill="#EB001B"
                    />
                    <path
                      d="M27.0002 13.49C27.0002 17.0744 24.095 19.9801 20.5111 19.9801C19.056 19.9822 17.6427 19.4933 16.5 18.5924C18.0097 17.4041 18.979 15.5602 18.979 13.49C18.979 11.4199 18.0097 9.57599 16.5 8.38763C17.6427 7.48681 19.0559 6.9979 20.511 7.00001C24.0949 7.00001 27.0001 9.90565 27.0001 13.49"
                      fill="#F79E1B"
                    />
                  </svg>

                  <span className="Credit-card-divv-main">Debit Card</span>
                </div>
              </div>
              <div className="Two-Radio-btnn-divv-Main">
                <div className="Radio-btnn-divv-imgg">
                  <input
                    className="Radio-btnn-master"
                    type="radio"
                    value="option3"
                    checked={selectedOption === "option3"}
                    onChange={handleOptionChange}
                  />
                  <svg
                    width="33"
                    height="26"
                    viewBox="0 0 33 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect
                      x="0.5"
                      y="0.5"
                      width="32"
                      height="25"
                      rx="3.5"
                      fill="white"
                      stroke="#C2C2C2"
                    />
                    <path
                      d="M19.8327 5C18.9797 5.059 17.9827 5.605 17.4017 6.316C16.8717 6.961 16.4357 7.919 16.6057 8.85C17.5377 8.879 18.5007 8.32 19.0587 7.597C19.5807 6.924 19.9757 5.972 19.8327 5Z"
                      fill="black"
                    />
                    <path
                      d="M23.2035 10.368C22.3846 9.34099 21.2336 8.745 20.1466 8.745C18.7116 8.745 18.1046 9.43199 17.1076 9.43199C16.0796 9.43199 15.2986 8.747 14.0576 8.747C12.8386 8.747 11.5406 9.49199 10.7176 10.766C9.56063 12.56 9.75863 15.933 11.6336 18.8059C12.3046 19.8339 13.2006 20.9899 14.3726 20.9999C15.4156 21.0099 15.7096 20.3309 17.1226 20.3239C18.5356 20.3159 18.8036 21.0089 19.8446 20.9979C21.0176 20.9889 21.9626 19.7079 22.6336 18.6799C23.1145 17.9429 23.2935 17.5719 23.6665 16.7399C20.9536 15.707 20.5186 11.849 23.2035 10.368Z"
                      fill="black"
                    />
                  </svg>

                  <span className="Credit-card-divv-main">Apple Pay</span>
                </div>
                <div
                  className="Radio-btnn-divv-imgg"
                  style={{paddingLeft: "11px"}}
                >
                  <input
                    className="Radio-btnn-master"
                    type="radio"
                    value="option4"
                    checked={selectedOption === "option4"}
                    onChange={handleOptionChange}
                  />
                  <svg
                    width="33"
                    height="26"
                    viewBox="0 0 33 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect
                      x="0.5"
                      y="0.5"
                      width="32"
                      height="25"
                      rx="3.5"
                      fill="white"
                      stroke="#C2C2C2"
                    />
                    <path
                      d="M22.1233 8.6793C22.0754 8.65104 22.021 8.63526 21.9654 8.6334C21.9097 8.63153 21.8545 8.64365 21.8047 8.66865C21.755 8.69371 21.7124 8.73081 21.6807 8.77658C21.649 8.82234 21.6293 8.87531 21.6233 8.93065C21.6113 9.03999 21.5927 9.14931 21.5693 9.26399C20.9167 12.226 19.606 13.6666 17.5613 13.6666H14.5613C14.4846 13.6668 14.4103 13.6934 14.3509 13.742C14.2915 13.7905 14.2507 13.8581 14.2353 13.9333L13.2353 18.6206L13.0567 19.814C12.954 20.3566 13.3106 20.8798 13.8532 20.9826C13.9131 20.9939 13.9738 20.9997 14.0347 21H16.0413C16.5013 21.0014 16.9023 20.6875 17.0113 20.2406L17.822 17H19.5613C21.614 17 23.0327 15.3633 23.556 12.3913C23.9014 10.9761 23.3299 9.49546 22.1233 8.6793Z"
                      fill="#03A9F4"
                    />
                    <path
                      d="M21.4946 6.18802C21.1689 5.81858 20.769 5.52186 20.3211 5.31718C19.8731 5.11249 19.387 5.00443 18.8946 5.00002H13.3746C12.5485 4.99559 11.8452 5.59999 11.7252 6.41734L10.0112 17.8513C9.92915 18.3975 10.3053 18.9068 10.8515 18.9889C10.901 18.9964 10.9511 19.0001 11.0012 19H13.5612C13.638 18.9999 13.7123 18.9733 13.7717 18.9247C13.8311 18.8761 13.8719 18.8085 13.8872 18.7334L14.8306 14.3334H17.5639C19.9359 14.3334 21.5033 12.6754 22.2233 9.40002C22.2522 9.26946 22.2745 9.13752 22.2899 9.00468C22.3725 8.50559 22.344 7.99444 22.2065 7.5076C22.0691 7.02077 21.826 6.57022 21.4946 6.18802Z"
                      fill="#283593"
                    />
                  </svg>

                  <span className="Credit-card-divv-main">Paypal</span>
                </div>
              </div>
            </div>
            <div className="Your-name-main-sec-divv">
              <div className="Your-Divv-name-spann-tagg mt-4">
                <span className="Your-name-tagg-Here">Name on Card</span>
              </div>
              <div className="nlogo-container-div">
                <div className="single-contendiv">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_37_1869)">
                      <path
                        d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H18C18 20.4087 17.3679 18.8826 16.2426 17.7574C15.1174 16.6321 13.5913 16 12 16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
                        fill="#14161B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_37_1869">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="fifth-input-feild"
                  name="CardName"
                  value={values.CardName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="Your-name-main-sec-divv">
              <div className="Your-Divv-name-spann-tagg mt-3">
                <span className="Your-name-tagg-Here">Card Number</span>
              </div>
              <div className="nlogo-container-div">
                <div className="single-contendiv">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_12_873)">
                      <path
                        d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 11H4V19H20V11ZM20 9V5H4V9H20ZM14 15H18V17H14V15Z"
                        fill="#1E2D38"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_12_873">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <input
                  type="number"
                  placeholder="Card Number"
                  className="fifth-input-feild"
                  name="CardNumber"
                  value={values.CardNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="Expiry-month-year-cvv-main-divv">
              <div className="Expiry-of-Addresss-main mt-3">
                <div className="Your-Divv-name-spann-tagg">
                  <span className="Your-name-tagg-Here">Expiry Date</span>
                </div>
                <div className="Expiryy-dropdown-city">
                  <select
                    className="Expiry-Adddress-divv-Dropdown"
                    value={values.Expiry}
                    onChange={handleChange}
                    onBlur={handleBlur}>
                    <option value="" disabled selected hidden>
                      MM
                    </option>
                    <option value="City 1">City 1</option>
                    <option value="City 2">City 2</option>
                    <option value="City 3">City 3</option>
                  </select>
                </div>
                {errors.Expiry && touched.Expiry ? (
                  <p className="form-errror">{errors.Expiry} </p>
                ) : null}
              </div>
              <div className="Expiry-of-Addresss-main mt-5">
                <div className="Expiryy-dropdown-city">
                  <select
                    className="Expiry-Adddress-divv-Dropdown"
                    value={values.year}
                    onChange={handleChange}
                    onBlur={handleBlur}>
                    <option value="" disabled selected hidden>
                      YYYY
                    </option>
                    <option value="City 1">City 1</option>
                    <option value="City 2">City 2</option>
                    <option value="City 3">City 3</option>
                  </select>
                </div>
                {errors.Expiry && touched.Expiry ? (
                  <p className="form-errror">{errors.Expiry} </p>
                ) : null}
              </div>
              <div className="Your-name-main-sec-divv">
                <div className="Your-Divv-name-spann-tagg mt-4">
                  <span className="Your-name-tagg-Here">CVV</span>
                </div>
                <div className="nlogo-container-div">
                  <input
                    type="number"
                    placeholder="CVV"
                    className="Input-cvv-feild"
                    name="CardNumber"
                    value={values.CardNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            </div>
            <div className="Book-check-next-btnn-divv mt-3">
              <button
                className="check-book-btnn"
                onClick={() => navigate("/Successfully")}>
                Book Now
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Payment;
