import "./test.css";
import React, { useState, useEffect, useContext } from "react";

import { Resizable } from "react-resizable";

const Index = () => {
  const [columnWidths, setColumnWidths] = useState([
    { width: 100 },
    { width: 100 },
    { width: 100 },
    { width: 100 },
    { width: 100 },
  ]);

  // Column resizing function
  const handleResize =
    (index) =>
    (e, { size }) => {
      const newColumnWidths = [...columnWidths];
      newColumnWidths[index] = { width: size.width };
      setColumnWidths(newColumnWidths);
    };

  return (
    <div style={{ marginTop: "10rem" }}>
      <div className="resizable-table">
        <table>
          <tbody>
            {[...Array(4)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(5)].map((__, colIndex) => (
                  <td key={colIndex}>
                    <Resizable
                      width={columnWidths[colIndex].width}
                      height={0}
                      onResize={handleResize(colIndex)}>
                      <div>{`Column${colIndex + 1}, Row${rowIndex + 1}`}</div>
                    </Resizable>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Index;
