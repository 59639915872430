import "../Modals/Chatbox.css";
import EmojiPicker from "emoji-picker-react";
import Modal from "react-bootstrap/Modal";
import plumber from "../../../User/assets/svg/Squareyellowcap.svg";
import yellowcap from "../../../User/assets/svg/yellowplumber.svg";

import React, { useContext, useEffect, useRef, useState } from "react";

// import { Icons } from "../../Constant/Icons";
// import { Cardimages } from "../../Constant/Cardimages";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "@firebase/firestore";
import { db } from "../../../Common/Firebase/firebase";
// import { store } from "../../MyRedux/MyRedux/redux/store";
// import { KEY_USER_DATA } from "../../Redux/AppConstants";
// import { Colors } from "../../Constant/Colors";
// import { GlobalContext } from "../../Context/GlobalContext";
// import { SimpleLoader } from "../../Constant/Loader";
// import Addmember from "./Addmember";
// import moment from "moment/moment";

const Chatbox = ({ show, onHide }) => {
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const toggleEmojiPicker = () => {
    setIsEmojiPickerVisible(!isEmojiPickerVisible);
  };

  const Arrow = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_11_1600)">
        <path
          d="M3 13H9V11H3V1.84598C3.00001 1.7591 3.02267 1.67372 3.06573 1.59825C3.1088 1.52279 3.17078 1.45985 3.24558 1.41564C3.32037 1.37143 3.4054 1.34747 3.49227 1.34613C3.57915 1.34479 3.66487 1.36611 3.741 1.40798L22.203 11.562C22.2814 11.6051 22.3468 11.6686 22.3923 11.7456C22.4378 11.8226 22.4618 11.9105 22.4618 12C22.4618 12.0895 22.4378 12.1773 22.3923 12.2544C22.3468 12.3314 22.2814 12.3948 22.203 12.438L3.741 22.592C3.66487 22.6339 3.57915 22.6552 3.49227 22.6538C3.4054 22.6525 3.32037 22.6285 3.24558 22.5843C3.17078 22.5401 3.1088 22.4772 3.06573 22.4017C3.02267 22.3263 3.00001 22.2409 3 22.154V13Z"
          fill="#00AD5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_1600">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  // ? chat box  code
  const [message, setmessage] = useState("");
  const [messagedata, setmessagedata] = useState([]);
  const [meData, setMeData] = useState(null);

  const divforscroll = useRef(null);

  const route = useLocation();

  const Mydata = JSON.parse(localStorage.getItem("messageSenderdata"));

  const [loading, setloading] = useState(false);

  // useEffect for get Chat data....................
  useEffect(() => {
    if (show && Mydata) {
      setloading(true);

      const q = query(
        collection(db, "chatrooms", Mydata?.Roomid, "mychat"),
        orderBy("time", "asc")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const arr = [];
        snapshot.forEach((doc) => {
          arr.push(doc.data());
        });
        setmessagedata(arr);

        setloading(false);
        if (divforscroll.current) {
          divforscroll.current.scrollTop = divforscroll.current.scrollHeight;
        }
      });
      return unsubscribe;
    }
  }, [show]);
  // console.log(Mydata);
  const fetchMeData = async () => {
    try {
      const meRef = doc(
        db,
        "users",
        JSON.stringify(Mydata?.Myid),
        "mychat",
        Mydata?.OppId
      );
      const meDoc = await getDoc(meRef);
      if (meDoc.exists()) {
        setMeData(meDoc.data());
        // console.log(meDoc.data());
      } else {
        // console.log("Document does not exist");
      }
    } catch (error) {
      // console.error("Error fetching document:", error);
    }
  };
  // for send message.........................
  const OnsendMessages = async (e) => {
    if (message) {
      var sentMsg = message;
      e.preventDefault();
      setmessage("");
      const messdata = {
        message: message,
        sendername: Mydata?.Myname,
        fromid: Mydata?.Myid,
        time: Date.now() * 1000,
        seen: false,
      };
      fetchMeData();
      const otherdata = {
        chatid: Mydata?.Roomid,
        lastmessageid: Mydata?.OppId,
        lastmessage: sentMsg,
        lastmessagetime: Date.now() * 1000,
        unreadcount: "0",
      };
      const mydata = {
        chatid: Mydata?.Roomid,
        lastmessageid: Mydata?.Myid,
        lastmessage: sentMsg,
        lastmessagetime: Date.now() * 1000,

        // unreadcount:
        //   countobject != null ? (+countobject?.unreadcount + 1).toString() : "1",
        unreadcount:
          meData != null ? (+meData?.unreadcount + 1).toString() : "1",
      };

      if (divforscroll.current) {
        divforscroll.current.scrollTop = divforscroll.current.scrollHeight;
      }

      sendMessage(messdata);

      // for save last message in my
      const mydetail = doc(
        collection(db, "users"),
        JSON.stringify(Mydata?.Myid)
      );
      const oppdetail = doc(
        collection(mydetail, "mychat"),
        JSON.stringify(Mydata?.OppId)
      );
      setDoc(oppdetail, otherdata);
      // for save last message in other
      const oppdocref = doc(
        collection(db, "users"),
        JSON.stringify(Mydata?.OppId)
      );
      const oppndocref = doc(
        collection(oppdocref, "mychat"),
        JSON.stringify(Mydata?.Myid)
      );
      // const messageRef = await addDoc(
      //   collection(db, "groups", userdata.a.chatid)
      // );
      // await updateDoc(doc(db, "groups", userdata.a.chatid), {
      //   lastmessage: sentMsg,
      //   lastmessagetime: new Date(),
      // });
      setDoc(oppndocref, mydata);
    }
  };
  // console.log(Mydata.detail);

  // sendmessage............................
  const sendMessage = async (mess) => {
    const docRef = await addDoc(
      collection(db, "chatrooms", Mydata?.Roomid, "mychat"),
      mess
    );
  };

  // console.log(messagedata);
  function splitTextIntoLines(message) {
    const maxLength = 35;
    const chunks = [];

    for (let i = 0; i < message.length; i += maxLength) {
      chunks.push(message.substr(i, maxLength));
    }

    return chunks.map((chunk, index) => (
      <React.Fragment key={index}>
        {chunk}
        <br />
      </React.Fragment>
    ));
  }
  // console.log(userdata);
  // ? chat box code

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        dialogClassName="bottom-right-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="Brokylyn-andtext-main-divv">
              <div className="Chatt-boxx-secondary-div">
                <div className="boxheader">
                  <div>
                    <div className="chatboxheader">
                      {Mydata && <img src={Mydata?.Oppimage} alt="" />}
                      <h3>{Mydata?.OppName || Mydata?.Myname}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>

        <div
          className={"boxmaindivactive"}
          style={{ display: "block", borderRadius: "10px" }}>
          <div
            className="chatmessages"
            ref={divforscroll}
            style={{
              overflowY: "scroll",
              scrollBehavior: "smooth",
              height: "500px",
            }}>
            <>
              {!Mydata ? null : loading ? (
                <div>Loading...</div>
              ) : (
                <>
                  {messagedata?.map((item, index) => {
                    const itemTimeMicroseconds = item.time;
                    const itemTimeMilliseconds = Math.floor(
                      itemTimeMicroseconds / 1000
                    );
                    const createdTime = new Date(itemTimeMilliseconds);
                    const formattedTime = createdTime.toLocaleTimeString(
                      "en-US",
                      {
                        hour: "numeric",
                        minute: "numeric",
                      }
                    );
                    return (
                      <div
                        key={index}
                        style={{
                          alignItems:
                            item.fromid == Mydata?.Myid
                              ? "flex-end"
                              : "flex-start",
                        }}>
                        <div>
                          {item.sendername !== Mydata?.name && (
                            <span
                              style={{
                                // color: Colors.pink,
                                fontSize: "13px",
                              }}>
                              {item.sendername}
                            </span>
                          )}
                          <span
                            style={{
                              paddingLeft: "5px",
                              alignSelf:
                                item.fromid == Mydata?.Myid
                                  ? "flex-end"
                                  : "flex-start",
                            }}
                            className="mestime">
                            {formattedTime}
                          </span>
                        </div>
                        <p
                          className={
                            item.fromid == Mydata?.Myid ? "sent" : "chatp"
                          }
                          style={{
                            alignSelf:
                              item.fromid == Mydata?.Myid
                                ? "flex-end"
                                : "flex-start",
                          }}>
                          {splitTextIntoLines(item.message)}
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
            </>
            {/* <span>{userdata?.time}</span> */}
          </div>
          <div className="sendmessage">
            <textarea
              value={message}
              onChange={(e) => setmessage(e.target.value)}
              type="text"
              placeholder="Write here"
            />
            <div
              type="submit"
              disabled={message === "" ? "disable" : ""}
              onClick={OnsendMessages}>
              <span>{Arrow}</span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Chatbox;
