import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../../../GlobalContext";
import "../AddressModal/AddressModal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import { AddressSchemas } from "../../../../schemas";
import BookNow from "../BookNowModal/BookNow";
import close from "../../../assets/svg/Close.svg";
import axios from "../../../../Common/Api/index";
import { toast } from "react-hot-toast";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ModalBody } from "react-bootstrap";
const initialValues = {
  YourName: "",
  Mobile: "",
  City: "",
  Address: "",
  WorkRequirement: "",
};
const AddressModal = ({ show, onHide }) => {
  const [Workrequirement, setWorkrequirement] = useState();
  const [loading, setloading] = useState(false);
  const userdata = JSON.parse(localStorage.getItem("login"));
  const {
    idget,
    Adressmodelshow,
    setAdressmodelshow,
    Successdata,
    setSuccessdata,
  } = useContext(GlobalContext);
  // console.log("🚀 ~ AddressModal ~ idget:", idget);
  const { availidget, setAvailidget } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  // console.log(availidget, "AVA============================>");

  const navigate = useNavigate();
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    // console.log("🚀 ~ onSubmit ~ values:", values);
    setloading(true);
    try {
      const response = await axios.post(
        "booking",
        {
          worker_id: idget.id,
          cat_id: idget.cat_id,
          subcate_id: idget.subcate_id,
          availability_id: availidget,
          address: values.Address,
          latitude: localStorage.getItem("lat"),
          longitude: localStorage.getItem("long"),
          city: values.City,
          requirement: values.WorkRequirement,
        },
        {
          headers: {
            "Content-Type": "application/json postman",
            Authorization: `Bearer ${userdata.data.data.api_token}`,
          },
        }
      );
      // console.log(response, "Booking successful ===>");
      setloading(false);
      if (response?.data?.status === "success") {
        setAdressmodelshow(false);
        setAvailidget("");
        navigate("/PaymentVerify");
        resetForm();
        setSuccessdata(response.data);
        // setOpen(true);
        localStorage.setItem("Booking Success", JSON.stringify(response.data));
        toast.success(response?.data?.message, {
          position: "top-right",
          theme: "colored",
        });
        onHide();
      } else if (
        response.data.message == "You have already rated this worker"
      ) {
        toast.error(response?.data?.message, {
          position: "top-left",
          theme: "colored",
        });
      }
    } catch (error) {
      setloading(false);
      toast.error(error.response.data.message, {
        position: "top-left",
        theme: "colored",
      });
      console.log(error);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      localStorage.setItem("lat", position.coords.latitude);

      localStorage.setItem("long", position.coords.longitude);
    });
  }, []);
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: AddressSchemas,
    onSubmit: onSubmit,
  });
  return (
    <>
      <BookNow show={open} onHide={() => setOpen(false)} />
      <Modal
        show={Adressmodelshow}
        onHide={() => setAdressmodelshow(false)}
        size="md"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="Address-main-payment-title">
            <span className="Address-main-span-divv">Address</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="Address-main-popup-divv-ppx">
            <form onSubmit={handleSubmit} className="Address-Popup-Main-Divv">
              <div className="Address-section-divv-Pxx mt-3">
                <div className="Your-name-main-sec-divv">
                  <div className="Your-Divv-name-spann-tagg">
                    <span className="Your-name-tagg-Here">Your Name</span>
                  </div>
                  <div className="input-text-Field-divv mt-2">
                    <div className="Name-container-div">
                      <input
                        type="text"
                        placeholder="Your Name"
                        className="input-feild-Of-address"
                        name="YourName"
                        value={values.YourName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={25}
                      />
                    </div>
                    {errors.YourName && touched.YourName ? (
                      <p className="form-errror">{errors.YourName} </p>
                    ) : null}
                  </div>
                </div>
                <div className="Your-name-main-sec-divv mt-3">
                  <div className="Your-Divv-name-spann-tagg">
                    <span className="Your-name-tagg-Here">Mobile</span>
                  </div>
                  <div className="input-text-Field-divv mt-2">
                    <div className="Name-container-div">
                      <input
                        type="text"
                        placeholder="Mobile"
                        className="input-feild-Of-address"
                        name="Mobile"
                        value={values.Mobile}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                        maxLength="10"
                      />
                    </div>
                    {errors.Mobile && touched.Mobile ? (
                      <p className="form-errror">{errors.Mobile} </p>
                    ) : null}
                  </div>
                </div>
                <div className="Your-name-main-sec-divv mt-3">
                  <div className="Your-Divv-name-spann-tagg">
                    <span className="Your-name-tagg-Here">Address</span>
                  </div>
                  <div className="input-text-Field-divv mt-2">
                    <div className="Name-container-div">
                      <input
                        type="text"
                        placeholder="Address"
                        className="input-feild-Of-address"
                        name="Address"
                        value={values.Address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={30}
                      />
                    </div>
                    {errors.Address && touched.Address ? (
                      <p className="form-errror">{errors.Address} </p>
                    ) : null}
                  </div>
                  <div className="Your-Divv-name-spann-tagg mt-2">
                    <span className="Your-name-tagg-Here">City</span>
                  </div>
                  <div className="input-text-Field-divv mt-2">
                    <div className="Name-container-div">
                      <input
                        type="text"
                        placeholder="City"
                        className="input-feild-Of-address"
                        name="City"
                        value={values.City}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={20}
                      />
                    </div>
                    {errors.City && touched.City ? (
                      <p className="form-errror">{errors.City} </p>
                    ) : null}
                  </div>
                </div>

                <div className="text-span-div mt-3">
                  <div className="Your-Divv-name-spann-tagg">
                    <span className="Your-name-tagg-Here">
                      Work Requirement
                    </span>
                  </div>
                  <textarea
                    name="WorkRequirement"
                    rows="4"
                    className="work-req-here-input   mt-2"
                    cols="31"
                    value={values.WorkRequirement}
                    placeholder="Write here"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // onChange={(e) => {
                    //   setWorkrequirement(e.target.value);
                    //   setFieldValue("WorkRequirement", e.target.value);
                    // }}
                    maxLength={100}
                    minLength={3}></textarea>
                  {errors.WorkRequirement && touched.WorkRequirement ? (
                    <p className="form-errror">{errors.WorkRequirement} </p>
                  ) : null}
                </div>
              </div>
              <div className="Address-next-btnn-divv mt-4">
                <Button
                  className="Add-Next-btnn"
                  type="submit"
                  variant="primary"
                  // onClick={() => {}}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default AddressModal;
