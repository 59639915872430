import { useState, useContext } from "react";
import "./sendotP.css";
import Modal from "react-bootstrap/Modal";
import { GlobalContext } from "../../../../GlobalContext";
import axios from "../../../../Common/Api";
import { toast } from "react-hot-toast";
import { Backdrop, CircularProgress } from "@mui/material";

const Index = () => {
  const { Sendotpnumber, setSendotpnumber, otpVerify, setOtpVerify } =
    useContext(GlobalContext);
  // console.log("🚀 ~ Index ~ otpVerify:", otpVerify);
  const [loading, setloading] = useState(false);
  // console.log("🚀 ~ Index ~ Sendotpnumber:", Sendotpnumber);
  const userdata = JSON.parse(localStorage.getItem("login"));

  const userID = Sendotpnumber?.data?.id;
  // console.log("🚀 ~ Index ~ userID:", userID);
  const onSabmit = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "send-otp",
        { id: userID },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userdata.data.data.api_token}`,
          },
        }
      );
      setloading(false);
      // console.log("check otp send data=====>", response);
      if (response.data.status === "success") {
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
        setOtpVerify({ istrue: true, data: userID });
        setSendotpnumber(false);
      } else {
        setloading(false);
      }
    } catch (error) {
      // console.log("check otp send error:==---> ", error);
      setloading(false);
    }
  };

  const sendotpbutton = () => {
    onSabmit();
  };

  return (
    <div>
      <Modal
        show={Sendotpnumber.istrue}
        onHide={() => setSendotpnumber(false)}
        size="sm"
        centered>
        <Modal.Header style={{ border: "0" }} closeButton></Modal.Header>
        <Modal.Body>
          <div className="main_send_otp_div">
            <div className="otp_sub_main_div">
              <p>
                Confirm OTP send to{" "}
                <span>{Sendotpnumber?.data?.user_name}</span>
              </p>
              <button className="card_btn_2" onClick={() => sendotpbutton()}>
                Send OTP
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </div>
  );
};

export default Index;
