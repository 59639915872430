import React, { useContext } from "react";
import sec5data from "./sec5_data";
import { useNavigate } from "react-router-dom";
import "./sec5.css";
import { GlobalContext } from "../../../../GlobalContext";
const Section5 = () => {
  const Navigate = useNavigate();
  const { setLoginmodalshow } = useContext(GlobalContext);
  const userData = JSON.parse(localStorage.getItem("login"));

  const truncateText = (text, maxWords) => {
    const wordsArray = text.split(" ");
    if (wordsArray.length > maxWords) {
      return wordsArray.slice(0, maxWords).join(" ") + "...";
    }
    return text;
  };

  return (
    <div className="main_sec5_div">
      <h1>How it Works</h1>
      <div className="sec5_sub_div">
        {sec5data.map((item, index) => {
          return (
            <div className="sec5_card" key={index}>
              <div className="sec5_img_div">
                <img src={item.pic} alt="img" />
              </div>
              <div className="sec5_text">
                <h4>{item.title}</h4>
                {/* <p>{truncateText(item.pre, 10)}</p> */}
                <p>{item.pre}</p>
              </div>
            </div>
          );
        })}
      </div>
      <button
        onClick={() => Navigate("/Search")}
        // onClick={() => Navigate(userData ? "/Search" : setLoginmodalshow(true))}
        className="btn5">
        Book a Service
      </button>
    </div>
  );
};

export default Section5;
