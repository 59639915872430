import React, { useState, useEffect, useContext } from "react";
import "./EditProfile.css";
import { useFormik } from "formik";
import { EditProfileSchemas } from "../../../../schemas/index";
import Profile from "../../../assets/svg/Profile.svg";
import camera from "../../../assets/svg/camera.svg";
import axios from "../../../../Common/Api/index";
import { toast } from "react-hot-toast";
import { Await, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../GlobalContext.jsx";
import { Backdrop, CircularProgress } from "@mui/material";
import { db } from "../../../../Common/Firebase/firebase";
import { doc, collection, setDoc } from "firebase/firestore";

const initialValues = {
  FullName: "",
  MobileNumber: "",
  email: "",
};

const EditProfile = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setloading] = useState(false);
  const [imgfile, setimgfile] = useState();

  const { profiledata, setprofiledata, profilereload, setProfilereload } =
    useContext(GlobalContext);

  const userdata = JSON.parse(localStorage.getItem("login"));
  const [Image, setImage] = useState({ img: "" });

  const navigate = useNavigate();

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };
  useEffect(() => {
    setImage({ img: profiledata?.profile_image });
  }, []);
  // console.log(userdata);
  const onSubmit = async () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("name", values.FullName);
    formdata.append("phone", values.MobileNumber);
    formdata.append("ratePerhour", "");
    formdata.append("description", "");
    formdata.append("skil", "");
    formdata.append("speciality_id", "");
    formdata.append("experience", "");
    formdata.append("profile_image", imgfile);

    try {
      let config = {
        url: "update-profile",
        method: "post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userdata.data.data.api_token}`,
        },
      };
      axios(config)
        .then((response) => {
          setloading(false);
          localStorage.setItem("login", JSON.stringify(response));
          // console.log("updated======>", response);
          if (response?.data?.status == "failed") {
            // toast.error(response.data.message, {
            //   position: "top-left",
            //   theme: "colored",
            // });
          } else if (response?.data?.status == "success") {
            toast.success(response.data.message, {
              position: "top-right",
              theme: "colored",
            });
            setProfilereload(true);
            getProfile();
            // navigate("/Profile/");
          }
        })
        .catch((error) => {
          setloading(false);
          // console.log(error);
        });
    } catch (error) {
      setloading(false);

      // console.log(error);
      if (error.response.data.status == "failed") {
        // toast.error(`${error.response.data.message}`, {
        //   position: "top-left",
        //   theme: "colored",
        // });
      }
    }
  };
  // console.log(userdata);
  // console.log(Image.img, "check image");

  //  ! get orfile api calling this function
  const getProfile = async () => {
    setloading(true);
    try {
      const response = await axios.get("get-profile", {
        headers: {
          // Authorization: `${token.data.api_token}`,
          Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
        },
      });
      setloading(false);
      setprofiledata(response.data.data);
      // Prepare data to store in Firestore
      const data = {
        userrole: response.data.data.user_type,
        name: response.data.data.name ?? "",
        email: response.data.data.email,
        id: response.data.data.id,
        image: response.data.data.profile_image
          ? response.data.data.profile_image
          : Profile,
        fcmtoken: response?.data?.data?.devicetoken,
        updatedat: new Date(),
      };

      // Store user data in Firestore with error handling
      const doref = doc(
        collection(db, "users"),
        JSON.stringify(response.data.data.id)
      );
      await setDoc(doref, data)
        .then((rrr) => {
          console.log("User data successfully stored in Firestore:", rrr);
        })
        .catch((error) => {
          console.error("Error setting data in Firestore:", error);
        });
    } catch (error) {
      setloading(false);
      // console.log(error);

      // toast.error(`${error?.response?.message}`);
    }
  };
  useEffect(() => {
    getProfile();
  }, [setprofiledata]);
  useEffect(() => {
    if (profilereload === true) {
      getProfile();
    }
  }, [profilereload]);

  //! get profile api function End
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    setFieldValue,
    handleSubmit,
    errors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: EditProfileSchemas,
    onSubmit,
  });
  useEffect(() => {
    setFieldValue("FullName", profiledata?.name);
    setFieldValue("email", profiledata?.email);
    setFieldValue("MobileNumber", profiledata?.phone);
    setImage({ img: profiledata?.profile_image });
  }, [profiledata]);

  return (
    <>
      <div className="All-Conatinor-perfect-divv">
        <div className="All-Containor-perfect-second-divv">
          <div className="edit-profile-single-div">
            <span className="Settikgs-main-span">Edit Profile</span>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="edit-profile-main27-div27  mt-3">
              <div className="Ht-ml-for-imput mb-4 mt-4">
                <label className="" htmlFor="mal">
                  <img src={Profile} alt="" className="pic-on-add" />
                  <div className="SandIcon-addeting-div">
                    <img className="Cemara-te-img" src={camera} alt="" />
                  </div>
                </label>

                <input
                  id="mal"
                  type="file"
                  onChange={(e) => {
                    setImage({ img: URL.createObjectURL(e.target.files[0]) });
                    setimgfile(e.target.files[0]);
                  }}
                  className="Malte-pal-cls"
                />

                <img
                  src={Image.img}
                  width="100px"
                  height="100px"
                  className="add-kiya-muja"
                />
              </div>
              <div className="input-text-div-dad-div">
                <div className="input-text-div-pap-div">
                  <div className="label-or-feild-div24">
                    <label htmlFor="fullname" className="html12-label-span">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="kathryn-input22"
                      placeholder="Enter your name"
                      name="FullName"
                      value={values.FullName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.FullName && touched.FullName ? (
                      <p className="mastu-to-eroor mt-2">{errors.FullName} </p>
                    ) : null}
                  </div>
                  <div className="label-or-feild-div24">
                    <label htmlFor="fullname" className="html12-label-span">
                      Mobile Number
                    </label>

                    <input
                      className="kathryn-input22"
                      placeholder="Enter your number"
                      type="text"
                      name="MobileNumber"
                      value={values.MobileNumber}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          handleChange(e);
                        }
                      }}
                      onBlur={handleBlur}
                      maxLength="10"
                    />
                    {errors.MobileNumber && touched.MobileNumber ? (
                      <p className="mastu-to-eroor mt-2">
                        {errors.MobileNumber}{" "}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="label-or-feild-div24-90">
                  <label htmlFor="fullname" className="html12-label-span-75">
                    Email
                  </label>

                  <input
                    type="email"
                    className="kathryn-sec-input"
                    placeholder="Enter your email"
                    value={values.email}
                    style={{ color: "#000" }}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={true}
                  />
                  {errors.email && touched.email ? (
                    <p className="mastu-to-eroor mt-2">{errors.email} </p>
                  ) : null}
                </div>
                <div className="button-save-div">
                  <button type="submit" className="save-button-ppx">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default EditProfile;
