import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./ForgotPassword.css";
import kalu from "../../../assets/Kaluu.png";
import { useFormik } from "formik";
import { ForgotPasswordSchemas } from "../../../../schemas";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "../../../../Common/Api/index";

function MyModal(props) {
  const initialValues = {
    email: "",
  };
  const { values, handleBlur, handleChange, touched, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ForgotPasswordSchemas,

      onSubmit: (values) => {},
    });
  const navigate = useNavigate();

  const ForgotPass = async () => {
    // setLoading(true);
    // console.log(values);
    try {
      const res = await axios.post(
        "",
        {},
        {
          headers: {
            authkey: "",
            "content-Type": "application/json",
          },
        }
      );
      // setLoading(false);

      // if (res.data.status == "failed") {
      //   toast.error(`${res.data.message}`);
      // } else if (res.data.status == "success") {
      //   toast.success(`${res.data.message}`);
      //   navigate("/Congratulation");
      // }

      // console.log(res);
    } catch (error) {
      // console.log(error);
      // setLoading(false);
    }
  };
  return (
    <Modal {...props} size="xl" centered className="forgotPassMainModalDiv">
      <div className="Forget-sec-main-divv">
        <div className="forget-pass-sec-divv">
          <div className="imgg-black-man-divv">
            <img className="Forget-black-man-imgg" src={kalu} alt="" />
          </div>
          <div className="main-forget-goes-here">
            <div
              className="back-btnn-main-divv"
              style={{ cursor: "pointer" }}
              onClick={props.onHide}>
              <div className="back-btnn-imgg">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_52_4984)">
                    <path
                      d="M7.828 10.9997H20V12.9997H7.828L13.192 18.3637L11.778 19.7777L4 11.9997L11.778 4.22168L13.192 5.63568L7.828 10.9997Z"
                      fill="#14161B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_52_4984">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="back-span-text-divv">
                <span className="back-span-tagg-ppx">Back</span>
              </div>
            </div>
            <div className="forget-password-main-form">
              <div className="forgetpass-main-span-divv">
                <span className="span-tagg-for-pass">Forgot Password</span>
              </div>
              <div className="para-forget-divv-span">
                <span className="para-forget-span-tagg">
                  Please enter the email address you ‘d like to your password
                  reset information sent to.
                </span>
              </div>
              <div>
                <div className="forget-email-input-div">
                  <div className="email-input-image-forget-div">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_52_4988)">
                        <path
                          d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 7.238L12.072 14.338L4 7.216V19H20V7.238ZM4.511 5L12.061 11.662L19.502 5H4.511Z"
                          fill="#14161B"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_52_4988">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  <input
                    type="text"
                    placeholder="Email"
                    className="forgot-input-feild"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.email && touched.email ? (
                  <p className="mastu-to-eroor mt-2">{errors.email} </p>
                ) : null}
              </div>
              <div>
                <button className="forgot-button">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default MyModal;

// function ForgotPaasFunc() {
//   const [modalShow, setModalShow] = useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically centered modal
//       </Button>

//       <MyModal show={modalShow} onHide={() => setModalShow(false)} />
//     </>
//   );
// }

// export default ForgotPaasFunc;
