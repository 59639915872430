import pic1 from "../../../../User/assets/sec7_1.png";
import pic2 from "../../../../User/assets/sec7_2.png";
import pic3 from "../../../../User/assets/sec7_3.png";
import pic4 from "../../../../User/assets/sec7_4.png";
import pic5 from "../../../../User/assets/sec7_5.png";
import pic6 from "../../../../User/assets/sec7_6.png";
const start = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_35_8969)">
      <path
        d="M7.50001 10.625L3.82626 12.8687L4.82501 8.68125L1.55626 5.88125L5.84688 5.5375L7.50001 1.5625L9.15313 5.5375L13.4444 5.88125L10.175 8.68125L11.1738 12.8687L7.50001 10.625Z"
        fill="#F2B006"
      />
    </g>
    <defs>
      <clipPath id="clip0_35_8969">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Sec7data = [
  {
    id: 1,
    img: pic1,
    Name: "Jane Cooper",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    disc: "Cleaner",
  },
  {
    id: 2,
    img: pic2,
    Name: "Jacob Jones",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    disc: "Plumber",
  },
  {
    id: 3,
    img: pic3,
    Name: "Arlene McCoy",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    disc: "Carpenter",
  },
  {
    id: 4,
    img: pic4,
    Name: "Theresa Webb",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    disc: "Plumber",
  },
  {
    id: 5,
    img: pic5,
    Name: "Kristin Watson",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    disc: "Electrician",
  },
  {
    id: 6,
    img: pic6,
    Name: "Ronald Richards",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    disc: "Repairer",
  },
  {
    id: 7,
    img: pic6,
    Name: "Ronald Richards",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    disc: "Repairer",
  },
  {
    id: 8,
    img: pic6,
    Name: "Ronald Richards",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    disc: "Repairer",
  },
  {
    id: 9,
    img: pic6,
    Name: "Ronald Richards",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    disc: "Repairer",
  },
  {
    id: 10,
    img: pic6,
    Name: "Ronald Richards",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    disc: "Repairer",
  },
  {
    id: 11,
    img: pic6,
    Name: "Ronald Richards",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    disc: "Repairer",
  },
  {
    id: 12,
    img: pic6,
    Name: "Ronald Richards",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    disc: "Repairer",
  },
  {
    id: 13,
    img: pic6,
    Name: "Ronald Richards",
    ico: start,
    rateing: "3.2",
    totalrating: "(233)",
    disc: "Repairer",
  },
];

export default Sec7data;
