import React, { useRef, useState, useEffect, useContext } from "react";

import "./sec8.css";
import Sec8data from "./Sec8data.js";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import axios from "../../../../Common/Api/index.jsx";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-hot-toast";
import sec8_1 from "../../../assets/sec8_1.png";

// import required modules
import { EffectCards } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { GlobalContext } from "../../../../GlobalContext";
const Section8 = () => {
  const Naviagte = useNavigate();
  const { setLoginmodalshow } = useContext(GlobalContext);
  const [loading, setloading] = useState(false);
  const [Sec8data, setSec8data] = useState([]);
  // console.log("check sec8 data===>", Sec8data);
  const userData = JSON.parse(localStorage.getItem("login"));
  const Lat = JSON.parse(localStorage.getItem("lat"));
  const Long = JSON.parse(localStorage.getItem("long"));
  const sec9details = async () => {
    setloading(true);
    try {
      const Response = await axios.post("remodeling", {
        latitude: Lat,
        longitude: Long,
      });
      setloading(false);
      setSec8data(Response.data.Experts);
      // console.log("check sec8 data===>", Response.data.Experts);
    } catch (error) {}
  };
  useEffect(() => {
    sec9details();
  }, []);
  const start = (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_35_8969)">
        <path
          d="M7.50001 10.625L3.82626 12.8687L4.82501 8.68125L1.55626 5.88125L5.84688 5.5375L7.50001 1.5625L9.15313 5.5375L13.4444 5.88125L10.175 8.68125L11.1738 12.8687L7.50001 10.625Z"
          fill="#F2B006"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_8969">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const handleclick = async (item) => {
    // console.log("🚀 ~ handledetail ~ item:", item);
    Naviagte("/WorkerProfile", {
      state: {
        item,
      },
    });
  };
  return (
    <div className="main_sec4_div">
      <div className="sub_sec4_div">
        <h2>Popular Remodeling Projects</h2>

        <div className="card_box">
          {loading
            ? Array(3)
                .fill(0)
                .map((_, index) => (
                  <div key={index}>
                    <SkeletonTheme baseColor="#949495" highlightColor="#D1D1D1">
                      <Skeleton className="card_div" />
                    </SkeletonTheme>
                  </div>
                ))
            : Sec8data?.slice(0, 3)?.map((item, index) => {
                return (
                  <div className="card_div" key={index}>
                    <img src={item.worker_image} alt="" />
                    <div className="card_data_sec4">
                      <div className="text_sec4_card">
                        <h4>{item.subcategory_name}</h4>
                        <div className="raiting">
                          <p>
                            {start}
                            {item.average_rate}
                          </p>
                          <p>( {item.rate_count} )</p>
                        </div>
                      </div>
                      <button
                        // onClick={() =>
                        //   userData ? handleclick(item) : setLoginmodalshow(true)
                        // }
                        onClick={() => handleclick(item)}
                        className="btn4">
                        Book Now
                      </button>
                    </div>
                  </div>
                );
              })}
        </div>

        <div className="ccard_box">
          <Swiper effect={"cards"} modules={[EffectCards]}>
            {Sec8data?.slice(0, 3)?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="main_swiper_box">
                    <img src={item.worker_image} alt="" />
                    <div className="card_data_sec4">
                      <div className="text_sec4_card">
                        <h4>{item.subcategory_name}</h4>
                        <div className="raiting">
                          <p>
                            {start}
                            {item.avg_rate}
                          </p>
                          <p>( {item.rate_count} )</p>
                        </div>
                      </div>
                      <button
                        // onClick={() =>
                        //   userData ? handleclick(item) : setLoginmodalshow(true)
                        // }
                        onClick={() => handleclick(item)}
                        className="btn4">
                        Book Now
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Section8;
