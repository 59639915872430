import React from "react";
import "../MyWallet/MyWallet.css";
import plus from "../../../../User/assets/svg/plus.svg";
import visa from "../../../../User/assets/svg/visa.svg";
import master from "../../../../User/assets/svg/Master2.svg";
import P1 from "../../../../User/assets/svg/P1.svg";
import P2 from "../../../../User/assets/svg/P2.svg";
import P3 from "../../../../User/assets/svg/P3.svg";
import P4 from "../../../../User/assets/svg/P4.svg";
import P5 from "../../../../User/assets/svg/P5.svg";
import P6 from "../../../../User/assets/svg/P6.svg";

const MyWallet = () => {
  const Hdata = [
    {
      img: P1,
      Name: "Albert Flores",
      Date: "21 Jan 2023",
      Price: "$199",
    },
    {
      img: P2,
      Name: "Guy Hawkins",
      Date: "21 Jan 2023",
      Price: "$199",
    },
    {
      img: P3,
      Name: "Theresa Webb",
      Date: "21 Jan 2023",
      Price: "$199",
    },
    {
      img: P4,
      Name: "Darrell Steward",
      Date: "21 Jan 2023",
      Price: "$199",
    },
    {
      img: P5,
      Name: "Eleanor Pena",
      Date: "21 Jan 2023",
      Price: "$199",
    },
    {
      img: P6,
      Name: "Courtney Henry",
      Date: "21 Jan 2023",
      Price: "$199",
    },
  ];

  const green_check = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_22_3414)">
        <path
          d="M9.99935 18.3333C5.39685 18.3333 1.66602 14.6025 1.66602 10C1.66602 5.3975 5.39685 1.66667 9.99935 1.66667C14.6018 1.66667 18.3327 5.3975 18.3327 10C18.3327 14.6025 14.6018 18.3333 9.99935 18.3333ZM9.16852 13.3333L15.0602 7.44083L13.8818 6.2625L9.16852 10.9767L6.81102 8.61917L5.63268 9.7975L9.16852 13.3333Z"
          fill="#36A533"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_3414">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const three_dots = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_22_3424)">
        <path
          d="M12 3C11.175 3 10.5 3.675 10.5 4.5C10.5 5.325 11.175 6 12 6C12.825 6 13.5 5.325 13.5 4.5C13.5 3.675 12.825 3 12 3ZM12 18C11.175 18 10.5 18.675 10.5 19.5C10.5 20.325 11.175 21 12 21C12.825 21 13.5 20.325 13.5 19.5C13.5 18.675 12.825 18 12 18ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z"
          fill="#3A3781"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_3424">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <>
      <div className="All-Conatinor-perfect-divv">
        <div className="All-Containor-perfect-second-divv">
          <div className="MyWallet-seond-div">
            <span className="Settikgs-main-span">My Wallet</span>
          </div>
          <div className="Balance-total-div mt-3">
            <div className="second-totel">
              <div className="total-kaliya-div">
                <span className="rupy-belans-doler">$2000</span>
                <span className="total-span">Total Balance</span>
              </div>
              <div>
                <button className="Transfer-btn">
                  Transfer to Bank Account
                </button>
              </div>
            </div>
          </div>
          <div className="payment-main-dib90 mt-5">
            <div className="paymnet-add-custumar">
              <div>
                <span className="Payment-Options-cus">My Bank Accounts</span>
              </div>
              <div className="add-logocard-div99">
                <img src={plus} alt="" className="add-logo-pls" />
                <span className="Add-New-Card-span">Add New Card</span>
              </div>
            </div>
            <div className="paymnet-three-main-div">
              <div className="payment-input67">
                <img src={visa} alt="" className="visaimg-img" />
                <h5 className="card_number">
                  <span className="hide_card_number">XXXX XXXX XXXX</span> 3243
                </h5>
                <div className="Check_box_div">
                  <span>{green_check}</span>
                  <span>{three_dots}</span>
                </div>
              </div>
              <div className="payment-input67">
                <img src={master} alt="" className="visaimg-img" />
                <h5 className="card_number">
                  <span className="hide_card_number">XXXX XXXX XXXX</span> 3243
                </h5>
                <div className="Check_box_div">
                  <span>{green_check}</span>
                  <span>{three_dots}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="custum-line-user"></div>
          <div className="paymnet-income-custumar mt-4 mb-2">
            <div>
              <span className="Income-History-span">Income History</span>
            </div>
            <div className="add-logocard-div99">
              <svg
                width="34"
                height="34"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_28_15844)">
                  <path
                    d="M18.031 16.617L22.314 20.899L20.899 22.314L16.617 18.031C15.0237 19.3082 13.042 20.0029 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20.0029 13.042 19.3082 15.0237 18.031 16.617ZM16.025 15.875C17.2941 14.5699 18.0029 12.8204 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18C12.8204 18.0029 14.5699 17.2941 15.875 16.025L16.025 15.875Z"
                    fill="#1E2D38"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_28_15844">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>

          {Hdata.map((itm, index) => {
            return (
              <div className="paymnet-add-custuma mt-4">
                <div className="bordar-ka-liya">
                  <div className="Albord-and-name">
                    <img src={itm.img} className="Albord-img" alt="" />
                    <span className="flores-span">{itm.Name}</span>
                  </div>
                  <div className="doharzar-and-span">
                    <span className="doharzar-dater">{itm.Date}</span>
                    <span className="blue-price">{itm.Price}</span>
                  </div>
                </div>
                <div className="custum-line-user"></div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MyWallet;
